import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar';
import Logo from '../assets/images/logo.png';
import '../assets/style/ServiceMapping.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import loadingGif from '../assets/images/test.gif'; // Adjust the path as necessary
import Modal from 'react-modal';


const ServiceMapping = ({call}) => {
    const [serviceMappings, setServiceMappings] = useState([]);
    const [editProductId, setEditProductId] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [services, setServices] = useState([]); // Stores the fetched services and categories
    const [submittingServiceId, setSubmittingServiceId] = useState(null);
    const [completedServiceIds, setCompletedServiceIds] = useState(new Set());
    const [isProductAlreadyMapped, setIsProductAlreadyMapped] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [serviceTriggered, setServiceTriggered] = useState(-1);
    const navigate = useNavigate();

    const [allExpanded, setAllExpanded] = useState(false);

    const [products, setProducts] = useState([]);
    const [currentServiceIdForModal, setCurrentServiceIdForModal] = useState(null);

    const [selectedTab, setSelectedTab] = useState('Pending');

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [usagePopup, setusagePopup] = useState(false);

    const [actualProducts, setActualProducts] = useState([]);

    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [expandedServices, setExpandedServices] = useState(new Set());

    const [completedServices, setcompletedServices] = useState('');
    const [isServiceMatchingCompleted, setIsServiceMatchingCompleted] = useState(false);

    const [isInstructionalModalOpen, setIsInstructionalModalOpen] = useState(() => {
      // Check local storage to see if 'acknowledged' is set
      return localStorage.getItem('instructionAcknowledged') !== 'true';
    });


    const location = useLocation();
    const { mappingStarted } = location.state || {};

    const [tempUsageQuantities, setTempUsageQuantities] = useState({});

    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [currentServiceIdForDuplication, setCurrentServiceIdForDuplication] = useState(null);
    const [progressPercentage, setProgressPercentage] = useState(0);



    const location_name = localStorage.getItem('location_name');
    const permission_code = localStorage.getItem('permission_code');



    useEffect(() => {
      const totalServices = services.length;
      const completedServices = services.filter(service => service.status === 'completed').length;
      const completedPercentage = totalServices > 0 ? (completedServices / totalServices) * 100 : 0;
    
      setProgressPercentage(completedPercentage);
      setcompletedServices(completedServices)
    }, [services]);


    useEffect(() => {
      const fetchServiceMatchingProgress = async () => {
    
          if (services.length === 0) {
            setIsServiceMatchingCompleted(true);
          } else {
            setIsServiceMatchingCompleted(false);
          }
        
      };
    
      fetchServiceMatchingProgress();
    }, [services]);

    const handleGenerateMappings = async () => {
      try {
        
        setShowModal(false);
        toast.success('Mapping algorithm is triggered')
  
        await fetch(`${call}/service-mapping-data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ location_name }),
        });
      } catch (error) {
        console.error('Error during fetch operation:', error);
      }

    };
    
    
    const toggleServiceExpansion = (serviceId) => {
        setExpandedServices(prevExpandedServices => {
            const isCurrentlyExpanded = prevExpandedServices.has(serviceId);
            const newExpandedServices = new Set(prevExpandedServices);
            if (isCurrentlyExpanded) {
                newExpandedServices.delete(serviceId);
            } else {
                newExpandedServices.add(serviceId);
            }
            return newExpandedServices;
        });
    };


    const handleTabClick = async (tab) => {
      setSelectedTab(tab);
      fetchMappings();
  };
  
  const toggleAllServices = () => {
    if (allExpanded) {
      setExpandedServices(new Set());
    } else {
      const allServiceIds = new Set(serviceMappings.map(({ internalServiceId }) => internalServiceId));
      setExpandedServices(allServiceIds);
    }
    setAllExpanded(!allExpanded);
  };
  
    
  const saveAllEditsForService = async (serviceId) => {
    const productsToSave = serviceMappings
      .find(({ internalServiceId }) => internalServiceId === serviceId)?.products
      .filter(({ productId }) => tempUsageQuantities.hasOwnProperty(`${serviceId}-${productId}`));
  
    for (const product of productsToSave) {
      await handleSave(serviceId, product.productId, false);
    }
  };

    const handleSubmit = async (serviceId) => {
      setSubmittingServiceId(serviceId);
      setCompletedServiceIds(prev => {
        const newSet = new Set(prev);
        newSet.add(serviceId);
        return newSet;
      });

      await saveAllEditsForService(serviceId);

      
        const serviceMapping = serviceMappings.find(({ internalServiceId }) => internalServiceId === serviceId);

        // Filter out products that are not in the actualProducts list
        const productsToAdd = serviceMappings
            .find(({ internalServiceId }) => internalServiceId === serviceId)?.products
            .filter(({ productName }) => !actualProducts.includes(productName))
            .map(({ productId, productName}) => ({ productId, productName, location_name }));

            console.log("in service mapping: ", productsToAdd)
         
        if (productsToAdd.length) {
            try {
                const response = await fetch(`${call}/add-products-to-inventory`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({products: productsToAdd}),
                });
                const data = await response.json();
                if (data.success) {

                    
                    fetchActualProducts();
                } else {
                    throw new Error('Failed to add products to inventory');
                }
            } catch (error) {
                console.error('Error adding products to inventory:', error);
                
            }
        } 
        try {
          const response = await fetch(`${call}/update-mapping-status`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({serviceId: serviceId, location_name: location_name}),
          });
          
          if (response.ok) {
              
              
              
              fetchActualProducts();
              fetchMappings();
              setSubmittingServiceId(null); 
              toast.success("Service mappings submitted successfully!")
          } else {
              throw new Error('Failed to add products to inventory');
          }
      } catch (error) {
          console.error('Error adding products to inventory:', error);
          
      }
      fetchMappings();
    };

    const checkAvailableServicesForDuplication = (currentServiceId) => {
      const currentService = services.find(s => s.service_id === currentServiceId);
      if (!currentService) return false;
  
      return services.some(s => 
          s.service_cat === currentService.service_cat && 
          s.status === 'completed' && 
          s.internalServiceId !== currentServiceId // Ensure it's not the same service
      );
  };

  function closeInstructionalModal() {
    localStorage.setItem('instructionAcknowledged', 'true'); // Set in local storage
    setIsInstructionalModalOpen(false); // Close the modal
  }
  
  
  function InstructionalModal({ isOpen, onClose }) {
    if (!isOpen) return null;

    // if(localStorage.getItem('instructionAcknowledged') === 'true') return null;
  
    return (
      <div className="modal-overlay" style={{ zIndex: 10001 }}>
        <div className="modal-content" style={{ maxWidth: '800px', height:'700px' ,padding: '20px', textAlign: 'center' }}>
          <h2>Instructions for the Service Mapping Page</h2>
          <p>Here's how you can use this page:</p>
          
          <div style={{ height: '200px' }}>
          <button
            onClick={onClose}
            style={{
              backgroundColor: '#44a9af',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              
            }}
          >
            Acknowledge
          </button>
          </div>
        </div>
      </div>
    );
  }
  
  useEffect(() => {
    const fetchServiceMappingStatus = async () => {
      try {
        const response = await fetch(`${call}/get-service-mapping-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location_name: location_name }),
        });
        
        const result = await response.json();
        setServiceTriggered(result.service_mapping);
      } catch (error) {
        console.error('Error fetching service mapping status:', error);
      }
    };
  
    fetchServiceMappingStatus();  
  }, []); 
  
    
    const fetchServicesAndCategories = async () => {
        await fetch(`${call}/get-services`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({location_name: location_name})
        })
        .then(response => response.json())
        .then(data => {
            if (Array.isArray(data.result)) {
                setServices(data.result);
            } else {
                console.error('Fetched data is not in the expected format:', data.result);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const fetchActualProducts = async () => {
        try {
            const response = await fetch(`${call}/fetch-location-products`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({location_name: location_name}),
            });
            const data = await response.json();
            console.log(data)
            setActualProducts(data.product_names); // Assuming the response contains an array of product names under 'product_name'
            console.log('actual products',actualProducts)
        } catch (error) {
            console.error('Failed to fetch actual products:', error);
        }
    };
    
    useEffect(() => {
        fetchActualProducts();
        setServiceMappings([]);
        fetchMappings(); 
    }, []); 
    
    const fetchMappings = async () => {

      await fetch(`${call}/get-mappings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ location_name: location_name }) 
      })
      .then(response => response.json())
      .then(data => {
          if (Array.isArray(data.result)) {
              const processedData = data.result.reduce((acc, { service_id, service_name, service_cat, service_sub_cat, status, product_id, usage_quantity, product_name, product_description, product_cat, manufacturer_name }) => {
                  if (!acc[service_id]) {
                      acc[service_id] = { serviceName: service_name, ServiceCat: service_cat, serviceSubCat: service_sub_cat, internalServiceId: service_id, status: status, products: [] };
                  }
                  acc[service_id].products.push({ productId: product_id, averageUsageQuantity: usage_quantity, productName: product_name, productDescription: product_description, productManufacturer: manufacturer_name, productCat: product_cat });
                  return acc;
              }, {});
              setServiceMappings(Object.values(processedData));

              // Extract completed service IDs from the fetched data
              const newCompletedServiceIds = Object.values(processedData)
              .filter(service => service.status === 'completed')
              .map(service => Number(service.internalServiceId));

            // Append new completed service IDs to the existing set
            setCompletedServiceIds(prev => {
              const updatedSet = new Set(prev);
              newCompletedServiceIds.forEach(id => updatedSet.add(id));
              return updatedSet;
            });


          } else {
              console.error('Fetched data is not in the expected format:', data.result);
          }
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  
      await fetchServicesAndCategories();
  };

    
    const startEdit = (productId, currentQuantity) => {
        setEditProductId(productId);
        setEditValue(currentQuantity);
    };
    
    
    const handleAddProducts = async (serviceId, productWithUsage) => {
      const { productId, usage } = productWithUsage; // Destructure the object to get productId and usage
      const payload = { 
          service_id: serviceId, 
          product_id: productId, 
          usage_quantity: usage, // Add the usage quantity to the payload
          location_name: location_name 
      };
      try {
          const response = await fetch(`${call}/add-products-to-mapping`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(payload),
          });
          const data = await response.json();
          if (data.success) {
              toast.success('Product mapped successfully');
              console.log('Products added successfully');
              fetchMappings(); // Refresh the mappings to reflect the newly added product with its usage
          }
      } catch (error) {
          console.error('Error adding products:', error);
         
      }
  };
  


const handleDelete = async (serviceId, productId) => {
    const payload = { service_id: serviceId, product_id: productId, location_name: location_name };
    await fetch(`${call}/delete-mapping`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            console.log(data.message);
            // You may want to re-fetch the mappings here to update the table
            fetchMappings();
        }
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};


useEffect(() => {
  const fetchProducts = async () => {
      try {
          const response = await fetch(`${call}/add-products`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({location_name: location_name}),
          });
          const data = await response.json();
          console.log("ALl products are fetched")
          setProducts(data.products); // Assuming 'data.products' is an array of product details
      } catch (error) {
          console.error('Failed to fetch products:', error);
      }
  };


      fetchProducts();
  
}, []);



function ProductModal({ isOpen, onClose, onAddProducts, serviceId, serviceMappings }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedUsage, setSelectedUsage] = useState('');
  const [searchTerm, setSearchTerm] = useState(""); // State to keep track of search term
  const [newProductName, setNewProductName] = useState('');


  const handleNewProductSubmit = async () => {
    //e.preventDefault(); // Prevent default form submission behavior
    
      const response = await fetch(`${call}/include-manual-product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productName: newProductName, location_name: location_name }),
      });
      const data = await response.json();
      if (data.product_id) {
        const newProduct = {
          id: data.product_id,
          name: newProductName,
        };
        console.log('new product included: ', newProduct)
        setSelectedProduct(newProduct);
        handleSelectProduct(newProduct);
        toast.success("New product added successfully");
        setNewProductName(''); // Clear the input field after submission
      } else {
        //toast.error("Failed to add new product");
      }
    
  };

  const handleSubmit = () => {
      if (serviceId && selectedProduct) {
          onAddProducts(serviceId, { productId: selectedProduct.id, usage: selectedUsage });
      } else {
          console.error('Service ID or product details are missing');
      }
      onClose();
  };

  const handleSelectProduct = (product) => {
      setIsProductAlreadyMapped(false);
      setSelectedProduct(product);
      setSelectedUsage(''); // Reset usage on new product selection

     
      const serviceMapping = serviceMappings.find(mapping => mapping.internalServiceId === serviceId);

      // Check if any product in the mapping matches the selected product
      const isMapped = serviceMapping?.products.some(mappedProduct => {
          console.log(parseInt(mappedProduct.productId) === parseInt(product.id));
          if(parseInt(mappedProduct.productId) === parseInt(product.id)){
            setIsProductAlreadyMapped(true);
          }
      });
  
     
  };

  const filteredProducts = products.filter(product =>
      product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isOpen) return null;

  return (
      <div className="modal-overlay" style={{zIndex: 10000}}>
          <div className="modal-content" style={{maxWidth: '800px', minHeight: '700px' }} >
              
              <h2>Select Product to Add</h2>
              <div style={{display:'flex'}}>
              <input
                  type="text"
                  placeholder="Search Product"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '30%', padding: '10px', marginBottom: '10px', border: '2px #87d3d7', fontStyle: 'italic', borderRadius: '5px' }}
              />
              
              
        </div>
              <div className='product-table-wrapper' style={{maxHeight: '350px',}}>
                  <table className="add-product-table" style={{
                    width: '100%',
                    borderCollapse: 'separate',
                    borderSpacing: 0,
                    
                    position: 'relative',}} >
                      <thead>
                          <tr >
                              <th style={{borderBottom: '2px solid black'}}>Product Name</th>
                              <th style={{borderBottom: '2px solid black'}}>Description</th>
                              <th style={{borderBottom: '2px solid black'}}>Manufacturer</th>
                              <th style={{borderBottom: '2px solid black'}}>Category</th>
                              <th style={{borderBottom: '2px solid black', width: '12%'}}>Select</th>
                          </tr>
                      </thead>
                      <tbody>
                          {filteredProducts.map((product, index) => (
                              <tr key={index}>
                                  <td>{product.name}</td>
                                  <td>{product.description}</td>
                                  <td>{product.manufacturer_name}</td>
                                  <td>{product.product_cat}</td>
                                  <td>
                                      <button  style={{
                                                backgroundColor: '#44a9af',
                                                color: 'white',
                                                padding: '8px 12px',
                                                borderRadius: '5px',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }} onClick={() => handleSelectProduct(product)}>
                                          {selectedProduct && selectedProduct.id === product.id ? 'Deselect' : 'Select'}
                                      </button>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>

              

              <input
                  key="new-product-name-input"
                  type="text"
                  value={newProductName}
                  onChange={(e) => setNewProductName(e.target.value)}
                  placeholder="Enter new product name"
                  style={{ width: '30%', padding: '10px', marginTop: '20px',marginBottom: '10px', border: '2px #87d3d7', fontStyle: 'italic', borderRadius: '5px' }}

                  required
            />
          <button type="submit" 
          onClick={handleNewProductSubmit}
          style={{
                            backgroundColor: '#44a9af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            marginLeft: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}>Add New Product</button>
                         {/* Display warning if the product is already in the mapping */}
                         {isProductAlreadyMapped && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        This product is already included in the mapping
                    </div>
                )}
              {selectedProduct && (
                
                  <div style={{ marginTop: '20px' }}>
                      <div><strong>Selected Product:</strong> {selectedProduct.name}</div>
                      <input
                          type="number"
                          placeholder="Enter Usage"
                          value={selectedUsage}
                          onChange={(e) => setSelectedUsage(e.target.value)}
                          style={{ width: '30%', padding: '10px', margin: '10px 0', border: '2px solid #87d3d7', borderRadius: '5px' }}
                      />
                  </div>
              )}
             
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button
                        onClick={onClose}
                        style={{
                            backgroundColor: '#44a9af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Close
                    </button>
                    <button
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#44a9af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Done
                    </button>
                </div>
          </div>
      </div>
  );
}



  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const usagetogglePopup = () => {
    setusagePopup(!usagePopup);
  };



  const handleSave = async (serviceId, productId, showToast = true) => {
    const key = `${serviceId}-${productId}`;
    const usageQuantity = tempUsageQuantities[key];
    if (usageQuantity === undefined) return;  // Do nothing if no changes have been made
  
    const payload = { 
      service_id: serviceId, 
      product_id: productId, 
      usage_quantity: usageQuantity,
      location_name: location_name 
    };
  
    try {
      const response = await fetch(`${call}/edit-mapping`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.success) {
        if (showToast) {
          toast.success('Product mapping updated successfully');
        }       
        fetchMappings(); // Optionally refresh mappings to show updated data
        setTempUsageQuantities(prev => {
          const newState = {...prev};
          delete newState[key]; // Remove the key after saving
          return newState;
        });
      } else {
        throw new Error('Failed to update product mapping');
      }
    } catch (error) {
      console.error('Error updating product mapping:', error);
      // toast.error('Failed to update product mapping');
    }
  };


  const ProductRow = ({ product, onProductUpdate, onProductDelete }) => {
    const { productId, productName, productDescription, productManufacturer, productCat, averageUsageQuantity } = product;
  
    const [usageQuantity, setUsageQuantity] = useState(averageUsageQuantity);
  
  
  
    return (
      <tr>
        <td>{productName}</td>
        <td>{productDescription}</td>
        <td>{productManufacturer}</td>
        <td>{productCat}</td>
        <td>
          <input
              type="number"
              value={product.averageUsageQuantity}
              onChange={e => onProductUpdate(product.productId, e.target.value)}
              style={{ width: '80%' }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          {/* <button onClick={() => onProductUpdate(productId, usageQuantity)}>Save</button> */}
          <button onClick={() => onProductDelete(productId)}>Delete</button>
        </td>
      </tr>
    );
  };
  

  function DuplicateMappingModal({ isOpen, onClose, services, onCopyMapping, serviceId }) {
    const [selectedServiceId, setSelectedServiceId] = useState('');
    const [previewProducts, setPreviewProducts] = useState([]);
  


    useEffect(() => {
      if (selectedServiceId) {
        console.log('preview products ', selectedServiceId)
        console.log('preview products ', serviceMappings)
        const selectedService = serviceMappings.find(({ internalServiceId }) => internalServiceId === parseInt(selectedServiceId));
        console.log('preview products ', selectedService)
        setPreviewProducts(selectedService ? selectedService.products : []);
      }
    }, [selectedServiceId]);

    const handleConfirm = () => {
      if (selectedServiceId && serviceId) {
          onCopyMapping(serviceId, selectedServiceId, previewProducts);
      }
      onClose();
  };
  
  const handleProductUpdate = (productId, newQuantity) => {
    setPreviewProducts(prevProducts =>
        prevProducts.map(p => p.productId === productId ? { ...p, averageUsageQuantity: parseFloat(newQuantity) || 0 } : p)
    );
};

    
  
    const handleProductDelete = (productId) => {
      setPreviewProducts(prevProducts => prevProducts.filter(p => p.productId !== productId));
    };
  
    if (!isOpen) return null;

    const selectedCategory = services.find(({ service_id }) => service_id === parseInt(serviceId))
  
    return (
      <div className="modal-overlay" style={{ zIndex: 10000 }}>
            <div className="modal-content" style={{ maxWidth: '800px' }}>
          <h2>Duplicate Mapping</h2>
          <select
            value={selectedServiceId}
            onChange={(e) => setSelectedServiceId(e.target.value)}
            className="category-dropdown"
            style={{ width: '30%',}}
          >
            <option value="">Select Service To Duplicate From</option>
            {services
            .filter(s => s.status === 'completed')
            .filter(s => s.service_cat === selectedCategory.service_cat)
            .filter(s => s.service_id !== serviceId)
            .map(service => (
              <option key={service.service_id} value={service.service_id}>{service.service_name}</option>
            ))}
          </select>
          {/* Table to display products */}
          <div className="expanded-table-wrapper" style={{minHeight: '300px', maxHeight: '300px', backgroundColor: 'white',overflowY: 'auto', border: '2px solid #87d3d7', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)' }}>
            <table className="add-product-table"  style={{
              width: '100%',
              borderCollapse: 'separate',
              borderSpacing: 0,
              position: 'relative'}}>
              <thead style={{ position: 'sticky', top: 0, borderBottom: '2px solid #000000' }}>
                <tr>
                  
                  
                  <th style={{ borderBottom: '2px solid #000000', width: '20%' }}>Product Name</th>
                  <th style={{ borderBottom: '2px solid #000000', width: '20%' }}>Description</th>
                  <th style={{ borderBottom: '2px solid #000000', width: '10%' }}>Manufacturer</th>
                  <th style={{ borderBottom: '2px solid #000000', width: '20%' }}>Category</th>
                  <th style={{borderBottom: '2px solid #000000',  width: '10%', textAlign: 'center', marginRight: '10px' }}>Usage</th>
                  <th style={{ borderBottom: '2px solid #000000', width: '35%', textAlign: 'center'  }}>Actions</th>
                </tr>
              </thead>
            <tbody>
              {previewProducts
              .filter(p => p.productId !== null)
              .map(product => (
                <ProductRow
                  key={product.productId}
                  product={product}
                  onProductUpdate = {handleProductUpdate}
                  onProductDelete={handleProductDelete}
                />
              ))}
            </tbody>
          </table>
          </div>
          {/* <button onClick={onClose}>Close</button>
          <button onClick={() => handleConfirm()}>Confirm Copy</button> */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button
                        onClick={onClose}
                        style={{
                            backgroundColor: '#44a9af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Close
                    </button>
                    <button
                        onClick={handleConfirm}
                        style={{
                            backgroundColor: '#44a9af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Confirm Copy
                    </button>
                </div>
        </div>
      </div>
    );
  }
  
  useEffect(() => {
    // Check if there are no pending services
    const noPendingServices = services.every(service => service.status !== 'pending');
  

    // If there are no pending services, send an update to the server
    if (noPendingServices && services.length > 0) {
      
      updateServiceMappingProgress();
    }
  }, [completedServices]); 
  
  // Function to call the API endpoint
  const updateServiceMappingProgress = async () => {
    try {
      const response = await fetch(`${call}/update-progress`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          taskName: 'Service Mapping',
          location_name: location_name,
          completed: "true",
        }),
      });
      const data = await response.json();
      if (data.success) {
        console.log('Update service mapping progress successfully.');
      } else {
        throw new Error('Failed to update service mapping progress');
      }
    } catch (error) {
      console.error('Error updating service mapping progress:', error);
    }
  };
  


  const handleCopyMappings = async (sourceServiceId, targetServiceId, updatedProducts) => {
    const payload = {
        internalServiceId: sourceServiceId,
        selectedServiceName: targetServiceId,
        products: updatedProducts,  // Now including the potentially updated product details
        location_name: location_name
    };

    try {
        const response = await fetch(`${call}/copy-mappings`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.success) {
            toast.success('Mappings copied successfully with updates');
            fetchMappings();  // Refresh the mappings to show the new copied data
        } else {
            toast.error('Failed to copy mappings');
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to copy mappings');
    }

    setIsDuplicateModalOpen(false); // Close the modal after copying
};

    const serviceCategories = [...new Set(services.map(service => service.service_cat))];

    const shouldHighlightService = (products) => {
      return products.length === 1 && (products[0].averageUsageQuantity === null || products[0].averageUsageQuantity === 0);
    };
    
    const deleteAllProducts = async (serviceId) => {
      const payload = { service_id: serviceId, location_name: location_name };
      await fetch(`${call}/delete-all-mappings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
          if (data.success) {
              console.log(data.message);
              // You may want to re-fetch the mappings here to update the table
              fetchMappings();
          }
      })
      .catch((error) => {
          console.error('Error:', error);
      });
    };
    
   
    return (
      <div className="page-wrapper-container">
        <div className="ServiceMapping">
          <div className="dashboard-layout">
          <Sidebar call={call}/>
          </div>
          {serviceTriggered === 0 && (
          <>

              
              <div className="loading-container">
                <img src={loadingGif} alt="Loading..." className="loading-gif" />
                <p style={{marginTop: '-100px'}}>Loading Mappings...</p>
              </div>
          
          </>
          )}

          { serviceTriggered === -1 && (
            <div style={{marginTop: '200px', marginLeft: '110px', fontSize: '20px', textAlign: 'center', marginRight: '110px'}}>
            <img src={Logo} alt="Loading..." className="loading-gif" style={{marginBottom: '20px'}}/>
            <p>Welcome to Medvelle Service-Product Mapping, please click the below button to generate your mappings.</p>
            <button
              className="next-step-button"
              onClick={() => setShowModal(true)}
            >
              Generate Mappings
            </button>
            </div>
          )}

          { (serviceTriggered === 1) &&  (


            <>
          <div className='heading'>
          <h1>Service Mapping</h1>
          <button className="info-button" onClick={togglePopup}>&#x2139;</button>
          </div>
          
  
          
          
          {isPopupVisible && (
            <div className="popup">
            <div className="popup-inner">
          <><p style={{ color: 'grey', textDecoration: 'underline' }}>Please Note:</p><p><span style={{ color: '#f49c7d', fontStyle: 'italic' }}>Orange products</span> are currently not in your inventory but we recommend them mapped to the services. Accepting the mappings includes these products to your inventory.</p></>
          <button className="submit-button" onClick={togglePopup}>Close</button>
          </div>
          </div>
          )}

          {usagePopup && (
            <div className="popup" >
            <div className="popup-inner" style={{width: '290px'}}>
              <p>Average product usage per appointment.</p>
          <button className="submit-button" onClick={usagetogglePopup} style={{width: '60px', height: '35px', marginTop: '10px'}}>Close</button>
          </div>
          </div>
          )}


            <div style={{ display: 'flex', marginTop: '5px', marginBottom: '10px', gap: '10px' }}>
              <span
                onClick={() => handleTabClick('Pending')}
                style={{
                  marginBottom: '15px',
                  color: '#000000',
                  borderBottom: selectedTab === 'Pending' ? ' 2px solid black' : ' 2px solid transparent',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer' // Set cursor to pointer
                }}
              >
                Pending
              </span>

              <span
                onClick={() => handleTabClick('Completed')}
                style={{
                  marginBottom: '15px',
                  color: '#000000',
                  borderBottom: selectedTab === 'Completed' ? ' 2px solid black' : ' 2px solid transparent',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer' // Set cursor to pointer
                }}
              >
                Completed
              </span>

              <div className="progress-container" style={{marginLeft: '20px', width: '400px', marginBottom: '10px'}}>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
              </div>
              <span style={{marginLeft: '10px', width: '400px'}} className="progress-text1">{`${parseInt(completedServices)}`}/{services.length} Completed</span>
            </div>
            </div>
            

            <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="category-dropdown"
          >
            <option value="">Select Category</option>
            {serviceCategories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
            
          <div className='table-wrapper' style={{ maxHeight: '63vh', overflowY: 'auto', }}>
            <table className="service-mapping-table" style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
            position: 'relative',
           
          }}>
              <thead >
                <tr>
                  <th style={{ borderBottom: '2px solid black', zIndex: 1, width: '2%' }} onClick={toggleAllServices}>
                    <span>
                      <FontAwesomeIcon icon={allExpanded ? faAngleUp : faAngleDown} />
                    </span>
                  </th>

                  <th style={{ borderBottom: '2px solid black', zIndex: 1, width:'20%'}}>Service</th>
                  <th style={{ borderBottom: '2px solid black', zIndex: 1, width:'20%'}}>Category</th>
                  <th style={{ borderBottom: '2px solid black', zIndex: 1}}>Sub-Category</th>
                  <th style={{ borderBottom: '2px solid black', zIndex: 1, textAlign: 'center'}}></th>
                  
                </tr>
              </thead>
              <tbody>

                
                
              {serviceMappings
                    .filter(mapping => selectedCategory === '' || mapping.ServiceCat === selectedCategory)
                    .filter(mapping => {
                      if (selectedTab === 'Completed') {
                        return completedServiceIds.has(mapping.internalServiceId);
                      } else {
                        return !completedServiceIds.has(mapping.internalServiceId);
                      }
                    })                    
                      
                  
                  .map(({ internalServiceId, serviceName, ServiceCat, serviceSubCat, products }) => (
                    
                    <>
                    <React.Fragment key={internalServiceId}>
                      <tr key={internalServiceId} style={{ fontSize: '14px'}}>
                        <td onClick={() => toggleServiceExpansion(internalServiceId)}>
                        <span >
                          <FontAwesomeIcon icon={expandedServices.has(internalServiceId) ? faAngleUp : faAngleDown} />
                        </span>                        
                        </td>
                        <td>
                        {serviceName}
                        </td>
                        <td>
                        {ServiceCat}
                        </td>
                        <td>
                        {serviceSubCat}
                        </td>

                        <td>
                          <div className='service-heading'>
                          <div className='service-sub-heading'>
                          </div>

                          <div className='service-actions'>
                          {expandedServices.has(internalServiceId) && (
                            <>
                              <button className='submit-mapping-button' style={{backgroundColor: '#87d3d7'}} onClick={() => { setIsProductModalOpen(true); setCurrentServiceIdForModal(internalServiceId); }}>Add Product</button>
                              
                              <button
                                  className='submit-mapping-button'
                                  style={{
                                      backgroundColor: checkAvailableServicesForDuplication(internalServiceId) ? '#87d3d7' : '#ccc'
                                  }}
                                  onClick={() => {
                                      if (checkAvailableServicesForDuplication(internalServiceId)) {
                                          setIsDuplicateModalOpen(true);
                                          setCurrentServiceIdForDuplication(internalServiceId);
                                      }
                                  }}
                                  disabled={!checkAvailableServicesForDuplication(internalServiceId)}
                                  title={!checkAvailableServicesForDuplication(internalServiceId) ? "No services completed for this category to import from" : ""}
                              >
                                  Duplicate Mapping
                              </button>
                            </>
                          )}

                          <button onClick={() => handleSubmit(internalServiceId)} disabled={submittingServiceId !== null && submittingServiceId !== internalServiceId} className="submit-mapping-button">Submit Mapping</button>    

                          </div>

                          </div>
                        </td>
                      </tr>
                      {expandedServices.has(internalServiceId) && (
                        <tr key={`expanded-${internalServiceId}`}>

                        <td colSpan="5">
                        <div className="expanded-table-wrapper" style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid black', marginLeft:'22px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)' }}>
                          <table className="expanded-table"  style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative'}}>
                            <thead style={{ position: 'sticky', top: 0, borderBottom: '2px solid #000000'  }}>
                              <tr>
                                <th style={{ borderBottom: '1px solid #000000', width: '15%', fontSize: '13px' }}>Product Name</th>
                                <th style={{ borderBottom: '1px solid #000000', width: '25%', fontSize: '13px'}}>Description</th>
                                <th style={{ borderBottom: '1px solid #000000', width: '10%', fontSize: '13px' }}>Manufacturer</th>
                                <th style={{ borderBottom: '1px solid #000000', width: '20%', fontSize: '13px' }}>Category</th>
                                <th style={{borderBottom: '1px solid #000000',  width: '10%', textAlign: 'center', marginLeft: '10px', fontSize: '13px', }}>
                                <div className='usage-heading'>
                                  <p>Usage</p>
                                <button className="usage-info-button" onClick={usagetogglePopup} style={{}}>&#x2139;</button>
                                </div>
                                </th>
                                <th style={{ borderBottom: '1px solid #000000', width: '30%', textAlign: 'right', fontSize: '13px'  }}>Actions
                                <button style={{marginLeft: '5px', fontSize: '11px'}}  onClick={() => deleteAllProducts(internalServiceId)}>Delete All Products</button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Row for service actions */}
                              <tr>                  
                                {products.length === 0 || (products.length === 1 && products[0].productName === null) ? (
                                  <td colSpan="6" style={{ textAlign: 'center', color: 'lightcoral', fontWeight: 'bold' }}>No products mapped</td>
                                ) : null}
                              </tr>
                              {/* Rows for product details */}
                              
                              {products.map(({ productId, averageUsageQuantity, productName, productDescription, productManufacturer, productCat }, index) =>
                                productName !== null && (
                                  <tr key={`${internalServiceId}-${productId}`} style={{ fontSize: '12px'}}>
                                    <td style={{ color: actualProducts.includes(productName) ? 'black' : '#f49c7d' }}>{productName}</td>
                                    <td>{productDescription}</td>
                                    <td>{productManufacturer}</td>
                                    <td>{productCat}</td>
                                    <td style={{ textAlign: 'center' }}>
                                    <input 
                                      type="number" 
                                      value = {(tempUsageQuantities[`${internalServiceId}-${productId}`] ?? averageUsageQuantity)}
                                      
                                      onChange={e => setTempUsageQuantities({
                                        ...tempUsageQuantities, 
                                        [`${internalServiceId}-${productId}`]: e.target.value
                                      })}
                                      style={{ width: '100%' }}
                                    />
                                    </td>
                                    {/* <td style={{ textAlign: 'center', backgroundColor: averageUsageQuantity === null || averageUsageQuantity === 0 ? 'lightcoral' : '' }}>{parseFloat(averageUsageQuantity).toFixed(2)}</td> */}
                                    <td style={{textAlign: 'center'}}>
                                      <button onClick={() => handleSave(internalServiceId, productId)}>Save</button> {/* Save on click */}

                                      <button onClick={() => handleDelete(internalServiceId, productId)}>Delete</button>
                                    </td>
                                  </tr>
                                )
                              )}
                              
                            </tbody>
                          </table>
                        </div>
                        </td>
                        </tr>
                        )}
                        </React.Fragment>
                    </>
                  ))}
              </tbody>
            </table>
          </div>




          <ProductModal
            isOpen={isProductModalOpen}
            onClose={() => setIsProductModalOpen(false)}
            onAddProducts={handleAddProducts}
            serviceId={currentServiceIdForModal}
            serviceMappings={serviceMappings} 
            className="product-modal"
          />
          <DuplicateMappingModal
              isOpen={isDuplicateModalOpen}
              onClose={() => setIsDuplicateModalOpen(false)}
              services={services}
              onCopyMapping={handleCopyMappings}
              serviceId={currentServiceIdForDuplication}
          />

          <InstructionalModal isOpen={isInstructionalModalOpen} onClose={closeInstructionalModal} />
          </>

          )}

<Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{ content: { height: 'auto' } }}
      >
        <h2 style={{ textAlign: 'center' }}>Attention</h2>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to generate mappings for this location?
        </p>
        <button
          onClick={handleGenerateMappings}
          type="button"
          style={{
            marginLeft: '220px',
            border: '3px solid #F49C7D',
            color: 'white',
            backgroundColor: '#F49C7D',
            padding: '5px',
            marginTop: '20px',
            cursor: 'pointer',
          }}
        >
          Confirm
        </button>
      </Modal>

        </div>
        </div>
      );
      

};

export default ServiceMapping;