import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const ProductModal = ({ isOpen, onClose, products, selectedProducts, setSelectedProducts, newProductName, setNewProductName, handleAddProducts,handleNewProductSubmit, fetchInventory, call, location_name }) => {
    const [searchTerm, setSearchTerm] = useState("");

    // Function to handle checkbox change for selecting products
    const handleCheckboxChange = (product) => {
        setSelectedProducts(prevSelected =>
            prevSelected.some(selectedProduct => selectedProduct.id === product.id)
                ? prevSelected.filter(selectedProduct => selectedProduct.id !== product.id)
                : [...prevSelected, product]
        );
    };

    const filteredProducts = products.filter(product =>
        product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}  // Close modal on outside click
            contentLabel="Product Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '800px',
                    height: '700px',
                    backgroundColor: '#87d3d7',
                },
            }}
        >
            <h2>Select Product to Add</h2>

            {/* Search input for filtering products */}
            <input
                type="text"
                placeholder="Search Product"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '30%', padding: '10px', marginBottom: '10px', borderRadius: '5px' }}
            />

            {/* Table for displaying products */}
            <div className="product-table-wrapper">
                <table className="vendor-table" style={{ width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
                    <thead>
                        <tr>
                            <th style={{textAlign: 'left', width: '25%'}}>Product Name</th>
                            <th style={{textAlign: 'left', width: '25%'}}>Description</th>
                            <th style={{textAlign: 'left', width: '15%'}}>Manufacturer</th>
                            <th style={{textAlign: 'left', width: '20%'}}>Category</th>
                            <th style={{textAlign: 'center', width: '10%'}}>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map((product, index) => (
                            <tr key={index}>
                                <td>{product.name}</td>
                                <td>{product.description}</td>
                                <td>{product.manufacturer_name}</td>
                                <td>{product.product_cat}</td>
                                <td style={{textAlign: 'center'}}>
                                    <input
                                        type="checkbox"
                                        checked={selectedProducts.some(selectedProduct => selectedProduct.id === product.id)}
                                        onChange={() => handleCheckboxChange(product)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* OR enter new product name */}
            <div style={{ display: 'flex', marginLeft: '380px', marginTop: '20px' }}>
                OR
            </div>

            {/* Input for adding a new product */}
            <input
                type="text"
                value={newProductName}
                onChange={(e) => setNewProductName(e.target.value)}
                placeholder="Enter new product name"
                style={{ width: '30%', padding: '10px', marginTop: '20px', marginBottom: '10px', borderRadius: '5px', marginLeft: '190px' }}
            />
            <button
                type="submit"
                onClick={handleNewProductSubmit}
                style={{
                    backgroundColor: '#44a9af',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    marginLeft: '5px',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                Add New Product
            </button>

            {/* Submit button for selected products */}
            <div style={{ display: 'flex', marginLeft: '317px', marginTop: '30px' }}>
                <button
                    onClick={() => {
                        handleAddProducts(selectedProducts); // Add selected products
                        onClose(); // Close modal after adding
                    }}
                    style={{
                        backgroundColor: '#44a9af',
                        color: 'white',
                        padding: '10px 20px',
                        width: '150px',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    Done
                </button>
            </div>
        </Modal>
    );
};

export default ProductModal;
