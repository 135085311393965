export const fetchAutoInventoryStatus = async (call, location_name, setAutoInventory) => {
    try {
        const response = await fetch(`${call}/get-auto-inventory-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const result = await response.json();
        setAutoInventory(result.auto_inventory);
    } catch (error) {
        console.error('Failed to fetch auto inventory status:', error);
    }
};

export const fetchClientReview = async (call, location_name, setClientReview) => {
    try {
        const response = await fetch(`${call}/get-client-inventory-review-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const data = await response.json();
        setClientReview(data.inventory_review);
    } catch (error) {
        console.error('Error fetching inventory review status:', error);
    }
};

export const fetchInventory = async (call, location_name, setInventoryData, setComments) => {
    try {
        const response = await fetch(`${call}/get-location-inventory`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const data = await response.json();
        setInventoryData(data.results);
        const initialComments = {};
        data.results.forEach(item => {
            initialComments[item.matched_product_id] = item.comment || '';
        });
        setComments(initialComments);
    } catch (error) {
        console.error('Error fetching inventory data:', error);
    }
};

export const fetchProducts = async (call, location_name, inventoryData, setProducts) => {
    try {
        const response = await fetch(`${call}/add-products`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const data = await response.json();
        const inventoryProductIds = new Set(inventoryData.map(item => item.matched_product_id));
        const filteredProducts = data.products.filter(product => !inventoryProductIds.has(String(product.id)));
        setProducts(filteredProducts);
    } catch (error) {
        console.error('Failed to fetch products:', error);
    }
};
