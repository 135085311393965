import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ProductTable = ({ inventoryData, comments, setComments, handleRemove, call }) => {
    const [sortAttribute, setSortAttribute] = useState('');
    const [sortAsc, setSortAsc] = useState(true);
    const [sortClickCount, setSortClickCount] = useState(0);

    const handleCommentChange = (productId, comment) => {
        setComments(prevComments => ({
            ...prevComments,
            [productId]: comment,
        }));
    };
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const uniqueCategories = [...new Set(inventoryData.map(item => item.product_cat))];
    const uniqueManufacturers = [...new Set(inventoryData.map(item => item.manufacturer_name))];

    const filteredData = inventoryData.filter(item => {
        return (
            (selectedCategory === '' || item.product_cat === selectedCategory) &&
            (selectedManufacturer === '' || item.manufacturer_name === selectedManufacturer) &&
            (item.product_name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });    


    const handleSortChange = (attribute, currentSortAttribute, setSortAttribute, sortAsc, setSortAsc) => {
        if (attribute === currentSortAttribute) {
            // Increment the click count
            setSortClickCount(prevCount => prevCount + 1);
    
            // Toggle between ascending and descending if the same column is clicked
            if (sortClickCount === 1) {
                setSortAsc(!sortAsc);
            }
    
            // Reset sorting if clicked three times
            if (sortClickCount === 2) {
                setSortAttribute(''); // Remove sorting by clearing the attribute
                setSortAsc(true); // Reset to the default sort order
                setSortClickCount(0); // Reset click count
            }
        } else {
            // New column is clicked
            setSortAttribute(attribute);
            setSortAsc(true); // Default to ascending
            setSortClickCount(1); // Start new click count
        }
    };
    

    const sortedData = [...filteredData].sort((a, b) => {
        if (sortAttribute) {
            if (typeof a[sortAttribute] === 'number' && typeof b[sortAttribute] === 'number') {
                return sortAsc ? a[sortAttribute] - b[sortAttribute] : b[sortAttribute] - a[sortAttribute];
            } else {
                const valueA = a[sortAttribute].toString().toLowerCase();
                const valueB = b[sortAttribute].toString().toLowerCase();
                if (valueA < valueB) return sortAsc ? -1 : 1;
                if (valueA > valueB) return sortAsc ? 1 : -1;
                return 0;
            }
        }
        return 0;
    });

    const getArrow = (attribute, currentSortAttribute, sortAsc) => {
        if (attribute === currentSortAttribute) {
            return sortAsc ? ' ▲' : ' ▼';
        }
        return '';
    };
        

    const handleCommentSubmit = async (productId) => {
        try {
            const response = await fetch(`${call}/update-product-comment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    product_id: productId, 
                    location_name: localStorage.getItem('location_name'), 
                    comment: comments[productId] 
                }),
            });

            const data = await response.json();
            if (data.success) {
                toast.success('Comment updated successfully');
            }
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };


    return (
        <>
        <input
        type="text"
        placeholder="Search Product Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginTop: '10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        />

        <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            style={{ marginTop: '10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        >
            <option value="">All Categories</option>
            {uniqueCategories.sort().map(product_cat => (
                <option key={product_cat} value={product_cat}>
                    {product_cat}
                </option>
            ))}
        </select>

        {/* Manufacturer Dropdown */}
        <select
            value={selectedManufacturer}
            onChange={(e) => setSelectedManufacturer(e.target.value)}
            style={{ marginTop: '10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        >
            <option value="">All Manufacturers</option>
            {uniqueManufacturers.sort().map(manufacturer => (
                <option key={manufacturer} value={manufacturer}>
                    {manufacturer}
                </option>
            ))}
        </select>
    
        <div style={{ border: '2px solid #87D3D7', marginTop: '30px', width: '96%', maxHeight: '63vh', minHeight: '63vh', overflowY: 'auto', boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)' }}>
            <table className="vendor-table" style={{ backgroundColor: '#ffffff', width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff' }}>
                <tr style={{ fontSize: '13px', color: '#000000', textAlign: 'left' }}>
                    <th style={{ textAlign: 'center', width: '5%' }}>Remove</th>
                    <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '20%' }}>
                        Product Name {getArrow('product_name', sortAttribute, sortAsc)}
                    </th>
                    <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '20%' }}>
                        Description {getArrow('product_description', sortAttribute, sortAsc)}
                    </th>
                    <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer' }}>
                        Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}
                    </th>
                    <th onClick={() => handleSortChange('product_cat', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '20%' }}>
                        Category {getArrow('product_cat', sortAttribute, sortAsc)}
                    </th>
                    <th style={{ textAlign: 'center', width: '20%' }}>Comments</th>
                </tr>
            </thead>
            <tbody>
                {sortedData.map((item, index) => (
                    <tr key={index}>
                        <td style={{ textAlign: 'center' }}>
                            <button 
                                style={{ backgroundColor: '#44a9af', border: 'none', width: '20px', height: '20px', borderRadius: '2px', fontWeight: 'bold', cursor: 'pointer' }} 
                                onClick={() => handleRemove(item)}
                            >
                                -
                            </button>
                        </td>
                        <td>{item.product_name}</td>
                        <td>{item.product_description}</td>
                        <td>{item.manufacturer_name}</td>
                        <td>{item.product_cat}</td>
                        <td style={{ textAlign: 'center' }}>
                            <input
                                type="text"
                                value={comments[item.matched_product_id] || ''}
                                onChange={(e) => handleCommentChange(item.matched_product_id, e.target.value)}
                                placeholder="Enter comment"
                                style={{ width: '50%', padding: '5px', borderRadius: '5px', border: '1px solid' }}
                            />
                            <button
                                onClick={() => handleCommentSubmit(item.matched_product_id)}
                                style={{ backgroundColor: '#44a9af', color: 'white', padding: '5px 10px', borderRadius: '5px', marginLeft: '5px', border: 'none', cursor: 'pointer' }}
                            >
                                Submit
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>

            </table>
        </div>
        </>
    );
};

export default ProductTable;
