import React, { useState, useEffect } from 'react';
import '../assets/style/Services.css';
import * as XLSX from 'xlsx';
import Sidebar from './Sidebar';
import axios from 'axios';
import { toast } from 'react-toastify';

function Services({call}) {
  
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [services, setServices] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const location_name = localStorage.getItem('location_name');
  const [serviceUpload, setServiceUpload] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    setIsUploading(true);

    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsBinaryString(file);
    });

    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setServices(json.map((row) => ({ name: row['service_name'], status: 'accepted' })));

    // After successful file upload, send progress update
    await fetch(`${call}/service-upload-progress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pageName: "Service Upload", location_name: location_name, completed: 'Completed' }),
    });

    setIsSaving(true);
    const acceptedServices = services.map(service => service.name);

    await fetch(`${call}/store-service-names`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({serviceNames: json.map((row) => ({ name: row['service_name'], status: 'accepted' })).map(service => service.name), location_name: location_name}),
    });



    // Run /service-name-matching in the background without awaiting it
    await fetch(`${call}/service-name-matching`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Service name matching failed');
      }
      console.log('Service name matching completed');
    })
    .catch(error => {
      console.error('Error in service name matching:', error);
    });

    setIsSaving(false);
    setIsUploading(false);
    handleAutoSetup(2);
};


  const handleAutoSetup = async (value) => {
    try {
      await fetch(`${call}/set-auto-services`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name, value }),
      });
      toast.success("Services uploaded successfully");

     
    } catch (error) {
      console.error('Failed to upload services:', error);
    }
    await fetchAutoServiceStatus();
  };

  const fetchAutoServiceStatus = async () => {
    try {
        const response = await fetch(`${call}/get-auto-service-status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: location_name }),
        });
        const result = await response.json();
        setServiceUpload(result.service_upload);
    } catch (error) {
        console.error('Failed to fetch auto service status:', error);
    }
  };

  useEffect(() => {

    fetchAutoServiceStatus();

  }, [location_name]);


  const handleReport = async () => {
  
    const response = await axios.get(`${call}/download-services-sample-sheet`, {
      responseType: 'blob',
    });
  
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sample_sheet.xlsx');
    document.body.appendChild(link);
    link.click();
  };  

  return (
    <div className="page-wrapper-container">
    <div className="service-page">
      <div className="dashboard-layout">
      <Sidebar call={call}/>
      </div>
      <h1>Services Upload</h1>
      <>
      {serviceUpload === 2 ? (
        <div style={{ marginTop: '70px', marginLeft: '180px' }}>
          <p>The services are uploaded, Medvelle team will process this data and populate the services for you.</p>
        </div>
      ) : serviceUpload === -1 ? (
        <div style={{ marginTop: '70px', marginLeft: '180px' }}>
          <p>Please wait while Medvelle configures your services setup system.</p>
        </div>
      ) 
      : serviceUpload === 1 ? (
        <div style={{ marginTop: '70px', marginLeft: '180px' }}>
          <p>Medvelle Team is preparing your services list.</p>
        </div>
      )
      
      : (
        <>
          <div style={{ marginLeft: '350px', marginTop: '200px' }}>
            <p style={{ width: '500px' }}>
              Please upload your services sheet below, you may download the sample sheet to follow the column name convention, thank you.
            </p>
            <div className="file-upload-section" style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', marginLeft: '30px' }}>
              <label className="custom-file-upload" style={{ width: '350px', height: '70px', fontSize: '20px' }}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className="file-upload"
                  style={{ marginTop: '30px' }}
                />
                <p style={{ marginTop: '24px', marginLeft: '80px' }}>Manual File Upload</p>
              </label>
              {file && (
                <button onClick={handleFileUpload} className="upload-button" disabled={isUploading}>
                  Upload Your Sheet {isUploading && <span>Loading...</span>}
                </button>
              )}
              <button onClick={handleReport} className="add-product-button">
                Download Sample Sheet
              </button>
            </div>
          </div>
        </>
      )}
    </>

     
    </div>
    </div>

  );
}

export default Services;
