import React from 'react';
import '../assets/style/InventoryFinalize.css'; // Assuming you have a CSS file for styles
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './Sidebar';
import Logo from '../assets/images/logo.png'


function ServiceFinalize({call}) {

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');

  if (permission_code !== "11") {
    return <NonPrivilegedUserView call={call}/>;
  }

  return <PrivilegedUserView call={call}/>;
}

function NonPrivilegedUserView({call}) {
  return (
    <div className="inventory-matching-page">
      <Sidebar />
      <div className="processing-message">
        <img src={Logo} alt="Loading..." className="loading-gif" />
        Medvelle team is processing your data, thank you for your patience.
      </div>
    </div>
  );
}

function PrivilegedUserView({call}) {

  const matches = []; // Your match data here
  
  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');

  const pushMatchesToServer = async () => {
    await fetch(`${call}/push-service-matches`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body : JSON.stringify({location_name: location_name})
      
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.message);
      toast.success("Services Added Successfully!")
      // Handle success response
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors here
    });

    try {
      await fetch(`${call}/service-mapping-data`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({location_name: location_name})
      });
      // This will be executed after the fetch call is completed
  } catch (error) {
      console.error("Error during fetch operation:", error);
  }

  };

  return (
    
    <div className="center-container">
      <div className="dashboard-layout">
      <Sidebar/>
    </div>
      <div className="instructions">
        You can push the selected matches for services. This step pushes your selected services to our system.
      </div>
      <button onClick={pushMatchesToServer} className="upload-button">Push Matches</button>
      
      <ToastContainer />
    </div>
    
  );
}

export default ServiceFinalize;
