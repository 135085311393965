import React, { useState, useEffect } from 'react';
import '../assets/style/NewProducts.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import Sidebar from './Sidebar';
import Logo from '../assets/images/logo.png'


function NewProducts({call}) {

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');

  if (permission_code !== "11") {
    return <NonPrivilegedUserView call = {call}/>;
  }

  return <PrivilegedUserView call = {call}/>;
}

function NonPrivilegedUserView({call}) {
  return (
    <div className="inventory-matching-page">
      <Sidebar call={call}/>
      <div className="processing-message">
        <img src={Logo} alt="Loading..." className="loading-gif" />
        Medvelle team is processing your data, thank you for your patience.
      </div>
    </div>
  );
}
 
function PrivilegedUserView({call}) {

  const [products, setProducts] = useState([]);
  const [suppliers, setSupplierdropdown] = useState([]);
  const [supplierId, setSupplierId] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [category, setCategory] = useState([]);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProductForReMap, setSelectedProductForReMap] = useState(null);

  const [editProductId, setEditProductId] = useState(null);
  const [editProductName, setEditProductName] = useState("");

  const [manufacturers ,setManfacturerdropdown] = useState([]);
  const [categories ,setCategorydropdown] = useState([]);
  const location_name = localStorage.getItem('location_name');
  const [customManufacturer, setCustomManufacturer] = useState("");
  const [customCategory, setCustomCategory] = useState("");
  const [customSupplier, setCustomSupplier] = useState("");
const [isManufacturerOther, setIsManufacturerOther] = useState(false);
const [isCategoryOther, setIsCategoryOther] = useState(false);
const [isSupplierOther, setIsSupplierOther] = useState(false);


  const handleEditStart = (product) => {
    setEditProductId(product.matched_product_id);
    setEditProductName(product.matched_product_name);
  };

  const handleEditCancel = () => {
    setEditProductId(null);
    setEditProductName("");
  };

  const handleNameChange = (event) => {
    setEditProductName(event.target.value);
  };

  const handleClientReview = async () => {
    toast.success('Inventory is visible to client for review');

    const response = await fetch(`${call}/client-inventory-review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        value: 0,
        location_name: location_name
      })
    });

  }

  const handleEditSubmit = async (product) => {
    
    try {
      const response = await fetch(`${call}/edit-matched-product-name`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oldName: product.matched_product_name,
          newName: editProductName,
          productId: product.matched_product_id,
          location_name: location_name
        })
      });

      if (response.status != 200) {
        throw new Error('Failed to update product name');
      }

      
    } catch (error) {
      console.error('Error updating product name:', error);
    }
    console.log('name changed successfully')
    toast.success('Product name updated successfully');
    setEditProductId(null);
    setEditProductName("");
    fetchProducts();  // Refetch products to update the table
  };

  const handleRemove = async(product) => {
    const response = await fetch(`${call}/remove-new-product`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name: localStorage.getItem('location_name'), product_id: product.matched_product_id }),
  });
  toast.success('Product removed successfully');
  fetchProducts();  // Refetch products to update the table
  }

  function ProductModal({ isOpen, onClose, onSelectProduct, currentProductId }) {
    const [newProducts, setNewProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    
    
    useEffect(() => {
        if (!isOpen) return;

        const fetchNewProducts = async () => {
            try {
                const response = await fetch(`${call}/fetch-re-map-products`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ location_name: localStorage.getItem('location_name') }),
                });
                const data = await response.json();
                setNewProducts(data.products);
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        };
        fetchNewProducts();
    }, [isOpen]);  // Only refetch when modal opens

    const handleSelectProduct = async (selectedProductId) => {
      // Use the selectedProductId and currentProductId for re-mapping
      console.log('from new product',currentProductId, selectedProductId)
      try {
        
          const response = await fetch(`${call}/re-map-products`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  oldProductId: currentProductId,
                  newProductId: selectedProductId,
                  location_name: location_name
              })
          });
          
          if (response.ok) {
              toast.success('Product mapped successfully');
              
          } else {
              
          }
      } catch (error) {
          console.error('Error re-mapping product:', error);
          toast.error('Error re-mapping product');
      }
      fetchProducts();
      onClose();  // Close modal after API call
  };

    const filteredProducts = newProducts.filter(product =>
        product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{maxWidth: '800px',}}>
                <span className="close" onClick={onClose}>Close</span>
                <h2>Re-Map Product: {selectedProductForReMap.matched_product_name}</h2>
                <input
                  type="text"
                  placeholder="Search Product"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '30%', padding: '10px', marginBottom: '10px', border: '2px #87d3d7', fontStyle: 'italic', borderRadius: '5px' }}
              />
                <div className='product-table-wrapper'>
                    <table className="add-product-table">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Description</th>
                                <th>Manufacturer</th>
                                <th>Category</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map((product, index) => (
                                <tr key={index}>
                                    <td>{product.name}</td>
                                    <td>{product.description}</td>
                                    <td>{product.manufacturer_name}</td>
                                    <td>{product.product_cat}</td>
                                    <td>
                                        <button className= 'map-button' onClick={() => handleSelectProduct(product.id)}>
                                            Map
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <button className='done-button' onClick={onClose}>Done</button>
            </div>
        </div>
    );
}


const handleReMap = (product) => {
  setSelectedProductForReMap(product);
  setIsProductModalOpen(true);
};

  
  async function fetchProducts() {
    try {
      const response = await fetch(`${call}/fetch-new-products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: location_name })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setProducts(data.map(product => ({ ...product, description: '', manufacturer: '', category: '', quantityConversion: '', productUnit: '', productTier: '', supplierId: '', priceQuantity: '', price: ''})));
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }

    if(products.length>0){
      await fetch(`${call}/update-new-products-progress`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskName: 'New Products', location_name: location_name, completed: 'true' }),
    });
    }

  }
  useEffect(() => {
    
    async function fetchDetails() {
        try {
          const response = await fetch(`${call}/fetch-manufacturer-name`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          console.log('manu', data)
          const sortedManufacturers = data.sort((a, b) => a.manufacturer_name.localeCompare(b.manufacturer_name));
          setManfacturerdropdown(sortedManufacturers)
        } catch (error) {
          console.error('Failed to fetch products:', error);
        }

        try {
          const response = await fetch(`${call}/fetch-suppliers`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          const sortedSuppliers = data.sort((a, b) => a.supplier_name.localeCompare(b.supplier_name));
          setSupplierdropdown(sortedSuppliers)
        } catch (error) {
          console.error('Failed to fetch products:', error);
        }

        try {
          const response = await fetch(`${call}/fetch-product-category`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          const sortedCategories = data.sort((a, b) => a.product_cat.localeCompare(b.product_cat));
          setCategorydropdown(sortedCategories)
        } catch (error) {
          console.error('Failed to fetch products:', error);
        }
      }

    fetchProducts();
    fetchDetails();
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedProducts = products.map((product, i) => {
      if (i === index) {
        return { ...product, [field]: value };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

const handleSubmit = async (product) => {
    const fieldsToCheck = ['description', 'quantityConversion', 'productUnit', 'productTier', 'priceQuantity', 'price'];
    const isInvalid = fieldsToCheck.some(field => !product[field]);  

    if (isInvalid) {
        toast.error('Please fill out all fields before submitting.');
        return;
    }

const manufacturerToSubmit = isManufacturerOther ? customManufacturer : manufacturer;
const categoryToSubmit = isCategoryOther ? customCategory : category;
const supplierIdToSubmit = isSupplierOther ? customSupplier : supplierId;



    try {
        const response = await fetch(`${call}/insert-product-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                product: product, manufacturer_name: manufacturerToSubmit, category: categoryToSubmit,
                supplierId: supplierIdToSubmit,
                location_name: location_name
            }),

        });

        if (!response.ok) {
            throw new Error('Failed to submit product data');
        }
        toast.success('Product Information updated successfully');
        fetchProducts();
    } catch (error) {
        console.error('Error submitting product:', error);
    }
};

  return (
    <div className="page-wrapper-container">
    <div className="product-container">
        <div className="dashboard-layout">
        <Sidebar call={call}/>
        </div>
        <h1>New Products</h1>
        <div className='table-wrapper'>
        <table className='products-table'>
          <thead>
            <tr>
            <th>Remove</th>
            <th>Re-Map</th>
              <th className="name" style={{width: '100px'}} >Name</th>
              <th>Description</th>
              <th>Manufacturer</th>
              <th>Category</th>
              <th>Quantity Conversion</th>
              <th>Product Unit</th>
              <th>Product Tier</th>
              <th>Supplier Name</th>
              <th>Price Quantity</th>
              <th>Price</th>
              
              <th>Submit</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.matched_product_id}>
                <td><button className='submit-button' onClick={() => handleRemove(product)}>Remove</button></td>

                <td><button className='submit-button' onClick={() => handleReMap(product)}>Re-Map</button></td>
            <td>
                {editProductId === product.matched_product_id ? (
                  <input
                    type="text"
                    value={editProductName}
                    onChange={handleNameChange}
                  />
                ) : (
                  product.matched_product_name
                )}
              
              {editProductId === product.matched_product_id ? (
                <td>
                  <button onClick={() => handleEditSubmit(product)}>Save</button>
                  <button onClick={handleEditCancel}>Cancel</button>
                </td>
              ) : (
                <td>
                  <button onClick={() => handleEditStart(product)}>
                  <i style={{ fontSize: "12px" }} className="fa">&#xf040;</i>


                  </button>
                </td>
              )}
              </td>

                
                <td><input type="text" value={product.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)} required/></td>
                <td>
                 <select
    onChange={(e) => {
        const value = e.target.value;
        if (value === 'Other') {
            setIsManufacturerOther(true);
            setManufacturer('');
        } else {
            setIsManufacturerOther(false);
            setManufacturer(value);
        }
    }}
    style={{ padding: '5px', fontStyle: 'italic', width: '150px' }}
>
    <option value="">Select Manufacturer</option>
    {manufacturers.map(({ manufacturer_name }) => (
        <option key={manufacturer_name} value={manufacturer_name}>
            {manufacturer_name}
        </option>
    ))}
    <option value="Other">Other</option>
</select>
{isManufacturerOther && (
    <input
        type="text"
        value={customManufacturer}
        placeholder="Enter custom manufacturer"
        onChange={(e) => setCustomManufacturer(e.target.value)}
        style={{ width: '150px', marginTop: '5px' }}
    />
)}


        
        </td>       
                 
        <td>
          <select
    onChange={(e) => {
        const value = e.target.value;
        if (value === 'Other') {
            setIsCategoryOther(true);
            setCategory('');
        } else {
            setIsCategoryOther(false);
            setCategory(value);
        }
    }}
    style={{ padding: '5px', fontStyle: 'italic', width: '150px' }}
>
    <option value="default">Select Category</option>
    {categories.map(({ product_cat }) => (
        <option key={product_cat} value={product_cat}>
            {product_cat}
        </option>
    ))}
    <option value="Other">Other</option>
</select>
{isCategoryOther && (
    <input
        type="text"
        value={customCategory}
        placeholder="Enter custom category"
        onChange={(e) => setCustomCategory(e.target.value)}
        style={{ width: '150px', marginTop: '5px' }}
    />
)}

          
          </td> 
                <td><input type="text" value={product.quantityConversion} onChange={(e) => handleInputChange(index, 'quantityConversion', e.target.value)} required /></td>
                <td><input type="text" value={product.productUnit} onChange={(e) => handleInputChange(index, 'productUnit', e.target.value)} required/></td>
                <td><input type="text" value={product.productTier} onChange={(e) => handleInputChange(index, 'productTier', e.target.value)} required/></td>
                <td>
<select
    onChange={(e) => {
        const value = e.target.value;
        if (value === 'Other') {
            setIsSupplierOther(true);
            setSupplierId('');
        } else {
            setIsSupplierOther(false);
            setSupplierId(value);
        }
    }}
    style={{ padding: '5px', fontStyle: 'italic', width: '150px' }}
>
    <option value="">Select Supplier</option>
    {suppliers.map(({ supplier_id, supplier_name }) => (
        <option key={supplier_id} value={supplier_id}>
            {supplier_name}
        </option>
    ))}
    <option value="Other">Other</option>
</select>
{isSupplierOther && (
    <input
        type="text"
        value={customSupplier}
        placeholder="Enter custom supplier"
        onChange={(e) => setCustomSupplier(e.target.value)}
        style={{ width: '150px', marginTop: '5px' }}
    />
)}




                </td>

                <td><input type="text" value={product.priceQuantity} onChange={(e) => handleInputChange(index, 'priceQuantity', e.target.value)} required/></td>
                <td><input type="text" value={product.price} onChange={(e) => handleInputChange(index, 'price', e.target.value)} required/></td>
                
                <td><button className='submit-button' onClick={() => handleSubmit(product)}>Submit</button></td>
              </tr>
            ))}
          </tbody>
        </table>

        {isProductModalOpen && (
            <ProductModal
                isOpen={isProductModalOpen}
                onClose={() => setIsProductModalOpen(false)}
                currentProductId={selectedProductForReMap.matched_product_id}
                onSelectProduct={(selectedProductId) => {
                console.log("Selected product ID:", selectedProductId);
                // Handle additional logic here if needed
            }}
            />
        )}
        
        </div>
        <ToastContainer />
        <button className='submit-button' style={{width: '200px', marginTop: '20px'}} onClick={() => handleClientReview()}>Trigger Client Review</button>
        
    </div>
    </div>
  );
}

export default NewProducts;
