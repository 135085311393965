import React from 'react';
import '../assets/style/InitialPage.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function InitialPage() {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle button clicks
  const handleButtonClick = (type) => {
    if (type === 'New') {
      navigate('/journeyselection'); // Use the navigate function to redirect
    } else {
      console.log('Existing onboarding clicked');
      navigate('/authentication'); // Use the navigate function to redirect
    }
  };

  return (
    <div className="initial-page">
      <h1>👋 Welcome to Medvelle Onboarding</h1>
      <div className="onboarding-options">
        <h2>We are happy to have you with us!</h2>
        <button onClick={() => handleButtonClick('Existing')}>Get started</button>
      </div>
    </div>
  );
}

export default InitialPage;
