// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';


// Your web app's Firebase configuration (replace with your own config values)
const firebaseConfig = {
    apiKey: "AIzaSyAElzssE0_p9BTvnuBoApemrLI99fPnKgk",
    authDomain: "medvelle-onboarding.firebaseapp.com",
    projectId: "medvelle-onboarding",
    storageBucket: "medvelle-onboarding.appspot.com",
    messagingSenderId: "808695432218",
    appId: "1:808695432218:web:79aff1820023724dc3bda3",
    measurementId: "G-ZP7X05BNRH"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open at once, persistence can only be enabled in one tab at a time.
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the features required to enable persistence.
    }
  });


export default app;
