import React from 'react';
import '../assets/style/InventoryFinalize.css'; // Assuming you have a CSS file for styles
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './Sidebar';


function InventoryFinalize({call}) {
  const matches = []; // Your match data here

  const email = localStorage.getItem('email');
  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');

  const pushMatchesToServer = () => {
    fetch(`${call}/push-product-matches`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({location_name: location_name})
      
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.message);
      toast.success("Products Added to Inventory")
      // Handle success response
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors here
    });
  };

   // Conditional rendering based on permission_code
   if (permission_code !== "21") {
    return (
      <div className="inventory-matching-page">
        <div className="dashboard-layout">
          <Sidebar />
        </div>
        <div className="processing-message">
          Medvelle team is processing your data, thank you for your patience
        </div>
      </div>
    );
  }


  return (
    
    <div className="center-container">
      <div className="dashboard-layout">
      <Sidebar/>
    </div>
      <div className="instructions">
        You can push the selected matches for inventory products. Note that this step pushes the products to live inventory.
      </div>
      <button onClick={pushMatchesToServer} className="upload-button">Push Matches</button>
      <ToastContainer />
    </div>
    
  );
}

export default InventoryFinalize;
