import React, { useState, useEffect } from 'react';
import '../assets/style/InventoryPage.css';
import * as XLSX from 'xlsx';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Sidebar from './Sidebar';
import axios from 'axios';

function InventoryPage({ call }) {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const location_name = localStorage.getItem('location_name');
  const [autoInventory, setAutoInventory] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleAutoSetup = async (value) => {
    try {
      await fetch(`${call}/set-auto-inventory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name, value }),
      });
      toast.success("Auto Inventory Setup initiated successfully");

      if (value === 1) {
        await fetch(`${call}/insert-auto-setup-products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location_name }),
        });
      }
    } catch (error) {
      console.error('Failed to initiate auto inventory setup:', error);
    }
    await fetchAutoInventoryStatus();
  };

  const handleFileUpload = async () => {
    await handleAutoSetup(2);
    setIsUploading(true);

    try {
      const data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsBinaryString(file);
      });

      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const productNames = json.map(row => `${row['product_name']}`);

      await fetch(`${call}/store-product-names`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productNames, location_name }),
      });

      await fetch(`${call}/inventory-upload-progress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pageName: "Inventory Upload", location_name, completed: 'Completed' }),
      });

      await fetch(`${call}/product-name-matching`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });

      toast.success("Information Saved Successfully");
    } catch (error) {
      console.error('Failed to upload file:', error);
      toast.error("Failed to upload file");
    } finally {
      setIsUploading(false);
      fetchAutoInventoryStatus();
    }
  };

  const fetchAutoInventoryStatus = async () => {
    try {
      const response = await fetch(`${call}/get-auto-inventory-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name }),
      });
      const result = await response.json();
      setAutoInventory(result.auto_inventory);
    } catch (error) {
      console.error('Failed to fetch auto inventory status:', error);
    }
  };

  useEffect(() => {
    fetchAutoInventoryStatus();
  }, []);

  const handleReport = async () => {
    try {
      const response = await axios.get(`${call}/download-sample-sheet`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sample_sheet.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Failed to download sample sheet:', error);
    }
  };

  return (
    <div className="page-wrapper-container">
    <div className="inventory-page">
      <div className="dashboard-layout">
        <Sidebar call={call} />
      </div>
      <h1>Inventory Setup</h1>
      <>
        {autoInventory === 2 ? (
          <div style={{ marginTop: '70px', marginLeft: '180px' }}>
            <p>Thank you for uploading the products, Medvelle team will process this data and populate the inventory for you.</p>
          </div>
        ) : autoInventory === -1 ? (
          <div style={{ marginTop: '70px', marginLeft: '180px' }}>
            <p>Please wait while Medvelle configures your inventory system.</p>
          </div>
        ) : (
          <>
            <div style={{ marginLeft: '350px', marginTop: '200px' }}>
              <p style={{ width: '500px' }}>
                Please upload your product sheet below, you may download the sample sheet to follow the column name convention, thank you.
              </p>
              <div className="file-upload-section" style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', marginLeft: '30px' }}>
                <label className="custom-file-upload" style={{ width: '350px', height: '70px', fontSize: '20px' }}>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className="file-upload"
                    style={{ marginTop: '30px' }}
                  />
                  <p style={{ marginTop: '24px', marginLeft: '80px' }}>Manual File Upload</p>
                </label>
                {file && (
                  <button onClick={handleFileUpload} className="upload-button" disabled={isUploading}>
                    Upload Your Sheet {isUploading && <span>Loading...</span>}
                  </button>
                )}
                <button onClick={handleReport} className="add-product-button">
                  Download Sample Sheet
                </button>
              </div>
            </div>
          </>
        )}
      </>
    </div>
    </div>
  );
}

export default InventoryPage;