import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import ProductTable from '../features/yourinventory/ProductTable';
import ProductModal from '../features/yourinventory/ProductModal';
import { fetchAutoInventoryStatus, fetchClientReview, fetchInventory, fetchProducts } from '../utils/InventoryUtils';

function YourInventory({ call }) {
    const location_name = localStorage.getItem('location_name');
    const [inventoryData, setInventoryData] = useState([]);
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [comments, setComments] = useState({});
    const [clientReview, setClientReview] = useState(-1);
    const [autoInventory, setAutoInventory] = useState(0);
    const [newProductName, setNewProductName] = useState('');

    useEffect(() => {
        fetchInventory(call, location_name, setInventoryData, setComments);
        fetchClientReview(call, location_name, setClientReview);
        fetchAutoInventoryStatus(call, location_name, setAutoInventory);
    }, [call, location_name]);

    useEffect(() => {
        fetchProducts(call, location_name, inventoryData, setProducts);
    }, [call, location_name, inventoryData]);

    const handleAddProducts = async () => {
        const productsToAdd = selectedProducts.map(product => ({
            productId: product.id,
            productName: product.name,
            location_name: location_name,
        }));

        try {
            const response = await fetch(`${call}/add-products-to-inventory`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ products: productsToAdd }),
            });
            const data = await response.json();
            if (data.success) {
                toast.success('Products added successfully');
                fetchInventory(call, location_name, setInventoryData, setComments);
                fetchProducts(call, location_name, inventoryData, setProducts);
            }
        } catch (error) {
            console.error('Error adding products:', error);
        }
    };

    // Handle adding a new product manually
    const handleNewProductSubmit = async () => {
        try {
            await fetch(`${call}/include-manual-product`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productName: newProductName, location_name }),
            });


        } catch (error) {
            console.error('Error adding new product:', error);
        }

        toast.success('New Product included successfully');
        fetchInventory(call, location_name, setInventoryData, setComments);
        setIsProductModalOpen(false); // Close the modal after adding
    };


    const handleRemove = async (product) => {
        try {
            await fetch(`${call}/remove-new-product`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name, product_id: product.matched_product_id }),
            });
            toast.success('Product removed successfully');
            fetchInventory(call, location_name, setInventoryData, setComments);
        } catch (error) {
            console.error('Error removing product:', error);
        }
    };

    return (
        <div className="page-wrapper-container">
        <div className="inventory-page">
            <div className="dashboard-layout">
                <Sidebar call={call} />
            </div>
            <h1>Your Inventory</h1>
            {autoInventory !== 1 && clientReview === -1 ? (
                <div style={{ textAlign: 'center', marginTop: '50px', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                    Medvelle Team is preparing your opening order.
                </div>
            ) : (
                <>
                    <ProductTable 
                        inventoryData={inventoryData} 
                        comments={comments} 
                        setComments={setComments} 
                        handleRemove={handleRemove}
                        call={call}
                    />
                    <button 
                        style={{ backgroundColor: '#87d3d7', borderRadius: '5px', cursor: 'pointer', padding: '1vw', marginTop: '3vh', marginLeft: '36vw', border: 'none' }} 
                        onClick={() => setIsProductModalOpen(true)}
                    >
                        Add Product
                    </button>
                </>
            )}

            <ProductModal
                isOpen={isProductModalOpen}
                onClose={() => setIsProductModalOpen(false)}
                products={products}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                newProductName = {newProductName}
                setNewProductName = {setNewProductName}
                handleAddProducts={handleAddProducts}
                handleNewProductSubmit = {handleNewProductSubmit}
                fetchInventory={fetchInventory}
                call={call}
                location_name={location_name}
            />
        </div>
        </div>

    );
}

export default YourInventory;
