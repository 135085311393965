import React from 'react';
import '../assets/style/FinalReview.css'; // Make sure your CSS file path is correct
import Logo from '../assets/images/logo.png'

function FinalReview() {

  return (
    <div className="final-review-container">
      <img src={Logo} alt="Medvelle Logo" className="center-logo" />
      <h1>Thank you for Onboarding with us!</h1>
    </div>
  );
}

export default FinalReview;
