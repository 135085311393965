import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InitialPage from './pages/InitialPage';
import JourneySelection from './pages/JourneySelection'; // Import the component
import Dashboard from './pages/Dashboard'; // Import the Dashboard component
import Authentication from './pages/authentication/Authentication'; // Import your Authentication component
import WelcomePage from './pages/Welcome';
import InventoryPage from './pages/InventoryPage';
import Accounts from './pages/AccountsPage';
import ProtectedRoute from './pages/ProtectedRoute'; // Import the ProtectedRoute component
import Services from './pages/Services';
import InventoryFinalize from './pages/InventoryFinalize';
import ServiceFinalize from './pages/ServiceFinalize';
import ServiceMapping from './pages/ServiceMapping';
import VendorInformation from './pages/Vendor';
import ServiceNameMatching from './pages/ServiceNameMatching';
import InventoryMatching from './pages/InventoryMatching';
import FinalReview from './pages/FinalReview';
import MedvelleTeam from './pages/MedvelleTeam';
import NewProducts from './pages/NewProducts';
import ServiceSelection from './pages/ServiceSelection';
import YourInventory from './pages/YourInventory';
import OpeningOrder from './pages/OpeningOrder';

function App() {

  const call = process.env.REACT_APP_BACKEND_URL;

  return (
  
    <Router>
      <Routes>
     
        <Route path="/" element={<InitialPage />} />
        <Route path="/journeyselection" element={<ProtectedRoute><JourneySelection /></ProtectedRoute>} /> 
        <Route path="/authentication" element={<Authentication call={call}/>} />
        <Route path="/welcome" element={<ProtectedRoute><WelcomePage call={call}/></ProtectedRoute>} />
        <Route path="/vendor-information" element={<ProtectedRoute><VendorInformation call={call}/></ProtectedRoute>} />
        <Route path="/inventory-upload" element={<ProtectedRoute><InventoryPage call={call}/></ProtectedRoute>} />
        <Route path="/inventory-matching" element={<ProtectedRoute><InventoryMatching call={call}/></ProtectedRoute>} />
        <Route path="/inventory-finalize" element={<ProtectedRoute><InventoryFinalize call={call}/></ProtectedRoute>} />
        <Route path="/your-inventory" element={<ProtectedRoute><YourInventory call={call}/></ProtectedRoute>} />
        <Route path="/opening-order" element={<ProtectedRoute><OpeningOrder call={call}/></ProtectedRoute>} />
        <Route path="/services" element={<ProtectedRoute><Services call={call}/></ProtectedRoute>} />
        <Route path="/review" element={<ProtectedRoute><FinalReview /></ProtectedRoute>} />
        <Route path="/service-selection" element={<ProtectedRoute><ServiceSelection call={call}/></ProtectedRoute>} />
        <Route path="/service-matching" element={<ProtectedRoute><ServiceNameMatching call={call}/></ProtectedRoute>} />
        <Route path="/services-finalize" element={<ProtectedRoute><ServiceFinalize call={call}/></ProtectedRoute>} />
        <Route path="/service-mapping" element={<ProtectedRoute><ServiceMapping call={call}/></ProtectedRoute>} />
        <Route path="/accounts" element={<ProtectedRoute><Accounts /></ProtectedRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard call={call}/></ProtectedRoute>} /> 
        <Route path="/new-products" element={<ProtectedRoute><NewProducts call={call}/></ProtectedRoute>} /> 
        <Route path="/medvelle-team" element={<ProtectedRoute><MedvelleTeam call={call}/></ProtectedRoute>} /> 
      </Routes>
    </Router>
  );
}

export default App;
