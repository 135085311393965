import React, { useState } from 'react';
import '../assets/style/JourneySelection.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


function JourneySelection() {
    const navigate = useNavigate();
  const [visibleTooltip, setVisibleTooltip] = useState('');

  const handleOptionClick = (option) => {
    if (option === 'Copilot') {
      navigate('/dashboard'); // Navigate to the Dashboard
    } else {
      // Handle other options as needed
      console.log(`${option} Selected`);
    }
  };

  return (
    <div className="journey-selection">
      <div className="journey-box">
        <h2>Select the Onboarding Journey  🚀</h2>
        <p className="subtitle">Select in what capacity do you want Medvelle to help you with this onboarding</p>
        <div className="options">
          {['Assistive', 'Independent', 'Copilot'].map((option, index) => (
            <div key={index} className="option-container">
              <button
                onMouseEnter={() => setVisibleTooltip(option)}
                onMouseLeave={() => setVisibleTooltip('')}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </button>
              {visibleTooltip === option && (
                <div className="tooltip">{getTooltipContent(option)}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// Function to return tooltip content based on the option
function getTooltipContent(option) {
  const tooltips = {
    'Assistive': 'Medvelle assists throughout the process',
    'Independent': 'Navigate the process on your own with minimal Medvelle intervention',
    'Copilot': 'Medvelle guides you through each step of the process'
  };
  return tooltips[option];
}

export default JourneySelection;
