import React, { useEffect, useState } from 'react';
import '../assets/style/Services.css';
import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function ServiceSelection({call}) {

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [services, setServices] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const fetchServices = async () => {
    const response = await fetch(`${call}/fetch-uploaded-services`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({location_name }),
      });

      const data = await response.json();
      
      setServices(data)
    }
  
  useEffect(() => {

    

    fetchServices();

    }, []);

    const handleServiceAction = (index, action) => {
      const updatedServices = services.map((service, i) => {
        if (i === index) {
          return { ...service, status: service.status === action ? 'pending' : action, isAccepted: action === 'accepted' ? 1 : 0 };
        }
        return service;
      });
      setServices(updatedServices);
    };

  const handleSave = async () => {
    setIsSaving(true);
    const rejectedServices = services.filter(service => service.status === 'rejected').map(service => service.service_name);

    await fetch(`${call}/reject-service-names`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({serviceNames: rejectedServices, location_name: location_name}),
    });

    await fetch(`${call}/update-service-selection-progress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({taskName: 'Service Selection', location_name: location_name, completed: 'true'}),
    });


    setIsSaving(false);
    fetchServices();
    toast.success('Service Processed')

    const response = await fetch(`${call}/service-name-matching`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name }),
    });
  };

  const hasAcceptedServices = services.some(service => service.status === 'rejected');

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };


  return (
    <div className="service-page">
      <div className="dashboard-layout">
        <Sidebar call={call}/>
      </div>
      <div className='heading' style={{marginTop: '-21.5px'}}>
          <h1>Service Selection</h1>
          <button className="info-button" onClick={togglePopup}>&#x2139;</button>
          </div>

          {isPopupVisible && (
            <div className="popup">
            <div className="popup-inner">
          <><p style={{ color: 'grey', textDecoration: 'underline' }}>Please Note:</p><p>These are all the services that you currently offer, please remove any services you would like to stop offering.</p></>
          <button className="submit-button" onClick={togglePopup}>Close</button>
          </div>
          </div>
          )}
    
      {services.length > 0 && (
        <><div style={{
          border: '2px solid #87D3D7',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxHeight: '600px',
          backgroundColor: 'white',
        }}>
          <div style={{
            maxHeight: '600px',
            overflowY: 'auto',
          }}>
            <table style={{
                    width: '100%',
                    borderCollapse: 'separate',
                    borderSpacing: 0,
                    position: 'relative',}} >
              <thead style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
              }}>
                <tr >
                  <th style={{borderBottom: '2px solid black', textAlign: 'left', padding: '10px'}}>Service Name</th>
                  <th style={{borderBottom: '2px solid black', textAlign: 'center', padding: '10px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {services.map((service, index) => (
                  <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                    <td style={{textAlign: 'left', padding: '10px'}}>{service.service_name}</td>
                    <td style={{textAlign: 'center'}}>
                      <button
                        onClick={() => handleServiceAction(index, 'accepted')}
                        className={service.isAccepted === 1 ? 'accepted' : 'selection'}
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleServiceAction(index, 'rejected')}
                        className={service.isAccepted === 0 ? 'rejected' : 'selection'}
                        style={{ marginLeft: '10px' }}
                      >
                        Reject
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div><button onClick={handleSave} className="save-button" >
            Submit Services {isSaving && <span>Saving...</span>}
          </button></>
      )}

    <ToastContainer />
    </div>
  );
}

export default ServiceSelection;
