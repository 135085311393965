import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import '../assets/style/MedvelleTeam.css';
import Sidebar from './Sidebar';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import axios from 'axios';


const MedvelleTeam = ({ call }) => {
    const [locationName, setLocationName] = useState(localStorage.getItem('location_name'));
    const [allLocations, setAllLocations] = useState([]);
    const [allLiveLocations, setAllLiveLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [isCreateLocationModalOpen, setIsCreateLocationModalOpen] = useState(false);
    const [newLocationName, setNewLocationName] = useState('');
    const [activeTab, setActiveTab] = useState('Progress');
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const email = localStorage.getItem('email'); 
    const [autoInventory, setAutoInventory] = useState(0);
    const [productFlags, setProductFlags] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [editedVendors, setEditedVendors] = useState([]);
    const [serviceUpload, setServiceUpload] = useState(0);
    const permission_code = localStorage.getItem('permission_code');
    const [steps, setSteps] = useState({});
    const [users, setUsers] = useState([]);
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [services, setServices] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [openingOrderStatus, setOpeningOrderStatus] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState(locationName);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [filteredVendors, setFilteredVendors] = useState([]);

    useEffect(() => {
        fetchTaskStatuses();
        fetchUsers();
        fetchOpeningOrderStatus();
      }, [locationName]);

    
    useEffect(() => {
        let filteredData = vendors;

        if (selectedLocation) {
            filteredData = filteredData.filter(vendor => vendor.location_name === selectedLocation);
        }

        if (selectedSupplier) {
            filteredData = filteredData.filter(vendor => vendor.supplier_name === selectedSupplier);
        }

        setFilteredVendors(filteredData);
    }, [selectedLocation, selectedSupplier, vendors]);

    const fetchUsers = async () => {
        fetch(`${call}/get-users`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
          })
          .then(response => response.json())
          .then(data => {
            setUsers(data);
          })
          .catch(error => console.error('Error fetching users:', error)); 
    }

    const fetchAutoInventoryStatus = async () => {
        try {
          const response = await fetch(`${call}/get-auto-inventory-status`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName }),
          });
          const result = await response.json();
          setAutoInventory(result.auto_inventory);
        } catch (error) {
          console.error('Failed to fetch auto inventory status:', error);
        }
      };

    const fetchAutoServiceStatus = async () => {
    try {
        const response = await fetch(`${call}/get-auto-service-status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
        });
        const result = await response.json();
        setServiceUpload(result.service_upload);
    } catch (error) {
        console.error('Failed to fetch auto service status:', error);
    }
    };



    const fetchVendorsData = async () => {
        const allVendorsResponse = await fetch(`${call}/all-vendors-data`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name: locationName }),
          });
          
          const allVendorsData = await allVendorsResponse.json();
  
          setVendors(allVendorsData);

    }

    const CreateNewLocation = async () => {
        if (newLocationName.trim() === '') {
            toast.error('Please enter a location name');
            return;
        }
    
        try {
            const response = await fetch(`${call}/create-new-location`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name: newLocationName }),
            });
            
            if (response.ok) {
                toast.success('Location created successfully');
                setIsCreateLocationModalOpen(false); // Close modal after creation
                setNewLocationName(''); // Reset input field
            } else {
                toast.error('Failed to create location');
            }
        } catch (error) {
            console.error('Error creating location:', error);
            toast.error('Error creating location');
        }
    };
    

    const fetchOpeningOrderStatus = async () => {
        try {
            const response = await fetch(`${call}/get-opening-order-status`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name: locationName }),
            });
            const result = await response.json();
            setOpeningOrderStatus(result.opening_order);
        } catch (error) {
            console.error('Failed to fetch opening order status:', error);
        }
    };

    const handleVendorStatusChange = async (e, supplier_name) => {
        const newStatus = e.target.value;
    
        try {
            await fetch(`${call}/change-vendor-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ supplier_name: supplier_name, status: newStatus, location_name: locationName }),
            });
            toast.success('Vendor status updated successfully');
            setEditedVendors((prevVendors) =>
                prevVendors.map(vendor =>
                    vendor.supplier_name === supplier_name ? { ...vendor, status: newStatus } : vendor
                )
            );
        } catch (error) {
            console.error('Failed to update vendor status:', error);
            toast.error('Failed to update vendor status');
        }

        fetchVendorsData();
    };
    

      const fetchInventory = async () => {
        try {
            const response = await fetch(`${call}/fetch-new-products`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProductFlags(data);
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
    };

    const fetchTaskStatuses = async () => {
        try {
          const response = await fetch(`${call}/task-statuses`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name: locationName }),
          });
          const taskData = await response.json();
    
          // Initialize steps with data from task-statuses
          const updatedSteps = initializeStepsWithTaskData(taskData);
          setSteps(updatedSteps);
        } catch (error) {
          console.error('Failed to fetch task statuses:', error);
        }
      };

      
    
      // Initialize steps with task data from the API response
      const initializeStepsWithTaskData = (taskData) => {
        // Default steps configuration (without status and due date)
        const defaultSteps = initializeSteps();
    
        // Loop over the taskData and update the relevant task's status and due date
        taskData.forEach(task => {
          Object.values(defaultSteps).forEach(step => {
            const matchingTask = step.tasks.find(t => t.name === task.page);
            if (matchingTask) {
              matchingTask.completed = task.status; // Set completion status
              matchingTask.dueDate = task.due_date; // Set due date
            }
          });
        });
    
        return defaultSteps;
      };
    

    const initializeSteps = () => {
        return {
          'Initial Setup': {
            title: 'Step 1',
            completed: true,
            dueDate: '04/01',
            tasks: [
              { name: 'Location Details', path: '/welcome', completed: 'Incomplete' },
              { name: 'Vendor Information', path: '/vendor-information', completed: 'Incomplete' }
            ],
            progress: 0,
          },
          'Services': {
            title: 'Step 2',
            completed: false,
            dueDate: '04/15',
            tasks: [
            { name: `Service Setup`, path: '/services', completed: 'Incomplete' },
            { name: 'Service Matching', path: '/service-matching', completed: 'Incomplete' },
            ],
            progress: 0,
          },
          'Products and Mapping': {
            title: 'Step 3',
            completed: false,
            dueDate: '04/07',
            tasks: [
              { name: `Inventory Setup`, path: '/inventory-upload', completed: 'Incomplete' },
              { name: 'Inventory Matching', path: '/inventory-matching', completed: 'Incomplete' },
              { name: 'New Products', path: '/new-products', completed: 'Incomplete' },
              { name: `Your Inventory`, path: '/your-inventory', completed: 'Incomplete' },
              { name: 'Mapping', path: '/service-mapping', completed: 'Incomplete' }
            ],
            progress: 0,
          }
        };
      };

      useEffect(() => {
        setSteps(initializeSteps(autoInventory, permission_code));
      }, [autoInventory, permission_code]);
    
      const handleStatusChange = async (e, task) => {
        const newStatus = e.target.value;
        const updatedSteps = { ...steps };
    
        // Find the task in the current steps and update only the status
        Object.values(updatedSteps).forEach(step => {
            step.tasks.forEach(t => {
                if (t.name === task.name) {
                    t.completed = newStatus; // Update status
                    t.dueDate = task.dueDate; // Preserve due date
                }
            });
        });
    
        try {
            await fetch(`${call}/update-page-progress`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    location_name: locationName,
                    page_name: task.name,
                    status: newStatus,
                    due_date: task.dueDate // Ensure due date is sent
                }),
            });
            setSteps(updatedSteps); // Immediately update UI with new status and preserved due date
            toast.success('Status updated successfully');
        } catch (error) {
            console.error('Failed to update status:', error);
            toast.error('Failed to update status');
        }
    };

    const handleToggleChange = async (e) => {
        const newValue = e.target.checked ? 2 : 1;
        setOpeningOrderStatus(newValue);
        
        try {
            await fetch(`${call}/set-opening-order-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName, value: newValue }),
            });
        } catch (error) {
            console.error('Error updating visibility:', error);
            toast.error('Failed to update opening order visibility');
        }
    };

    const handleDateChange = async (date, task) => {
        const updatedSteps = { ...steps };
        
        // Loop over each step to find and update the relevant task
        Object.entries(updatedSteps).forEach(([stepKey, step]) => {
            step.tasks.forEach(t => {
                if (t.name === task.name) {
                    t.dueDate = date.toISOString().split('T')[0]; // Update due date
                    t.completed = task.completed; // Preserve the original status from the task argument
                }
            });
        });
    
        try {
            await fetch(`${call}/update-page-progress`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    location_name: locationName,
                    page_name: task.name,
                    due_date: date.toISOString().split('T')[0], // Send updated due date
                    status: task.completed // Send the original status
                }),
            });
            setSteps(updatedSteps); // Immediately update the state with the new due date
            toast.success('Due date updated successfully');
        } catch (error) {
            console.error('Failed to update due date:', error);
            toast.error('Failed to update due date');
        }
    };
    

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };
    

    useEffect(() => {
        const fetchLiveLocations = async () => {
            const response = await fetch(`${call}/all-live-locations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAllLiveLocations(data);
        };    
        
        const fetchLocations = async () => {
            const response = await fetch(`${call}/list-locations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: email }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAllLocations(data);
        };        

        fetchAutoInventoryStatus();
        fetchAutoServiceStatus();
        fetchLocations();
        fetchInventory();
        fetchVendorsData();
        fetchLiveLocations();
    }, [locationName]);

    const handleUsersLive = async () => {
        try {
            await fetch(`${call}/move-users-live`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName }),
            });
            toast.success(`Step completed successfully`);
        } catch (error) {
            console.error('Network error:', error);
        }
    };
    

    
    const handleInventory = async () => {
        try {
            await fetch(`${call}/move-inventory-live`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName }),
            });
            toast.success(`Step completed successfully`);
        } catch (error) {
            console.error('Network error:', error);
        }
    };
    
    const handleServices = async () => {
        try {
            await fetch(`${call}/move-services-live`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName }),
            });
            toast.success(`Step completed successfully`);
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    const handleReversePush = async () => {
        try {
            await fetch(`${call}/reverse-push`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName }),
            });
            toast.success(`Step completed successfully`);
        } catch (error) {
            console.error('Network error:', error);
        }
    };
    

    const handleLocationChange = (e) => {
        setLocationName(e.target.value);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleAutoSetup = () => {
        setIsModalOpen(true);
    };

    const handleLocationCheckboxChange = (e, location) => {
        const { checked } = e.target;
        setSelectedLocations((prevSelected) =>
            checked
                ? [...prevSelected, location]
                : prevSelected.filter((loc) => loc !== location)
        );
    };

    const handleAutoGenerate = async () => {
        setIsModalOpen(false);
        try {
            await fetch(`${call}/set-auto-inventory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName, value: 1 }),
            });
        } catch (error) {
            console.error('Failed to initiate manual inventory setup:', error);
            toast.error("Failed to initiate Manual Inventory Setup");
        }

        fetchAutoInventoryStatus();

        try {
            await fetch(`${call}/insert-auto-setup-products`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName, selectedLocations }),
            });
            toast.success("Auto Inventory Setup initiated successfully");
            
        } catch (error) {
            console.error('Failed to push auto inventory setup:', error);
            toast.error("Failed to initiate Auto Inventory Setup");
        }

        
    };

    const handleManualSetup = async () => {
        try {
            await fetch(`${call}/set-auto-inventory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName, value: 0 }),
            });
            toast.success("Manual Inventory Setup initiated successfully");
        } catch (error) {
            console.error('Failed to initiate manual inventory setup:', error);
            toast.error("Failed to initiate Manual Inventory Setup");
        }

        fetchAutoInventoryStatus();
    };

    const handleServiceAutoGenerate = async () => {

        try {
            await fetch(`${call}/set-auto-services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName, value: 1 }),
            });
        } catch (error) {
            console.error('Failed to initiate service setup:', error);
            toast.error("Failed to initiate service Setup");
        }


        fetchAutoServiceStatus();
    };

    const handleReport = async () => {
  
        const response = await axios.get(`${call}/download-services-sample-sheet`, {
          responseType: 'blob',
        });
      
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sample_sheet.xlsx');
        document.body.appendChild(link);
        link.click();
      };  

      const handleAutoServiceSetup = async (value) => {
        try {
          await fetch(`${call}/set-auto-services`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_name: locationName, value }),
          });
          toast.success("Services uploaded successfully");
    
          
        } catch (error) {
          console.error('Failed to upload services', error);
        }
        await fetchAutoServiceStatus();
      };

    const handleFileUpload = async () => {
        handleAutoServiceSetup(2);
        setIsUploading(true);
    
        const data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsBinaryString(file);
        });
    
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setServices(json.map((row) => ({ name: row['service_name'], status: 'accepted' })));
    
        // After successful file upload, send progress update
        await fetch(`${call}/service-upload-progress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageName: "Service Upload", location_name: locationName, completed: 'true' }),
        });
    
        setIsSaving(true);
        const acceptedServices = services.map(service => service.name);
    
        await fetch(`${call}/store-service-names`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({serviceNames: json.map((row) => ({ name: row['service_name'], status: 'accepted' })).map(service => service.name), location_name: locationName}),
        });
    
        setIsSaving(false);
        setIsUploading(false);
    
        const response = await fetch(`${call}/service-name-matching`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ location_name: locationName }),
        });
    
    
      };

    const handleServiceManualSetup = async () => {
        try {
            await fetch(`${call}/set-auto-services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name: locationName, value: 0 }),
            });
            toast.success("Manual service Setup initiated successfully");
        } catch (error) {
            console.error('Failed to initiate manual service setup:', error);
            toast.error("Failed to initiate Manual service Setup");
        }

        fetchAutoServiceStatus();
    };


    if (permission_code !== '11' && permission_code !== '21') {
        return (
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
            </div>
        );
    }

    const sendEmails = async () => {
        for (const entry of users) {
          // Step 1: Generate a random 8-letter password with capital letters and numbers
          const password = uuidv4().slice(0, 8).replace(/[^A-Z0-9]/gi, (char) => {
            const random = Math.random();
            return random < 0.5 ? char.toUpperCase() : Math.floor(random * 10);
          });
      
          try {
       
            // Step 2: Send the user email and password to Firebase
            const firebaseResponse = await fetch(`${call}/create-user-firebase`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: entry.user_email,
                password,
              }),
            });
      
            if (!firebaseResponse.ok) {
              throw new Error('Failed to create user in Firebase');
            }
      
            // Step 3: Get the password reset link from the backend
            const resetLinkResponse = await fetch(`${call}/generate-reset-link`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: entry.user_email }),
            });
      
            const { resetLink } = await resetLinkResponse.json();
            if (!resetLinkResponse.ok) {
              throw new Error('Failed to generate password reset link');
            }
      
            // Step 4: Send email via Outlook
            const outlookResponse = await fetch(`${call}/send-outlook-email`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: entry.user_email,
                subject: 'Your Account Details',
                body: `Hello ${entry.first_name},\n\nYour account has been created. Your temporary password is: ${password}. If you wish to reset your password please do so by following this link: ${resetLink}.`,
              }),
            });
    
    
      
            if (!outlookResponse.ok) {
              throw new Error('Failed to send email');
            }
      
            toast.success(`Email sent to ${entry.user_email}`);
          } catch (error) {
            console.error(`Error sending email to ${entry.user_email}:`, error);
          }
        }
      };

    const handleGenerateOpeningOrder = async () => {
        if(openingOrderStatus !== 0){
            alert('Opening Order for this location is already generated')
            return
        }
        setOpeningOrderStatus(1);
        try {
            const response = await fetch(`${call}/generate-opening-order`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name: locationName }),
            });
            const data = await response.json();
            if (data.success) {
                toast.success('Opening order generated successfully');
                const response = await fetch(`${call}/set-opening-order-status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ location_name: locationName, value: 1 }),
                });
            } else {
                toast.error('Failed to generate opening order');
            }
        } catch (error) {
            console.error('Error generating opening order:', error);
            toast.error('Error generating opening order');
            setOpeningOrderStatus(0);
        }
        
    };

    const handleOpeningOrderPush = async () => {
        if(openingOrderStatus === 3){
            alert('Opening Order for this location is already pushed')
            return;
        }
        setOpeningOrderStatus(3);
        try {
            const response = await fetch(`${call}/push-opening-order`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name: locationName }),
            });
            const data = await response.json();
            if (data.success) {
                toast.success('Opening order pushed successfully');
                const response = await fetch(`${call}/set-opening-order-status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ location_name: locationName, value: 3 }),
                });
                
            } else {
                toast.error('Failed to push opening order');
                setOpeningOrderStatus(2);
            }
        } catch (error) {
            console.error('Error pushing opening order:', error);
            toast.error('Error pushing opening order');
        }
        const response = await fetch(`${call}/set-opening-order-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name: locationName, value: 3 }),
        });
    };

    const handleOpeningOrderReverse = async () => {
        try {
            const response = await fetch(`${call}/reverse-push-opening-order`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name: locationName }),
            });
            const data = await response.json();
            if (data.success) {
                toast.success('Reversed opening order push');
                setOpeningOrderStatus(2);
                const response = await fetch(`${call}/set-opening-order-status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ location_name: locationName, value: 2 }),
                });
            } else {
                toast.error('Failed to reverse opening order');
            }
        } catch (error) {
            console.error('Error reversing opening order:', error);
            toast.error('Error reversing opening order');
        }
    };
    

    return (

        
        <div className="page-wrapper-container">
        <div  style={{ textAlign: 'left', marginLeft: '50px', marginTop: '15px',}}>
            <div className="dashboard-layout">
                <Sidebar call={call} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row',  }}>
                <h1 style={{ fontSize: '35px', fontWeight: 'bold', color: '#87D3D7' }}>Medvelle Team Dashboard</h1>
                <div className="input-group" style={{ marginTop: '35px', marginLeft: '350px' }}>
                <button 
                    onClick={() => setIsCreateLocationModalOpen(true)} 
                    style={{padding: '10px', backgroundColor: '#87D3D7', cursor: 'pointer', marginRight: '10px', border: 'none', borderRadius: '10px'}}
                >
                    Create New Location
                </button>                    
                    <label>Location: </label>
                    <select value={locationName} onChange={handleLocationChange} required>
                        <option value="" disabled>Select Location</option>
                        {allLocations.map((location) => (
                            <option key={location.location_name} value={location.location_name}>
                                {location.location_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div style={{ width: '1200px', marginLeft: '65px', marginTop: '30px', marginBottom: '30px' }}>
                <span
                    onClick={() => handleTabClick('Progress')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Progress' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginRight: '50px',
                    }}
                >
                    Progress
                </span>
                <span
                    onClick={() => handleTabClick('Vendor')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Vendor' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginRight: '50px',
                    }}
                >
                    Vendor
                </span>
                <span
                    onClick={() => handleTabClick('Services')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Services' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginRight: '50px',
                    }}
                >
                    Services
                </span>
                <span
                    onClick={() => handleTabClick('Inventory')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Inventory' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginRight: '50px',
                    }}
                >
                    Inventory
                </span>
                <span
                    onClick={() => handleTabClick('Go-Live')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Go-Live' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginRight: '50px',
                    }}
                >
                    Go-Live
                </span>
                <span
                    onClick={() => handleTabClick('Opening-Order')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Opening-Order' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}
                >
                    Opening Order
                </span>
            </div>
            {activeTab === 'Progress' && (
        <div style={{
          border: '2px solid #87D3D7',
          display: 'flex',
          flexDirection: 'column',
          width: '75%',
          maxHeight: '610px',
          overflowY: 'auto',
          boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>
          <table
            className="vendor-table"
            style={{
              backgroundColor: '#ffffff',
              width: '100%',
              borderCollapse: 'separate',
              borderSpacing: 0,
              position: 'relative'
            }}>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff' }}>
              <tr style={{ fontSize: '13px', color: '#000000' }}>
                <th style={{ textAlign: 'left' }}>Page Name</th>
                <th style={{ textAlign: 'left' }}>Status</th>
                <th style={{ textAlign: 'left' }}>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(steps).map((step) =>
                step.tasks.map((task) => (
                  <tr key={task.name}>
                    <td>{task.name}</td>
                    <td>
                      <select
                        value={task.completed}
                        onChange={(e) => handleStatusChange(e, task)}
                      >
                        <option value="Incomplete">Incomplete</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </td>
                    <td>
                      <DatePicker
                        selected={new Date(task.dueDate || new Date())}
                        onChange={(date) => handleDateChange(date, task)}
                        dateFormat="yyyy-MM-dd"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

            
            {activeTab === 'Services' && (
                <>
                <h2>Services Upload Configuration</h2>
                {serviceUpload === 1 ? (
                    <div style={{ marginTop: '15px', marginLeft: '1px' }}>
                        <>
                        <div style={{ marginLeft: '1px', marginTop: '100px' }}>
                            <p style={{ width: '500px' }}>
                            Please upload your services sheet below, you may download the sample sheet to follow the column name convention, thank you.
                            </p>
                            <div className="file-upload-section" style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', marginLeft: '30px' }}>
                            <label className="custom-file-upload" style={{ width: '350px', height: '70px', fontSize: '20px' }}>
                                <input
                                type="file"
                                onChange={handleFileChange}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="file-upload"
                                style={{ marginTop: '30px' }}
                                />
                                <p style={{ marginTop: '24px', marginLeft: '80px' }}>Manual File Upload</p>
                            </label>
                            {file && (
                                <button onClick={handleFileUpload} className="upload-button" disabled={isUploading}>
                                Upload Your Sheet {isUploading && <span>Loading...</span>}
                                </button>
                            )}
                            <button onClick={handleReport} className="add-product-button">
                                Download Sample Sheet
                            </button>
                            </div>
                        </div>
                        </>
                    </div>
                ) : serviceUpload === 0 ? (
                    <div style={{ marginTop: '50px', marginLeft: '15px' }}>
                        <p>Client has been notified to manually upload their services sheet.</p>
                    </div>
                ) :
                serviceUpload === 2 ? (
                    <div style={{ marginTop: '50px', marginLeft: '15px' }}>
                        <p>Services Sheet has been uploaded.</p>
                    </div>
                ) :
                
                (
                    <>
                        <div style={{ marginTop: '50px', marginLeft: '15px', flexDirection: 'row', display: 'flex' }}>
                        <button className="step-button" onClick={handleServiceAutoGenerate}>Setup Services Internally</button>

                            <div style={{ marginLeft: '20px', marginTop: '30px', marginRight: '15px' }}>OR</div>
                            <button className="step-button" onClick={handleServiceManualSetup}>Manual Services Setup</button>
                        </div>
                    </>
                )}
                </>
            )}

            {activeTab === 'Go-Live' && (
                <>
                    <h2>Step 1. Initiate the Location Data</h2>
                    <button className="step-button" onClick={handleUsersLive}>Complete Step</button>

                    <h2>Step 2. GO Live - Inventory</h2>
                    <button className="step-button" onClick={handleInventory}>Complete Step</button>

                    <h2>Step 3. GO Live - Products, Services and Mappings</h2>
                    <button className="step-button" onClick={handleServices}>Complete Step</button>

                    <h2>Step 4. GO Live - Send Authentication Emails</h2>
                    <button className="step-button" onClick={sendEmails}>Complete Step</button>

                    {openingOrderStatus === 3 && (
                    <><h1 style={{ color: 'red' }}>Reverse Push Opening Order</h1><button
                            className="step-button"
                            onClick={handleOpeningOrderReverse}
                        >
                            <span style={{ color: 'red' }}>Reverse Push Opening Order </span>
                        </button></>
                    )}

                    <h1 style={{color: 'red'}}>Reverse the Push Data</h1>
                    <button className="step-button" onClick={handleReversePush} ><span style={{color: 'red'}}>Reverse</span></button>
                </>
            )}


            {activeTab === 'Vendor' && (

                    <>

<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', marginLeft: '10px' }}>
            <div style={{ marginRight: '20px' }}>
                <label htmlFor="locationFilter">Location Name:</label>
                <select
                    id="locationFilter"
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    value={selectedLocation}
                    style={{ marginLeft: '10px', padding: '5px' }}
                >
                    <option value="">All Locations</option>
                    {allLocations.map((location) => (
                        <option key={location.location_name} value={location.location_name}>
                            {location.location_name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label htmlFor="supplierFilter">Supplier Name:</label>
                <select
                    id="supplierFilter"
                    onChange={(e) => setSelectedSupplier(e.target.value)}
                    value={selectedSupplier}
                    style={{ marginLeft: '10px', padding: '5px' }}
                >
                    <option value="">All Suppliers</option>
                    {vendors.map((vendor, index) => (
                        <option key={index} value={vendor.supplier_name}>
                            {vendor.supplier_name}
                        </option>
                    ))}
                </select>
            </div>
        </div>

                <div style={{
                    border: '2px solid #87D3D7',
                    display: 'flex',
                    marginTop: '30px',
                    justifyContent: 'left',
                    flexDirection: 'column',
                    width: '90%',
                    maxHeight: '510px',
                    overflowY: 'auto',
                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
                        <table
                            className="vendor-table"
                            style={{
                                backgroundColor: '#ffffff',
                                width: '100%',
                                borderCollapse: 'separate',
                                borderSpacing: 0,
                                position: 'relative'
                            }}>
                            <thead style={{
                                position: 'sticky', top: 0, backgroundColor: '#ffffff'
                            }}>
                                <tr style={{ fontSize: '13px', color: '#000000' }}>
                                    <th style={{ textAlign: 'left', }}>Location Name</th>
                                    <th style={{ textAlign: 'left', }}>Supplier Name</th>
                                    <th style={{ textAlign: 'left', }}>Primary Order Type</th>
                                    <th style={{ textAlign: 'left', }}>Username</th>
                                    <th style={{ textAlign: 'left', }}>Password</th>
                                    <th style={{ textAlign: 'left', }}>Rep Name</th>
                                    <th style={{ textAlign: 'left', }}>Rep Email</th>
                                    <th style={{ textAlign: 'left', }}>Rep Phone</th>
                                    <th style={{ textAlign: 'left', }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredVendors.map((item, index) => (
                                    <tr key={index} style={{ backgroundColor: item.status === 0 ? '#fdd' : '' }}>
                                        <td>{item.location_name}</td>
                                        <td>{item.supplier_name}</td>
                                        <td>{item.ordering_type}</td>
                                        <td>{item.username}</td>
                                        <td>{item.password}</td>
                                        <td>{item.rep_name}</td>
                                        <td>{item.rep_email}</td>
                                        <td>{item.rep_phone}</td>
                                        <td>
                                            <select
                                                value={item.status}
                                                onChange={(e) => handleVendorStatusChange(e, item.supplier_name)}
                                            >
                                                <option value="1">Active</option>
                                                <option value="2">In-Review</option>
                                                <option value="0">Error</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div></>
            )
            
            }

            {activeTab === 'Opening-Order' && (

                <div style={{display: 'flex', flexDirection: 'column', width: '300px'}}>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', width: '500px', marginTop: '10px' }}>
                    <button
                        style={{ backgroundColor: '#87d3d7', borderRadius: '5px', cursor: 'pointer', padding: '15px', marginRight: '10px', border: 'none' }}
                        onClick={handleGenerateOpeningOrder}
                    >
                        1. Generate Opening Order
                    </button>
                    <label style={{ fontSize: '18px', marginRight: '10px' }}>Client Visibility:</label>
                    <input
                        type="checkbox"
                        checked={openingOrderStatus === 2}
                        onChange={handleToggleChange}
                        style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                    />
                </div>
                
                <button
                    style={{ backgroundColor: '#87d3d7', borderRadius: '5px', cursor: 'pointer', padding: '15px', marginTop: '100px', marginLeft: '10px', border: 'none' }}
                    onClick={handleOpeningOrderPush}
                >
                    2. Push Opening Order
                </button>

               
                </div>
            )
            }

            {activeTab === 'Inventory' && (
                <>
                    <h2>Inventory Generation Configuration</h2>
                    {autoInventory === 1 ? (
                        <div style={{ marginTop: '50px', marginLeft: '15px' }}>
                            <p>Client's inventory generation is configured to auto-setup.</p>
                        </div>
                    ) : autoInventory === 0 ? (
                        <div style={{ marginTop: '50px', marginLeft: '15px' }}>
                            <p>Client has been notified to manually upload their product sheet.</p>
                        </div>
                    ) :
                    autoInventory === 2 ? (
                        <div style={{ marginTop: '50px', marginLeft: '15px' }}>
                            <p>Client has uploaded the product sheet.</p>
                        </div>
                    ) :
                    
                    (
                        <>
                            <div style={{ marginTop: '50px', marginLeft: '15px', flexDirection: 'row', display: 'flex' }}>
                            <button className="step-button" onClick={handleAutoSetup}>Auto Setup Inventory</button>

                                <div style={{ marginLeft: '20px', marginTop: '30px', marginRight: '15px' }}>OR</div>
                                <button className="step-button" onClick={handleManualSetup}>Manual Setup Inventory</button>
                            </div>
                        </>
                    )}

                    <h2 style={{marginTop: '40px'}}>Product Flags</h2>

                    <div style={{
                border: '2px solid #87D3D7',
                display: 'flex',
                marginTop: '30px',
                justifyContent: 'left',
                flexDirection: 'column',
                width: '40%',
                maxHeight: '610px',
                overflowY: 'auto',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table
                    className="vendor-table"
                    style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        borderCollapse: 'separate',
                        borderSpacing: 0,
                        position: 'relative'
                    }}>
                    <thead style={{
                        position: 'sticky', top: 0, backgroundColor: '#ffffff'
                    }}>
                        <tr style={{ fontSize: '13px', color: '#000000', textAlign: 'left' }}>
                            <th style={{textAlign: 'left', width: '25%'}}>Product Name</th>

                            <th style={{textAlign: 'left'}}>Flags</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productFlags.map((item, index) => {
                            const flags = [];

                            return (
                                <tr key={item.matched_product_id}>
                                    <td>{item.matched_product_name}</td>

                                    <td>{flags.join(', ')}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        contentLabel="Auto Setup Modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                padding: '20px',
                            },
                        }}
                    >
                        <h2>Select Locations for Auto Setup</h2>
                        <div>
                            {allLiveLocations.map((location) => (
                                <div key={location.location_name}>
                                    <input
                                        type="checkbox"
                                        id={location.location_name}
                                        value={location.location_name}
                                        onChange={(e) => handleLocationCheckboxChange(e, location.location_name)}
                                    />
                                    <label htmlFor={location.location_name}>{location.location_name}</label>
                                </div>
                            ))}
                        </div>
                        <button className="step-button" onClick={handleAutoGenerate}>Auto Generate</button>
                    </Modal>
                </>
            )}
            <Modal
                isOpen={isCreateLocationModalOpen}
                onRequestClose={() => setIsCreateLocationModalOpen(false)}
                contentLabel="Create New Location"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '300px',
                        padding: '20px',
                    },
                }}
            >
                <h2>Enter Location Name</h2>
                <input
                    type="text"
                    value={newLocationName}
                    onChange={(e) => setNewLocationName(e.target.value)}
                    placeholder="Location Name"
                    style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
                />
                <button 
                    onClick={CreateNewLocation} 
                    style={{ padding: '10px', backgroundColor: '#87D3D7', cursor: 'pointer', border: 'none', borderRadius: '5px' }}
                >
                    Create
                </button>
            </Modal>
        </div>
        </div>
    );

};

export default MedvelleTeam;
