import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import ProductTable from '../features/openingorder/ProductTable';
import ProductModal from '../features/openingorder/ProductModal';
import {fetchOpeningOrder, fetchProducts, fetchOpeningOrderStatus } from '../utils/OpeningOrderUtils';

function OpeningOrder({ call }) {
    const location_name = localStorage.getItem('location_name');
    const permission_code = localStorage.getItem('permission_code');
    const [orderData, setorderData] = useState([]);
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [comments, setComments] = useState({});
    const [quantities, setQuantities] = useState({});
    const [modalQuantities, setModalQuantities] = useState({});
    const [totalValue, setTotalValue] = useState(0); 
    const [openingOrderStatus, setOpeningOrderStatus] = useState(0); 
    const [newProductName, setNewProductName] = useState('');

    useEffect(() => {
        fetchOpeningOrder(call, location_name, setorderData, setComments, setQuantities);
        fetchOpeningOrderStatus(call, location_name, setOpeningOrderStatus);
    }, [location_name]);

    useEffect(() => {
        fetchProducts(call, location_name, orderData, setProducts);
    }, [call, location_name, orderData]);

    const handleAddProducts = async () => {

        const productsToAdd = selectedProducts.map(product => ({
            productId: product.id,
            product_name: product.name,
            manufacturer_name: product.manufacturer_name,
            location_name: location_name,
            quantity: Number(modalQuantities[product.id] || 1),  // Get the quantity from modalQuantities, default to 1 if not provided
        }));
    
    
        try {
            const response = await fetch(`${call}/add-products-to-order`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ products: productsToAdd }),
            });
            const data = await response.json();
            if (data.success) {
                toast.success('Products added successfully');
                fetchOpeningOrder(call, location_name, setorderData, setComments, setQuantities);
                fetchProducts(call, location_name, orderData, setProducts);
            }
        } catch (error) {
            console.error('Error adding products:', error);
        }
    };
    
     // Handle adding a new product manually
     const handleNewProductSubmit = async () => {
        try {
            await fetch(`${call}/include-manual-product-to-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productName: newProductName, location_name, }),
            });


        } catch (error) {
            console.error('Error adding new product:', error);
        }

        toast.success('New Product included successfully');
        fetchOpeningOrder(call, location_name, setorderData, setComments, setQuantities);
        setIsProductModalOpen(false); // Close the modal after adding
    };

    const handleRemove = async (product) => {
        try {
            const response = await fetch(`${call}/remove-opening-order`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ location_name, product_id: product.product_id }),
            });
    
            const data = await response.json();
            if (data.success) {
                // toast.success('Product removed successfully');
                await fetchProducts(call, location_name, orderData, setProducts);
            } else {
                toast.error('Failed to remove the product');
            }
        } catch (error) {
            console.error('Error removing product:', error);
            toast.error('Error removing product');
        }
        fetchOpeningOrder(call, location_name, setorderData, setComments, setQuantities);
    };

    
    const handleDownloadAsSheet = async () => {
        
        const updatedOrderData = orderData.map(product => ({
            ProductName: product.product_name,
            ProductDescription: product.product_description,
            ManufacturerName: product.manufacturer_name,
            Category: product.product_cat,
            Usage: product.total_usage,
            Quantity: quantities[product.product_id],
            Price: product.client_price,
            Comments: comments[product.product_id],  
        }));

        const worksheet = XLSX.utils.json_to_sheet(updatedOrderData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Opening Order");
        XLSX.writeFile(workbook, `Opening_Order_${location_name}.xlsx`);
    };    


    return (
        <div className="page-wrapper-container">
        <div className="inventory-page">
            <div className="dashboard-layout">
                <Sidebar call={call} />
            </div>
            <h1>Opening Order</h1>
            
            {orderData?.length === -1 ? (
                <div style={{ textAlign: 'center', marginTop: '50px', fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                    Medvelle Team is preparing your opening order.
                </div>
            ) : (
                <>
                    {openingOrderStatus === 2 || openingOrderStatus === 3 || permission_code === '11' ? (
                    <>
                    <ProductTable 
                        orderData={orderData} 
                        comments={comments} 
                        setComments={setComments} 
                        handleRemove={handleRemove}
                        call={call}
                        quantities = {quantities}
                        setQuantities = {setQuantities}
                        handleDownloadAsSheet = {handleDownloadAsSheet}
                        totalValue = {totalValue}
                        setTotalValue = {setTotalValue}
                    />
                    <div style={{display: 'flex', }}>
                        <button 
                            style={{ backgroundColor: '#87d3d7', borderRadius: '5px', cursor: 'pointer', padding: '10px', marginTop: '1vw', marginLeft: '40%', border: 'none' }} 
                            onClick={() => setIsProductModalOpen(true)}
                        >
                            Add Product
                        </button>

                        <div style={{ marginTop: '32px', fontWeight: 'bold', fontSize: '18px', marginLeft: '27%', }}>
                            Estimated Value:<span style={{color:'green'}}> ${totalValue.toFixed(2)}</span>
                        </div>
                    </div>
                    </>
                    ) : (
                        <div>Please wait while the Medvelle Onboarding Team prepares your Opening Order.</div>
                    )
                }

                </>
            )}

            <ProductModal
                isOpen={isProductModalOpen}
                onClose={() => {
                    setIsProductModalOpen(false);  // Close the modal
                    setModalQuantities({});        // Reset quantities inside the modal only
                    setSelectedProducts([]);       // Reset selected products
                }}
                products={products}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                handleAddProducts={(productsToAdd) => handleAddProducts(productsToAdd, modalQuantities)}
                quantities={modalQuantities}        // Pass modal-specific quantities
                setQuantities={setModalQuantities}  // Manage modal-specific quantities
                handleNewProductSubmit = {handleNewProductSubmit}
                setNewProductName = {setNewProductName}
                newProductName = {newProductName}
            />

        </div>
        </div>
    );
}

export default OpeningOrder;
