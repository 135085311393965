
export const fetchOpeningOrderStatus = async (call, location_name, setOpeningOrderStatus) => {
    try {
        const response = await fetch(`${call}/get-opening-order-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const result = await response.json();
        setOpeningOrderStatus(result.opening_order);
    } catch (error) {
        console.error('Failed to fetch opening order status:', error);
    }
};

export const fetchOpeningOrder = async (call, location_name, setorderData, setComments, setQuantities) => {
    try {
        const response = await fetch(`${call}/get-opening-order`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const data = await response.json();
        setorderData(data.results);
        const initialComments = {};
        data.results.forEach(item => {
            initialComments[item.product_id] = item.comment || '';
        });
        setComments(initialComments)
        const initialQuantities = {};
        data.results.forEach(item => {
            initialQuantities[item.product_id] = item.order_quantity || '';
        });
        setQuantities(initialQuantities);
    } catch (error) {
        console.error('Error fetching order data:', error);
    }
};

export const fetchProducts = async (call, location_name, orderData, setProducts) => {
    try {
        const response = await fetch(`${call}/add-products`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ location_name }),
        });
        const data = await response.json();

        // Create a Set of product IDs from the orderData to filter out already added products
        const inventoryProductIds = new Set(orderData.map(item => (item.product_id)));  // Ensure IDs are strings for comparison
        
        // Filter products that are not already in orderData
        const filteredProducts = data.products.filter(product => !inventoryProductIds.has((product.id)));  // Convert product_id to string for comparison
        
        setProducts(filteredProducts);
    } catch (error) {
        console.error('Failed to fetch products:', error);
    }
};
