// Authentication.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/style/Authentication.css'; 
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import app from './FirebaseConfig'; 
import logo from '../../assets/images/logo.png'; 


function Authentication({call}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const completedOnboarding = false;

  
const handleSignIn = async () => {
  const auth = getAuth(app);
  try {
    await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem('email', email);
    getPermissionCode();
    if(completedOnboarding){
      navigate('/medvelle-team')
    }
    else{
      navigate('/dashboard'); // Navigate to Dashboard upon successful sign-in
    }
    
  } catch (error) {
    console.error("Authentication failed: ", error.message);
    // Handle errors (e.g., show an error message)
  }
};

const getPermissionCode = async () => {
  const response = await fetch(`${call}/send-email-location`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    
    localStorage.setItem('location_name', data.locationName);
    console.log('location_name is:', data.locationName)


    const response1 = await fetch(`${call}/send-permission-code`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data1 = await response1.json();

    localStorage.setItem('permission_code', data1.permission_code);

}


  return (
    <div className="authentication-page">
      <div className="auth-box">
      <img src={logo} alt="Logo" className="auth-logo"/> {/* Logo added here */}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className='submit-button' onClick={handleSignIn}>Sign In</button>
      </div>
    </div>
  );
}

export default Authentication;