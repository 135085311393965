import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ProductTable = ({ orderData, handleRemove, call, setQuantities, quantities, handleDownloadAsSheet, totalValue, setTotalValue }) => {
    const [comments, setComments] = useState(
        orderData.reduce((acc, item) => ({ ...acc, [item.product_id]: item.comment || '' }), {})
    );
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const uniqueCategories = [...new Set(orderData.map(item => item.product_cat))];
    const uniqueManufacturers = [...new Set(orderData.map(item => item.manufacturer_name))];
    const [sortAsc, setSortAsc] = useState(true);
    const [sortClickCount, setSortClickCount] = useState(0);
    const [sortAttribute, setSortAttribute] = useState('');


    const filteredData = orderData.filter(item => {
        return (
            (selectedCategory === '' || item.product_cat === selectedCategory) &&
            (selectedManufacturer === '' || item.manufacturer_name === selectedManufacturer) &&
            (item.product_name &&  item.product_name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    // Adjusted handleSortChange function
    const handleSortChange = (attribute, currentSortAttribute, setSortAttribute, sortAsc, setSortAsc) => {
        if (attribute === currentSortAttribute) {
            setSortClickCount(prevCount => prevCount + 1);

            if (sortClickCount === 1) {
                setSortAsc(!sortAsc);
            }

            if (sortClickCount === 2) {
                setSortAttribute('');
                setSortAsc(true);
                setSortClickCount(0);
            }
        } else {
            setSortAttribute(attribute);
            setSortAsc(true);
            setSortClickCount(1);
        }
    };

    // Updated sorting logic in sortedData
    const sortedData = [...filteredData].sort((a, b) => {
        if (sortAttribute) {
            const valueA = a[sortAttribute];
            const valueB = b[sortAttribute];

            if (typeof valueA === 'number' && typeof valueB === 'number') {
                return sortAsc ? valueA - valueB : valueB - valueA;
            } else if (sortAttribute === 'total_usage' || sortAttribute === 'order_quantity') {
                const numA = parseFloat(valueA) || 0;
                const numB = parseFloat(valueB) || 0;
                return sortAsc ? numA - numB : numB - numA;
            } else {
                const strA = valueA.toString().toLowerCase();
                const strB = valueB.toString().toLowerCase();
                if (strA < strB) return sortAsc ? -1 : 1;
                if (strA > strB) return sortAsc ? 1 : -1;
                return 0;
            }
        }
        return 0;
    });


    const getArrow = (attribute, currentSortAttribute, sortAsc) => {
        if (attribute === currentSortAttribute) {
            return sortAsc ? ' ▲' : ' ▼';
        }
        return '';
    };
        

    // Function to recalculate the total value
    const calculateTotalValue = (filteredOrderData) => {
        const total = filteredOrderData.reduce((acc, item) => {
            const quantity = quantities[item.product_id] || 0;
            const productTotal = (item.client_price || 0) * quantity;
            return acc + productTotal;
        }, 0);
        setTotalValue(total);
    };

    useEffect(() => {
        // Calculate the total value when quantities or filters change
        calculateTotalValue(filteredData);
    }, [quantities, selectedCategory, selectedManufacturer, searchTerm]);

    const handleCommentChange = (productId, comment) => {
        setComments(prevComments => ({
            ...prevComments,
            [productId]: comment,
        }));
    };

    const handleQuantityChange = (productId, quantity) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: quantity,
        }));
    };

    const handleCommentSubmit = async (productId) => {
        try {
            const response = await fetch(`${call}/update-opening-order-product-comment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    product_id: productId, 
                    location_name: localStorage.getItem('location_name'), 
                    comment: comments[productId] 
                }),
            });

            const data = await response.json();
            if (data.success) {
                toast.success('Comment updated successfully');
            }
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    const handleQuantitySubmit = async (productId) => {
        try {
            const response = await fetch(`${call}/update-opening-order-product-quantity`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    product_id: productId, 
                    location_name: localStorage.getItem('location_name'), 
                    quantity: quantities[productId]
                }),
            });

            const data = await response.json();
            if (data.success) {
                toast.success('Quantity updated successfully');
            }
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };



    return (
        <>
            <input
                type="text"
                placeholder="Search Product Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginTop: '10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            />

            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                style={{ marginTop: '10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                <option value="">All Categories</option>
                {uniqueCategories.sort().map(product_cat => (
                    <option key={product_cat} value={product_cat}>
                        {product_cat}
                    </option>
                ))}
            </select>

            <select
                value={selectedManufacturer}
                onChange={(e) => setSelectedManufacturer(e.target.value)}
                style={{ marginTop: '10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                <option value="">All Manufacturers</option>
                {uniqueManufacturers.sort().map(manufacturer => (
                    <option key={manufacturer} value={manufacturer}>
                        {manufacturer}
                    </option>
                ))}
            </select>

            <button 
                style={{ backgroundColor: '#87d3d7', borderRadius: '5px', cursor: 'pointer', padding: '12px', marginTop: '20px', marginLeft: '10px', border: 'none' }} 
                onClick={handleDownloadAsSheet}
            >
                Download as Sheet
            </button>            

            <div style={{ border: '2px solid #87D3D7', marginTop: '30px', width: '96%', maxHeight: '63vh', minHeight: '63vh', overflowY: 'auto', boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)' }}>
                <table className="vendor-table" style={{ backgroundColor: '#ffffff', width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff' }}>
                        <tr style={{ fontSize: '13px', color: '#000000', textAlign: 'left' }}>
                        <th style={{ textAlign: 'center', width: '5%' }}>Remove</th>
                        <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '20%' }}>
                            Product Name {getArrow('product_name', sortAttribute, sortAsc)}
                        </th>
                        <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '20%' }}>
                            Description {getArrow('product_description', sortAttribute, sortAsc)}
                        </th>
                        <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer' }}>
                            Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}
                        </th>
                        <th onClick={() => handleSortChange('product_cat', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '10%' }}>
                            Category {getArrow('product_cat', sortAttribute, sortAsc)}
                        </th>
                        <th onClick={() => handleSortChange('total_usage', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '5%' }}>
                            Usage {getArrow('total_usage', sortAttribute, sortAsc)}
                        </th>
                        <th onClick={() => handleSortChange('order_quantity', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ textAlign: 'left', cursor: 'pointer', width: '15%' }}>
                            Order Quantity {getArrow('order_quantity', sortAttribute, sortAsc)}
                        </th>
                            <th style={{ textAlign: 'center', width: '25%' }}>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'center' }}>
                                    <button
                                        style={{ backgroundColor: '#44a9af', border: 'none', width: '20px', height: '20px', borderRadius: '2px', fontWeight: 'bold', cursor: 'pointer' }}
                                        onClick={() => handleRemove(item)}
                                    >
                                        -
                                    </button>
                                </td>
                                <td>{item.product_name}</td>
                                <td>{item.product_description}</td>
                                <td>{item.manufacturer_name}</td>
                                <td>{item.product_cat}</td>
                                <td>{item.total_usage ? item.total_usage.toFixed(2) : '0.00'}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <input
                                        type="number"
                                        value={quantities[item.product_id] || ''}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value >= 0) { // Ensure the value is non-negative
                                                handleQuantityChange(item.product_id, value);
                                            }
                                        }}
                                        style={{ width: '30%', padding: '5px', borderRadius: '5px', border: '1px solid' }}
                                    />
                                    <button
                                        onClick={() => handleQuantitySubmit(item.product_id)}
                                        style={{ backgroundColor: '#44a9af', color: 'white', padding: '5px 5px', borderRadius: '5px', marginLeft: '5px', border: 'none', cursor: 'pointer' }}
                                    >
                                        Update
                                    </button>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <input
                                        type="text"
                                        value={comments[item.product_id] || ''}
                                        onChange={(e) => handleCommentChange(item.product_id, e.target.value)}
                                        placeholder="Enter comment"
                                        style={{ width: '60%', padding: '5px', borderRadius: '5px', border: '1px solid' }}
                                    />
                                    <button
                                        onClick={() => handleCommentSubmit(item.product_id)}
                                        style={{ backgroundColor: '#44a9af', color: 'white', padding: '5px 10px', borderRadius: '5px', marginLeft: '5px', border: 'none', cursor: 'pointer' }}
                                    >
                                        Enter
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                
            </div>
            
        </>
    );
};

export default ProductTable;
