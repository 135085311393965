import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const ProductModal = ({ isOpen, onClose, products, selectedProducts, setSelectedProducts, handleAddProducts, quantities, setQuantities, newProductName, setNewProductName, handleNewProductSubmit}) => {    
    const [searchTerm, setSearchTerm] = useState("");

    // Function to handle checkbox change for selecting products
    const handleCheckboxChange = (product) => {
        if(!quantities[product.id]){
            alert('Enter quantity before selecting the product for ordering')
            return
        }
        const quantity = quantities[product.id];
        setSelectedProducts(prevSelected => {
            if (prevSelected.some(selectedProduct => selectedProduct.id === product.id)) {
                // If product is already selected, remove it
                return prevSelected.filter(selectedProduct => selectedProduct.id !== product.id);
            } else {
                // Add product with its quantity
                return [...prevSelected, { ...product, quantity }];
            }
        });
    };

    const handleQuantityChange = (productId, quantity) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: quantity,
        }));
        
        // Update quantity in the selectedProducts if already selected
        setSelectedProducts(prevSelected =>
            prevSelected.map(product =>
                product.id === productId ? { ...product, quantity: quantity } : product
            )
        );
    
        const product = products.find(p => p.id === productId);
    
        if (quantity > 0) {
            // Automatically check/select the product if quantity is entered
            if (product && !selectedProducts.some(selectedProduct => selectedProduct.id === productId)) {
                setSelectedProducts(prevSelected => [...prevSelected, { ...product, quantity }]);
            }
        } else {
            // Remove the product from selectedProducts if quantity is set to 0 or cleared
            setSelectedProducts(prevSelected =>
                prevSelected.filter(selectedProduct => selectedProduct.id !== productId)
            );
        }
    };
    
        

    // Filter products based on search term
    const filteredProducts = products.filter(product =>
        product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {onClose(); setSearchTerm('');}}  // Close modal on outside click
            contentLabel="Product Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '800px',
                    height: '700px',
                    backgroundColor: '#87d3d7',
                },
            }}
        >
            <h2>Select Product to Add</h2>

            {/* Search input for filtering products */}
            <input
                type="text"
                placeholder="Search Product"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '30%', padding: '10px', marginBottom: '10px', borderRadius: '5px' }}
            />

            {/* Table for displaying products */}
            <div className="product-table-wrapper">
                <table className="vendor-table" style={{ width: '100%', borderCollapse: 'separate', borderSpacing: 0 }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff' }}>
                        <tr>
                            <th style={{textAlign: 'left'}}>Product Name</th>
                            <th style={{textAlign: 'left'}}>Description</th>
                            <th style={{textAlign: 'left'}}>Manufacturer</th>
                            <th style={{textAlign: 'left'}}>Category</th>
                            <th style={{textAlign: 'center'}}>Qty</th>
                            <th style={{textAlign: 'center'}}>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map((product, index) => (
                            <tr key={index}>
                                <td >{product.name}</td>
                                <td>{product.description}</td>
                                <td>{product.manufacturer_name}</td>
                                <td>{product.product_cat}</td>
                                <td style={{textAlign: 'center'}}>
                                <input
                                    type="number"
                                    value={quantities[product.id] || ''}  // Ensure quantity input is correct
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value >= 0) {  // Ensure the value is non-negative
                                            handleQuantityChange(product.id, value);
                                        }
                                    }}
                                    placeholder="Qty"
                                    min="0"  // HTML5 validation to prevent negative input
                                    style={{ width: '50px', padding: '5px' }}
                                />

                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <input
                                        type="checkbox"
                                        checked={selectedProducts.some(selectedProduct => selectedProduct.id === product.id)}
                                        onChange={() => handleCheckboxChange(product)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* OR enter new product name */}
            <div style={{ display: 'flex', marginLeft: '380px', marginTop: '20px' }}>
                OR
            </div>

            {/* Input for adding a new product */}
            <input
                type="text"
                value={newProductName}
                onChange={(e) => setNewProductName(e.target.value)}
                placeholder="Enter new product name"
                style={{ width: '30%', padding: '10px', marginTop: '20px', marginBottom: '10px', borderRadius: '5px', marginLeft: '190px' }}
            />
            <button
                type="submit"
                onClick={handleNewProductSubmit}
                style={{
                    backgroundColor: '#44a9af',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    marginLeft: '5px',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                Add New Product
            </button>

            {/* Submit button for selected products */}
            <div style={{ display: 'flex', marginLeft: '325px', marginTop: '30px' }}>
            <button
                onClick={() => {
                    const productsToAdd = selectedProducts.map(product => ({
                        productId: product.id,
                        productName: product.name,
                        quantity: quantities[product.id], 
                    }));
                    handleAddProducts(productsToAdd); // Pass selected products with quantities
                    onClose(); // Close modal after adding
                    setSearchTerm('');
                }}
                style={{
                    backgroundColor: '#44a9af',
                    color: 'white',
                    padding: '10px 20px',
                    width: '150px',
                    borderRadius: '5px',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                Done
            </button>
            </div>
        </Modal>
    );
};

export default ProductModal;
