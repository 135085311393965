import React, { useState,useEffect } from 'react';
import '../assets/style/ServiceMatching.css'
import * as XLSX from 'xlsx';
import Sidebar from './Sidebar';
import loadingGif from '../assets/images/test.gif'; // Adjust the path as necessary
import Logo from '../assets/images/logo.png'
import { toast } from 'react-toastify';

function ServiceNameMatching({call}) {

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');

  if (permission_code !== "11") {
    return <NonPrivilegedUserView call = {call}/>;
  }

  return <PrivilegedUserView call = {call}/>;
}

function NonPrivilegedUserView({call}) {
  return (
    <div className="inventory-matching-page">
     
    </div>
  );
}

function PrivilegedUserView({call}) {

  const [unmatchedProducts, setUnmatchedProducts] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedMatches, setSelectedMatches] = useState({}); // New state to track selections
  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');
  const [selectedTab, setSelectedTab] = useState('Unmatched_Products');
  const [matchedProducts, setMatchedProducts] = useState([]); // New state for API fetched matched products
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalServiceName, setModalServiceName] = useState('');
  const [serviceCategory, setServiceCategory] = useState('');
  const [serviceSubCategory, setServiceSubCategory] = useState('');
  const [hasServiceNamesButNoTopMatches, setHasServiceNamesButNoTopMatches] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [serviceSubCategories, setServiceSubCategories] = useState([]);
  
  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const response = await fetch(`${call}/fetch-service-categories`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        setServiceCategories(data.categories || []);
        setServiceSubCategories(data.subcategories || []);
        console.log(data.categories)
      } catch (error) {
        console.error('Error fetching service categories:', error);
      }
    };
  
    fetchServiceCategories();
  }, []);
  
  const fetchProducts = async () => {
    try {
      setIsUploading(true);
  
      const response = await fetch(`${call}/service-name-matching-services`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log(data);

      // Process the data
      const processedData = data.map(item => ({
        givenName: item.service_name,
        matches: JSON.parse(item.top_matches || '[]'),

      }));
  
      setUnmatchedProducts(processedData);

      
      // Check if there are products with service names but no top matches
      setHasServiceNamesButNoTopMatches(processedData.some(item => item.givenName && item.matches.length === 0));
      
     
    const initialSelections = {};
    processedData.forEach(product => {
      product.matches.forEach(match => {
        if (match.score === 100) {
          initialSelections[product.givenName] = product.matches[0].internal_service_id;
        }
      });
    });
    setSelectedMatches(initialSelections);

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsUploading(false);
    }
  };
   
  
  useEffect(() => {
  
    fetchProducts();
  }, []);




  // Add this new function
const checkAndUpdateProgress = async () => {
  const response = await fetch(`${call}/service-name-matching-services`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name }),
  });

  if (response.ok) {
      const data = await response.json();
      const remainingUnmatched = data.filter(item => !item.top_matches || JSON.parse(item.top_matches).length === 0);

      if (remainingUnmatched.length === 0) {
          await fetch(`${call}/update-service-matching-progress`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ taskName: 'Service Matching', location_name: location_name, completed: 'true' }),
          });

          window.location.reload();
        }
  }
};


  // Add a function to export the mappings
  const handleSave = async () => {
    const wb = XLSX.utils.book_new(); // Create a new workbook
    const wsName = "Mappings";

    // Convert selectedMatches object to array suitable for Excel sheet
    const dataForExcel = Object.entries(selectedMatches).map(([givenName, internal_service_id]) => {
      // Find the matched product to get the description
      const product = unmatchedProducts.find(p => p.givenName === givenName);
      const match = product.matches.find(m => m.internal_service_id === internal_service_id);
      return {
        Given_Name: givenName,
        Matched_Name: match.matchedName,
        Matched_internal_service_id: match.internal_service_id,
        Matched_service_cat: match.service_cat,
        Matched_service_sub_cat: match.service_sub_cat
      };
    });
    

    
    const response = await fetch(`${call}/save-services-json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({dataForExcel, location_name}),
    })

    if(response.ok){
    if(trulyUnmatchedProducts.length === 0){
      await fetch(`${call}/update-service-matching-progress`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskName: 'Service Matching', location_name: location_name, completed: 'true' }),
    });

    }
    fetchProducts();
    fetchMatchedProducts();
    toast.success('Saved!');
    checkAndUpdateProgress(); 
    }
  };

  const submitMatchedServices = async () => {
    try {
      const response = await fetch(`${call}/push-service-matches`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit matched services');
      }
  
      const data = await response.json();
      console.log('Services submitted successfully:', data);
      toast.success('Services submitted successfully!');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit services.');
    }
  };
  


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isModalOpen && !event.target.closest('.modal-content')) {
        setIsModalOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);
  

  const handleNoneOfTheAbove = (serviceName) => {
    setModalServiceName(serviceName);
    setIsModalOpen(true);
    handleMatchSelection(serviceName, -1)
  };
  
  const handleModalSubmit = async () => {
    if (!serviceCategory || !serviceSubCategory) {
      toast.warn("Please select both Service Category and Service Sub-Category.");
      return; // Do not proceed if either field is empty
    }

    try {
      const response = await fetch(`${call}/unmatched-service-details`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          serviceName: modalServiceName,
          location_name,
          serviceCategory,
          serviceSubCategory,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      handleSave()
      setIsModalOpen(false);
      setServiceCategory('');
      setServiceSubCategory('');
    }
  };
  

  const handleMatchSelection = (givenName, internal_service_id) => {
    setSelectedMatches(prev => ({
      ...prev,
      [givenName]: internal_service_id // Update selected match
    }));
  };



  const handleTabClick = (tab) => {
    
    setSelectedTab(tab);
};


// Render function to display matched products
const renderMatchedProductsTable = (products) => {
  return (
    <div style={{
      border: '2px solid #87D3D7',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '560px',
    }}>
      
      {/* <h4>{heading} ({products.length} products)
      
      </h4> */}

      <div style={{
        maxHeight: '540px',
        overflowY: 'auto', // Apply scrolling to this div
        
      }}>
        <table style={{
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          position: 'relative',
         
        }}>
          <thead style={{
            position: 'sticky',
            top: 0,
            zIndex: 0,
            backgroundColor: 'white',
          }}>
            <tr style={{ fontSize: '14px' }}>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Service Name</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Matched Name</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Service Category</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Service Sub-Category</th>
              {/* <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Description</th> */}
              
              {/* <th style={{ borderBottom: '2px solid black', padding: '10px',  width: '20%', textAlign: 'center' }}>Name Score</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'center' }}>Name + Description Score</th> */}
            </tr>
          </thead>
          <tbody>
        {products.map((product, index) => (
          <tr key={index}>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.service_name}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.matched_service_name}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.matched_service_cat}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.matched_service_sub_cat}</td>

          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </div>
  );
};


  // Function to sort product groups by the highest product_ within each group
  const sortByHighestscore = (products) => {
    return products.sort((a, b) => {
      const maxscoreA = Math.max(...a.matches.map(match => match.score));
      const maxscoreB = Math.max(...b.matches.map(match => match.score));
      return maxscoreB - maxscoreA; // Descending order
    });
  };

  // Add a function to export the mappings
  const exportMappings = () => {
    const wb = XLSX.utils.book_new(); // Create a new workbook
    const wsName = "Mappings";

    // Convert selectedMatches object to array suitable for Excel sheet
    const dataForExcel = Object.entries(selectedMatches).map(([givenName, internal_service_id]) => {
      // Find the matched product to get the description
      const product = unmatchedProducts.find(p => p.givenName === givenName);
      const match = product.matches.find(m => m.internal_service_id === internal_service_id);
      return {
        Given_Name: givenName,
        Matched_Name: match.matchedName,
        Matched_internal_service_id: match.internal_service_id,
        Matched_service_cat: match.service_cat,
        Matched_service_sub_cat: match.service_sub_cat
      };
    });

    const ws = XLSX.utils.json_to_sheet(dataForExcel); // Convert data to worksheet
    XLSX.utils.book_append_sheet(wb, ws, wsName); // Append worksheet to workbook

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, "mappings.xlsx");
    // Send dataForExcel to a server-side endpoint to save as JSON
  fetch(`${call}/save-services-json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({dataForExcel, location_name}),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
  })
  .catch((error) => {
    console.error('Error:', error);
  });

  

  };

  const fetchMatchedProducts = async () => {
    try {
      

      const response = await fetch(`${call}/fetch-matched-services`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: location_name }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMatchedProducts(data.products); // assuming response in expected format

    } catch (error) {
      console.error('Error:', error);
    } finally {
      
    }
  };

  useEffect(() => {

    fetchMatchedProducts();
  }, []);


  const trulyUnmatchedProducts = sortByHighestscore(unmatchedProducts);

  
  const renderTable = (products, tableBackground, heading) => {
    // Function to render a table for either matched or unmatched products
    return (
      
      <div style={{
        border: '2px solid #87D3D7',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        width: '100%',
        maxHeight: '560px',
        backgroundColor: tableBackground, // Set the background color based on the table type
      }}>
        
        
  
        <div style={{
          maxHeight: '540px',
          overflowY: 'auto', // Apply scrolling to this div
        }}>
          <table style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
            position: 'relative',
          }}>
            <thead style={{
              position: 'sticky',
              top: 0,
              zIndex: 0,
              backgroundColor: 'white',
            }}>
              <tr style={{ fontSize: '13px' }}>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Given Name</th>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Matched Name</th>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Service Cat</th>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Service Sub Cat</th>
                
              </tr>
            </thead>
            <tbody>
              {products.map((product, productIndex) => {
                return product.matches.sort((a, b) => b.score - a.score).map((match, matchIndex) => {
                  const isSelected = selectedMatches[product.givenName] === match.internal_service_id;
  
                  return (
                    <tr key={`${product.givenName}-${match.matchedName}-${matchIndex}`} style={{ fontSize: '13px', backgroundColor: productIndex % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                      {matchIndex === 0 && (
                        <td rowSpan={product.matches.length} style={{ verticalAlign: 'top', padding: '10px', textAlign: 'center' }}>
                          {product.givenName}
                        </td>
                      )}
                      <td style={{ padding: '10px' }}>
                        <button
                          className="upload-button"
                          onClick={match.matchedName === 'None of the Above' ? () => handleNoneOfTheAbove(product.givenName) : () => handleMatchSelection(product.givenName, match.internal_service_id)}
                          style={{
                            backgroundColor: isSelected ? '#183030' : '#87d3d7',
                          }}
                        >
                          ({match.internal_service_id}) {match.matchedName}
                        </button>
                      </td>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{match.service_cat}</td>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{match.service_sub_cat}</td>
                      
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      </div>
      
    );
  };
  
  

  return (
    <div className="page-wrapper-container">
    <div className="inventory-matching-page">
    <div className="dashboard-layout">
    <Sidebar call={call}/>
    </div>

    <h1>Service Matching</h1>
      {/* Only show the upload section if no tables are being displayed */}


  {
    (trulyUnmatchedProducts.length >= 0 ) && (
      
      <>
      
      
          
          <div style={{ marginTop: '5px', marginBottom: '30px' }}>
              <span
                onClick={() => handleTabClick('Unmatched_Products')}
                style={{
                  marginBottom: '15px',
                  color: '#000000',
                  borderBottom: selectedTab === 'Unmatched_Products' ? ' 2px solid black' : ' 2px solid transparent',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer' // Set cursor to pointer
                }}
              >
                Unmatched
              </span>

              <span
                onClick={() => handleTabClick('Matched_Products')}
                style={{
                  marginLeft: '15px',
                  color: '#000000',
                  borderBottom: selectedTab === 'Matched_Products' ? ' 2px solid black' : ' 2px solid transparent',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer' // Set cursor to pointer
                }}
              >
                Completed
              </span>
            </div>
            
            
           

  {
  matchedProducts.length > 0 && selectedTab === 'Matched_Products' &&
  renderMatchedProductsTable(matchedProducts)

}

{
  trulyUnmatchedProducts.length > 0 && selectedTab === 'Unmatched_Products' &&
  renderTable(trulyUnmatchedProducts, '#ffe0e0', 'Unmatched Products')
}
{
  selectedTab === 'Unmatched_Products' &&
(
<div className="button-container">
{trulyUnmatchedProducts.length > 0 ? (
  <div >
    <button onClick={handleSave} className="save-mappings-button">
      Save
    </button>
    <button onClick={exportMappings} className="save-mappings-button" style={{ marginLeft: '10px' }}>
      Export Mappings
    </button>
  </div>
) : (
  <div>
    <h2 style={{ marginLeft: '-400px' }}>Once all the services are matched please verify and click submit below, this is confirm the matches.</h2>
    <button onClick={submitMatchedServices} className="save-mappings-button" style={{ marginLeft: '20px' }}>
      Submit Services
    </button>
  </div>
)}


  </div>
  )}
  </>
            

            )
          }

{isModalOpen && (
  <div className="modal-overlay">
    <div className="modal-content">
      <h2>Enter Details for {modalServiceName}</h2>
      <div>
        <label>Service Category</label>
        <select
          value={serviceCategory}
          onChange={(e) => setServiceCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          <option value="NEVESKIN">NEVESKIN</option>

          {serviceCategories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
            
          ))}
        </select>
      </div>
      <div>
        <label>Service Sub-Category</label>

        <select
          value={serviceSubCategory}
          onChange={(e) => setServiceSubCategory(e.target.value)}
        >
          
          <option value="">Select Sub-Category</option>
          <option value="NEVESKIN">NEVESKIN</option>

          {serviceSubCategories.map((subCategory, index) => (
            <option key={index} value={subCategory}>
              {subCategory}
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleModalSubmit}>Submit</button>
    </div>
  </div>
)}


      </div>
      </div>




    );


  }

export default ServiceNameMatching;

