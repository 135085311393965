import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../assets/style/Dashboard.css';
import InitialSetupImage from '../assets/images/intialsetup.png'; // Make sure this path is correct
import InventoryImage from '../assets/images/inventory.png'; // Make sure this path is correct
import ServicesImage from '../assets/images/services.png'; // Make sure this path is correct
import { getAuth, signOut } from 'firebase/auth';
import starImage from '../assets/images/stars.png'; // Make sure this path is correct
import step1_inactive from '../assets/images/step1-inactive.png'; 
import step2_inactive from '../assets/images/step2-inactive.png'; 
import step3_inactive from '../assets/images/step3-inactive.png'; 
import step1_active from '../assets/images/step1-active.png'; 
import step2_active from '../assets/images/step2-active.png'; 
import step3_active from '../assets/images/step3-active.png'; 
import MedvelleLogo from '../assets/images/logo.png'; 
import helpLogo from  '../assets/images/help-circle.svg'; 
import homeLogo from  '../assets/images/homelogo.svg'; 
import logoutLogo from  '../assets/images/logout.svg'; 


function Dashboard({call}) {
  const navigate = useNavigate();

  
  const permission_code = localStorage.getItem('permission_code');
  const [currentLocation, setCurrentLocation] = useState(localStorage.getItem('location_name') || '');
  let location_name = localStorage.getItem('location_name')
  const email = localStorage.getItem('email')
  const [isExpanding, setIsExpanding] = useState(false); // New state to manage expansion effect
  const [steps, setSteps] = useState({}); 
  const [autoInventory, setAutoInventory] = useState();
  const [locations, setLocations] = useState([]);
  const [openingOrder, setopeningOrder] = useState(1);
  const [autoService, setAutoService] = useState();
  const [hoveredCard, setHoveredCard] = useState(null);

  const [showSubmitPopup, setShowSubmitPopup] = useState(false); // State to control submit popup visibility
  // Function to toggle the submit confirmation popup
  const toggleSubmitPopup = () => {
    setShowSubmitPopup(!showSubmitPopup);
  };

  const initializeSteps = (autoInventory, openingOrder, autoService, permission_code) => {
    return {
      'Initial Setup': {
        title: 'Step 1',
        completed: true, 
        description: 'Complete initial setup tasks to get started.',
        dueDate: '04/01',
        tasks: [
          { name: 'Location Details', path: '/welcome', completed: 'Completed' },
          { name: 'Vendor Information', path: '/vendor-information', completed: 'Completed' }
        ],
        progress: 0,
      },
      'Services': {
        title: 'Step 2',
        completed: false, 
        description: 'Set up your services with these steps.',
        dueDate: '04/15',
        tasks: [
          ...(autoService === 0 || autoService === 2 ? [{ name: `Service Setup`, path: '/services', completed: 'Incomplete' }]: []),
          ...(permission_code === '11' ? [{ name: 'Service Matching', path: '/service-matching', completed: 'Incomplete' }] : []),
        ],
        progress: 0,
      },
      'Products and Mapping': {
        title: 'Step 3',
        completed: false, 
        description: 'Set up and map your products in the platform.',
        dueDate: '04/07',
        tasks: [
          ...(autoInventory === 0 ? [{ name: `Inventory Setup`, path: '/inventory-upload', completed: 'Incomplete' }] : []),
          ...(permission_code === '11' ? [{ name: 'Inventory Matching', path: '/inventory-matching', completed: 'Incomplete' }] : []),
          ...(permission_code === '11' ? [{ name: 'New Products', path: '/new-products', completed: 'Incomplete' }] : []),
          { name: `Your Inventory`, path: '/your-inventory', completed: 'Incomplete' },
          { name: 'Mapping', path: '/service-mapping', completed: 'Incomplete' },
          
          ...(openingOrder >0 ? [{ name: 'Opening Order', path: '/opening-order', completed: 'Incomplete' }] : []),
          
        ],
        progress: 0,
      }

    };
  };

  const getCardStatus = (bucket) => {
    if (bucket === 'Initial Setup') {
      if (steps['Initial Setup']?.tasks.some(task => task.completed === 'Incomplete')) {
        return 'Start here';
      }
      return 'Completed';
    }
    if (bucket === 'Services') {
      if (steps['Initial Setup']?.completed && steps['Services']?.tasks.some(task => task.completed === 'Incomplete')) {
        return 'Start here';
      }
      if (steps['Initial Setup']?.completed && steps['Services']?.completed) {
        return 'Completed';
      }
      return 'Finish Step 1';
    }
    if (bucket === 'Products and Mapping') {
      if (!steps['Initial Setup']?.completed) {
        return 'Finish Step 1&2';
      }
      if (steps['Initial Setup']?.completed && !steps['Services']?.completed) {
        return 'Finish Step 2';
      }
      return 'Start here';
    }
    return '';
  };
  

  const fetchAutoInventoryStatus = async () => {
    try {
      // Fetch auto inventory status
      const response = await fetch(`${call}/get-auto-inventory-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
      const result = await response.json();
      setAutoInventory(result.auto_inventory);
  
      // Fetch openingOrder status
      const openingOrderResponse = await fetch(`${call}/get-opening-order-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
      const openingOrderResult = await openingOrderResponse.json();
      setopeningOrder(openingOrderResult.opening_order);
  
      // Fetch auto service status
      const serviceResponse = await fetch(`${call}/get-auto-service-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: location_name }),
      });
      const serviceResult = await serviceResponse.json();
      setAutoService(serviceResult.service_upload);
  
      // Fetch task statuses
      const taskStatusResponse = await fetch(`${call}/task-statuses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
      const taskData = await taskStatusResponse.json();
  
      // Initialize steps with autoInventory, openingOrder, autoService, and permission_code
      let initializedSteps = initializeSteps(result.auto_inventory, openingOrderResult.opening_order, serviceResult.service_upload, permission_code);
  
      // Integrate task status data into initializedSteps
      const updatedSteps = initializeStepsWithTaskData(taskData, initializedSteps);
      setSteps(updatedSteps);
  
    } catch (error) {
      console.error('Failed to fetch statuses:', error);
    }
  };
  
// Update the initializeStepsWithTaskData function to check task completion, set progress, and determine step's due date
const initializeStepsWithTaskData = (taskData, steps) => {
  // Loop over the taskData and update the relevant task's status and due date
  taskData.forEach(task => {
    Object.values(steps).forEach(step => {
      const matchingTask = step.tasks.find(t => t.name === task.page);
      if (matchingTask) {
        matchingTask.completed = task.status; // Set completion status
        matchingTask.dueDate = formatDateWithSuffix(task.due_date); // Set due date
      }
    });
  });

  // Update each step's completed status, calculate progress, and set the step's due date
  Object.values(steps).forEach(step => {
    const totalTasks = step.tasks.length;
    const completedTasks = step.tasks.filter(task => task.completed === 'Completed').length;

    // Calculate progress as a percentage
    step.progress = (completedTasks / totalTasks) * 100;

    // Set the step's completed status if all tasks are marked as 'Completed'
    step.completed = completedTasks === totalTasks;

    // Find the latest due date among all tasks in this step
    const latestDueDate = step.tasks
      .map(task => new Date(task.dueDate))
      .reduce((latest, current) => (current > latest ? current : latest), new Date(0));

    // Format the latest due date and assign it to the step
    step.dueDate = latestDueDate > new Date(0) ? formatDateWithSuffix(latestDueDate) : step.dueDate;
  });

  return steps;
};

  
  const fetchPermissionCode = async () => {
    const response1 = await fetch(`${call}/send-permission-code`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  
    const data1 = await response1.json();
  
    // Find the matching permission code for the selectedLocation
    const matchingLocation = data1.find(location => location.location_name === localStorage.getItem('location_name'));
  
    if (matchingLocation) {
      localStorage.setItem('permission_code', matchingLocation.permission_code);
    } else {
      console.log('No matching location found for the selected location.');
    }
  };
  

  const handleConfirmSubmit = () => {
    
    setIsExpanding(true); // Start the expansion effect
    setTimeout(() => {
      navigate('/review'); // Delay navigation until after the effect
    }, 800); // Adjust timing based on your CSS transition duration
  };

  // ConfirmationModal component
  function ConfirmationModal({ isOpen, onCancel, onConfirm }) {
    if (!isOpen) return null;

    return (
      <div className={`modal-background ${isExpanding ? 'expanding' : ''}`}>
      {!isExpanding && ( // Only show the content if not expanding

        <div className="modal-content" style={{maxWidth: '600px', }}>
          <h1 style={{ fontWeight: 'bold', fontSize: '24px', textAlign: 'center' }}>Warning</h1>
          <p style={{ textAlign: 'center' }}>
            You are about to submit onboarding for {location_name}, do you want to confirm?
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginLeft: '60px', marginRight: '60px' }}>
            <button
             onClick={onCancel} 
             style={{
                            backgroundColor: '#44a9af',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}>Cancel</button>
            <button 
            onClick={onConfirm}
            style={{
              backgroundColor: '#44a9af',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer'
          }}
            >Confirm</button>
          </div>
        </div>
        )}
      </div>
    );
  }


function formatDateWithSuffix(dateStr) {
  // Create a Date object
  const date = new Date(dateStr);

  // Get formatted date string without the day suffix
  const formattedDateWithoutSuffix = date.toLocaleDateString('en-US', {
    weekday: 'long', // full name of the day of the week
    year: 'numeric', // numeric year
    month: 'long', // full name of the month
    day: 'numeric' // numeric day of the month
  });

  // Split the formatted date to insert the day suffix
  const parts = formattedDateWithoutSuffix.split(' ');
  const dayWithSuffix = parts[1] 

  // Reconstruct the full date string with the day suffix
  return `${parts[0]} ${dayWithSuffix} ${parts[2]} ${parts[3]}`;
}


    const fetchTaskStatuses = async () => {
      try {
        const response = await fetch(`${call}/task-statuses`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ location_name }),
        });
        const taskData = await response.json();
  
        // Initialize steps with data from task-statuses
        const updatedSteps = initializeStepsWithTaskData(taskData);
        setSteps(updatedSteps);
      } catch (error) {
        console.error('Failed to fetch task statuses:', error);
      }
    };
  

  

  useEffect(() => {
    fetchlocations();
    fetchAutoInventoryStatus();
    fetchPermissionCode();
    fetchTaskStatuses();
  }, [location_name]);
  

  
const handleLogout = () => {
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
    navigate('/'); // Redirect to the InitialPage after logout
  }).catch((error) => {
    // An error happened.
    console.error('Logout Error:', error);
  });
};

const fetchlocations = async () => {

  const response = await fetch(`${call}/list-locations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email: email }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setLocations(data);

}

  const [showSupportPopup, setShowSupportPopup] = useState(false);
  // Toggle the support popup visibility
  const toggleSupportPopup = () => {
    setShowSupportPopup(!showSupportPopup);
  };

  // State to track flipped cards
  const [flipped, setFlipped] = useState({
    'Initial Setup': false,
    'Inventory': false,
    'Services': false
  });

  // Function to handle flip
  const handleFlip = (bucket) => {
    setFlipped({ ...flipped, [bucket]: !flipped[bucket] });
  };

  // Function to handle navigation
  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLocationChange = (newLocation) => {
    localStorage.setItem('location_name', newLocation);
    setCurrentLocation(newLocation);
    // fetchTaskStatuses(newLocation);
  };
  

  function areAllTasksCompleted() {
    return Object.values(steps).every(step => 
      step.tasks.every(task => task.completed)
    );
  }
  

  return (
    <div className="dashboard">
      {/* Logo Section */}
      <div className="logo-container">
        <img src={MedvelleLogo} alt="Medvelle Logo" className="logo" />
      </div>

      {/* Location Dropdown Section */}
      <div className="location-dropdown-container">
        
        <img src={homeLogo}></img>
        <select
          value={currentLocation}
          onChange={(e) => handleLocationChange(e.target.value)}
          className="location-dropdown"
        >
          <option value="">Select Location</option>
          {locations.map(({ location_name }) => (
            <option key={location_name} value={location_name}>
              {location_name}
            </option>
          ))}
        </select>
      </div>

      <button className="support-button"><img src={helpLogo}></img> Support</button>

      
      <button onClick={handleLogout} className="logout-button"><img src={logoutLogo} style={{}}></img> Log out</button>
      
      <div className="card-wrapper-container">
      <h1 className="welcome-title">Welcome to Medvelle Onboarding!</h1>
      <p className="welcome-ai">
        <img src={starImage} alt="Star Icon" className="star-icon" />
        Powered by <span style={{color: '#4f9598'}}>Medvelle AI</span>
      </p>
      <p className="welcome-message">The Medvelle Onboarding Platform is a streamlined solution designed to simplify the setup process for new users, vendors, and locations. It guides users through essential steps, including initial configuration, service setup, inventory management, and product mapping. </p>

      <div className="widgets">
        {Object.entries(steps).map(([bucket, { title, tasks, dueDate, progress }], index) => (
          
          
          <div key={index} className="card-wrapper">
            
 

          <div
              className="card"
              onMouseEnter={() => setHoveredCard(title)}
              onMouseLeave={() => setHoveredCard(null)}
              onClick={() => handleNavigate(tasks[0]?.path)}
            >              
              {/* Image Section */}
              <div
                className="image-container"
              >
                {title === 'Step 1' && (
                  <img
                    src={hoveredCard === 'Step 1' ? step1_active : step1_inactive}
                    alt="Step 1"
                    className="card-image"
                  />
                )}
                {title === 'Step 2' && (
                  <img
                    src={hoveredCard === 'Step 2' ? step2_active : step2_inactive}
                    alt="Step 2"
                    className="card-image"
                  />
                )}
                {title === 'Step 3' && (
                  <img
                    src={hoveredCard === 'Step 3' ? step3_active : step3_inactive}
                    alt="Step 3"
                    className="card-image"
                  />
                )}
              </div>
              {/* Title Section */}
              <div className="card-title">{bucket}</div>
              <div className="status-box">
                {getCardStatus(bucket)}
              </div>

              {/* Steps Section */}
              <div className="steps-container">
                {tasks.map((task, taskIndex) => (
                  <button
                    key={taskIndex}
                    className="step-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNavigate(task.path);
                    }}
                  >
                    {task.name}
                  </button>
                ))}
              </div>

              {/* Navigation and Due Date */}
              <div className="navigate-container"             
              onClick={() => handleNavigate(tasks[0]?.path)}
              >
                <span className="due-date">Due: {dueDate}</span>
                <div
                  className="navigate-arrow"
                  onClick={() => handleNavigate(tasks[0]?.path)}
                >
                  ➔
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>


      </div>
      {/* <div className="progress-tracker" >
        {Object.entries(steps).map(([bucket, { dueDate, completed }], index, array) => (
          <div key={index} className={`progress-step ${index === 0 ? 'first' : index === array.length - 1 ? 'last' : ''}`}>
            <div className={`progress-circle ${completed ? 'completed' : 'not-completed'}`}>
              {completed ? '✔' : '!'}
            </div>
            <div className="progress-circle2"></div>
            <div className="due-date">Due: {dueDate}</div>
          </div>
        ))}
      </div>
      {!isExpanding && (
      <button className={`review-button ${areAllTasksCompleted() ? '' : 'disabled'}`}
        disabled={!areAllTasksCompleted()} 
        onClick={toggleSubmitPopup}>
          
        Submit Onboarding
      </button>
      )}
      <ConfirmationModal
        isOpen={showSubmitPopup}
        onCancel={toggleSubmitPopup}
        onConfirm={handleConfirmSubmit}
      /> */}
      
    </div>
  );
}

export default Dashboard;
