import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../assets/style/Sidebar.css';
import Logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';


const Sidebar = ({ call }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [autoInventory, setAutoInventory] = useState();
  const [steps, setSteps] = useState({});
  const [serviceUpload, setServiceUpload] = useState(0);
  const [openingOrder, setopeningOrder] = useState(1);
  const [autoService, setAutoService] = useState();

  const fetchAutoInventoryStatus = async () => {
    try {
      const response = await fetch(`${call}/get-auto-inventory-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
      const result = await response.json();
      setAutoInventory(result.auto_inventory);
  
      // Fetch openingOrder status here
      const openingOrderResponse = await fetch(`${call}/get-opening-order-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
      const openingOrderResult = await openingOrderResponse.json();
      setopeningOrder(openingOrderResult.opening_order);

      const serviceResponse = await fetch(`${call}/get-auto-service-status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: location_name }),
        });
        const serviceResult = await serviceResponse.json();
        console.log('serviceResult.service_upload', serviceResult.service_upload)
  
      setSteps(initializeSteps(result.auto_inventory, openingOrderResult.opening_order, serviceResult.service_upload ,permission_code));
  
    } catch (error) {
      console.error('Failed to fetch statuses:', error);
    }
  };
  

  useEffect(() => {
    fetch(`${call}/activate-mapping`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data.data === 'boolean') {
          setIsActive(data.data);
        } else {
          console.error('Fetched data is not in the expected format:', data.data);
        }
      })
      .catch((error) => console.error('Error:', error));

    fetchAutoInventoryStatus();
  }, []);

  const initializeSteps = (autoInventory, openingOrder, autoService, permission_code) => {
    return {
      'Initial Setup': {
        title: 'Step 1',
        completed: true,
        description: 'Complete initial setup tasks to get started.',
        dueDate: '04/01',
        tasks: [
          { name: 'Location Details', path: '/welcome', completed: false },
          { name: 'Vendor Information', path: '/vendor-information', completed: false },
        ],
        progress: 0,
      },
      'Services': {
        title: 'Step 2',
        completed: false,
        description: 'Set up your services with these steps.',
        dueDate: '04/15',
        tasks: [
          ...(autoService === 0 || autoService === 2 ? [{ name: `Service Setup`, path: '/services', completed: 'Incomplete' }]: []),
          ...(permission_code === '11' ? [{ name: 'Service Matching', path: '/service-matching', completed: false }] : []),
        ],
        progress: 0,
      },
      'Products and Mapping': {
        title: 'Step 3',
        completed: false,
        description: 'Set up and map your products in the platform.',
        dueDate: '04/07',
        tasks: [
          ...(autoInventory === 0 ? [{ name: `Inventory Setup`, path: '/inventory-upload', completed: false }] : []),
          ...(permission_code === '11' ? [{ name: 'Inventory Matching', path: '/inventory-matching', completed: false }] : []),
          ...(permission_code === '11' ? [{ name: 'New Products', path: '/new-products', completed: false }] : []),
          { name: `Your Inventory`, path: '/your-inventory', completed: false },
          { name: 'Mapping', path: '/service-mapping', completed: false },
          ...(openingOrder >0 ? [{ name: 'Opening Order', path: '/opening-order', completed: 'Incomplete' }] : []),

        ],
        progress: 0,
      }
    };
  };

  const flattenTasks = () => {
    return Object.values(steps).flatMap(step => step.tasks);
  };

  const findStepIndex = (currentPath) => {
    const tasks = flattenTasks();
    return tasks.findIndex(task => task.path === currentPath);
  };

  const handleNavigation = (direction) => {
    const tasks = flattenTasks();
    const currentIndex = findStepIndex(location.pathname);
    if (currentIndex !== -1) {
      const newIndex = currentIndex + direction;
      if (newIndex >= 0 && newIndex < tasks.length) {
        navigate(tasks[newIndex].path);
      }
    }
  };

  const isFirstTask = () => findStepIndex(location.pathname) === 0;
  const isLastTask = () => (findStepIndex(location.pathname) === flattenTasks().length - 1) || (location.pathname === '/medvelle-team') || (location.pathname === '/service-mapping');

  const handleGenerateMappings = async () => {
    try {
      navigate('/service-mapping', { state: { mappingStarted: true } });

      const response = await fetch(`${call}/push-service-matches`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });

      const data = await response.json();
      toast.success('Services Added Successfully!');
      setShowModal(false);

      await fetch(`${call}/service-mapping-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location_name }),
      });
    } catch (error) {
      console.error('Error during fetch operation:', error);
    }
  };

  const isServiceMatchingPage = location.pathname === '/service-matching';

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <NavLink to="/dashboard" className="logo-link">
          <img src={Logo} alt="Logo" />
        </NavLink>
      </div>

      <div style={{ marginTop: '50px' }}>
        {Object.entries(steps).map(([bucket, stepDetails]) => (
          <div key={bucket}>
            {stepDetails.tasks.map((task) => (
              <NavLink
                to={task.path}
                className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
                key={task.name}
              >
                {task.name}
              </NavLink>
            ))}
          </div>
        ))}
      </div>

      <NavLink to="/dashboard" className="dashboard-back-button">
        <FontAwesomeIcon icon={faHome} /> {isActive} {location_name}
      </NavLink>

      



      {!isLastTask() && (
  <div className="next-step-container">
    <button
      className="next-step-button"
      onClick={() => handleNavigation(1)}
    >
      Next Step
    </button>
  </div>
)}
  

        
  {!isLastTask() && !isFirstTask() && (
  <div className="previous-step-container">
    <button
      className="previous-step-button"
      onClick={() => handleNavigation(-1)}
    >
      Previous Step
    </button>
  </div>
)}


      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{ content: { height: 'auto' } }}
      >
        <h2 style={{ textAlign: 'center' }}>Attention</h2>
        <p style={{ textAlign: 'center' }}>
          Do you want to confirm the services?
        </p>
        <button
          onClick={handleGenerateMappings}
          type="button"
          style={{
            marginLeft: '220px',
            border: '3px solid #F49C7D',
            color: 'white',
            backgroundColor: '#F49C7D',
            padding: '5px',
            marginTop: '20px',
            cursor: 'pointer',
          }}
        >
          Confirm
        </button>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default Sidebar;
