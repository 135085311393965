import React, { useState, useEffect } from 'react';
import '../assets/style/Vendor.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './Sidebar';

const VendorInformation = ({ call }) => {
  const emptyRow = { supplier_name: '', isOtherSelected: false, ordering_type: '', username: '', password: '', rep_name: '', rep_email: '', rep_phone: '', comment: '' };
  const defaultRows = Array(1).fill(emptyRow);

  const [vendors, setVendors] = useState(defaultRows);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedOrderingTypes, setSelectedOrderingTypes] = useState([]);
  const [originalVendors, setOriginalVendors] = useState([]);

  const location_name = localStorage.getItem('location_name');

  useEffect(() => {
    const fetchVendorsData = async () => {
      try {
        const vendorsResponse = await fetch(`${call}/vendors`);
        const vendorsData = await vendorsResponse.json();

        const uniqueSuppliers = Array.from(new Set(vendorsData.map(vendor => vendor.supplier_name)))
          .map(supplier_name => vendorsData.find(vendor => vendor.supplier_name === supplier_name))
          .sort((a, b) => a.supplier_name.localeCompare(b.supplier_name));

        setSuppliers(uniqueSuppliers);

        const allVendorsResponse = await fetch(`${call}/all-vendors-data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ location_name }),
        });
        
        const allVendorsData = await allVendorsResponse.json();
        const filteredVendorsData = allVendorsData.filter(vendor => vendor.location_name === location_name);

        const initialVendors = filteredVendorsData.length ? filteredVendorsData : defaultRows;
        setVendors(initialVendors);
        setOriginalVendors(initialVendors);
        setSelectedSuppliers(initialVendors.map(vendor => vendor.supplier_name));
        setSelectedOrderingTypes(initialVendors.map(vendor => vendor.ordering_type));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchVendorsData();
  }, []);

  const handleAddNewRow = () => {
    if (suppliers.length > selectedSuppliers.length) {
      setVendors([...vendors, { ...emptyRow }]);
      setOriginalVendors([...vendors, { ...emptyRow }]);
    }
  };

  const handleChange = (index, key, value) => {
    const newVendors = vendors.map((vendor, i) => (
      i === index ? { ...vendor, [key]: value } : vendor
    ));
  
    // Handle special case for supplier_name
    if (key === 'supplier_name' && value === 'Other') {
      newVendors[index] = { ...newVendors[index], supplier_name: '', isOtherSelected: true };
    } else if (key === 'supplier_name' && newVendors[index].isOtherSelected) {
      newVendors[index] = { ...newVendors[index], supplier_name: value };
    } else if (key === 'supplier_name') {
      newVendors[index] = { ...newVendors[index], isOtherSelected: false };
    }
  
    setVendors(newVendors);
  
    // Update selectedSuppliers if the supplier_name was changed
    if (key === 'supplier_name' && value !== 'Other') {
      const newSelectedSuppliers = [...selectedSuppliers];
      newSelectedSuppliers[index] = value;
      setSelectedSuppliers(newSelectedSuppliers.filter(Boolean));
    }
  
    // Update selectedOrderingTypes if the ordering_type was changed
    if (key === 'ordering_type') {
      const newSelectedOrderingTypes = [...selectedOrderingTypes];
      newSelectedOrderingTypes[index] = value;
      setSelectedOrderingTypes(newSelectedOrderingTypes.filter(Boolean));
    }
  };
  

  const removeUserEntry = async (indexToRemove) => {
    const vendorToRemove = vendors[indexToRemove];

    try {
      const response = await fetch(`${call}/vendors/delete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          supplier_name: vendorToRemove.supplier_name,
          location_name: location_name,
        }),
      });

      
      // toast.success("Vendor deleted successfully");

      const updatedVendors = vendors.filter((_, index) => index !== indexToRemove);
      setVendors(updatedVendors);
      setOriginalVendors(updatedVendors);
    } catch (error) {
      console.error('Error:', error);
      // toast.error("Failed to delete vendor");
    }
  };

  const handleSubmit = async (index) => {
    const vendor = vendors[index];
    const originalVendor = originalVendors[index];
  
    console.log('vendor', vendor);
    console.log('originalVendor', originalVendor);

  
    // Handle supplier name change logic
    if ((originalVendor.supplier_name !=='') && vendor.supplier_name !== originalVendor.supplier_name) {
      try {
        const response = await fetch(`${call}/change-supplier-name`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            old_supplier_name: originalVendor.supplier_name,
            new_supplier_name: vendor.supplier_name,
            location_name: location_name,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to change supplier name');
        }
  
        // Update original vendor
        const updatedOriginalVendors = [...originalVendors];
        updatedOriginalVendors[index] = { ...originalVendor, supplier_name: vendor.supplier_name };
        setOriginalVendors(updatedOriginalVendors);
  
        toast.success("Supplier name changed successfully");
      } catch (error) {
        console.error('Error:', error);
        return;
      }
    } else {
      // If no name change, update vendor information
      try {
        const response = await fetch(`${call}/vendors/update`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...vendor, location_name }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update vendor information');
        }
  
  
        // Sync original vendors with latest data
        const updatedOriginalVendors = [...originalVendors];
        updatedOriginalVendors[index] = vendor;
        setOriginalVendors(updatedOriginalVendors);
      } catch (error) {
        console.error('Error:', error);
        toast.error(`Failed to update vendor ${vendor.supplier_name}`);
      }
    }
  };

  
  const handleSaveAll = async () => {
    const invalidVendors = [];
    
    // If there are any invalid vendors, show error messages
    if (invalidVendors.length > 0) {
      invalidVendors.forEach(message => {
        toast.error(message);
      });
      return; // Stop execution if there are validation errors
    }
  
    // Sequentially save each vendor row by row
    for (let i = 0; i < vendors.length; i++) {
      await handleSubmit(i);  // Wait for each submit to complete before moving to the next
    }
  
    toast.success("All vendors saved successfully");
  };
  
  

  return (
    <div className="page-wrapper-container">
    <div className="vendor-information">
      <div className="dashboard-layout">
        <Sidebar call={call} />
      </div>
      <h1>Vendor Information</h1>
      <div style={{
        border: '2px solid #87D3D7',
        display: 'flex',
        marginTop: '30px',
        justifyContent: 'left',
        flexDirection: 'column',
        width: '100%',
        maxHeight: '610px',
        overflowY: 'auto',
        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
      }}>
        <table
          className="vendor-table"
          style={{
            backgroundColor: '#ffffff',
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
            position: 'relative'
          }}>
          <thead style={{
            position: 'sticky', top: 0, backgroundColor: '#ffffff'
          }}>
            <tr style={{ fontSize: '13px', color: '#000000' }}>
              <th></th>
              <th>Supplier Name</th>
              <th>Primary Order Type</th>
              <th>Username</th>
              <th>Password</th>
              <th>Rep Name</th>
              <th>Rep Email</th>
              <th>Rep Phone</th>
              <th>Comment</th>
              {/* <th>Submit</th> */}
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor, index) => (
              <tr key={index}>
                <td>
                  <button className="add-row-button" onClick={() => removeUserEntry(index)}>-</button>
                </td>
                <td>
                  {!vendor.isOtherSelected ? (
                    <select
                      value={vendor.supplier_name}
                      onChange={e => handleChange(index, 'supplier_name', e.target.value)}
                    >
                      <option value="">Select Supplier</option>
                      {suppliers.filter(supplier =>
                        !selectedSuppliers.includes(supplier.supplier_name) ||
                        supplier.supplier_name === vendor.supplier_name
                      ).map((supplier, supplierIndex) => (
                        <option key={supplierIndex} value={supplier.supplier_name}>
                          {supplier.supplier_name}
                        </option>
                      ))}
                      <option value="Other">Other</option>
                    </select>
                  ) : (
                    <input
                      className="small-input"
                      type="text"
                      value={vendor.supplier_name}
                      onChange={e => handleChange(index, 'supplier_name', e.target.value)}
                      placeholder="Enter Supplier Name"
                    />
                  )}
                </td>
                <td>
                  <select
                    value={vendor.ordering_type}
                    onChange={e => handleChange(index, 'ordering_type', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Sales Representative">Sales Representative</option>
                    <option value="Platform">Platform</option>
                  </select>
                </td>
                <td>
                  <input
                    className="small-input"
                    type="text"
                    value={vendor.username}
                    onChange={e => handleChange(index, 'username', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="small-input"
                    type="text"
                    value={vendor.password}
                    onChange={e => handleChange(index, 'password', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="small-input"
                    type="text"
                    value={vendor.rep_name}
                    onChange={e => handleChange(index, 'rep_name', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="small-input"
                    type="text"
                    value={vendor.rep_email}
                    onChange={e => handleChange(index, 'rep_email', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="small-input"
                    type="text"
                    value={vendor.rep_phone}
                    onChange={e => handleChange(index, 'rep_phone', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="small-input"
                    type="text"
                    value={vendor.comment}
                    onChange={e => handleChange(index, 'comment', e.target.value)}
                  />
                </td>
                {/* <td>
                  <button className="submit-row-button" onClick={() => handleSubmit(index)}>Submit</button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <button className="add-row-button"  style = {{marginLeft: '14.8px'}} onClick={handleAddNewRow}>+</button>
      </div>

      <button className="add-row-button" onClick={handleSaveAll} style = {{marginTop: '15px', width: '210px'}}>Save Filled Information</button>

      {/* <ToastContainer /> */}
    </div>
    </div>

  );
};

export default VendorInformation;
