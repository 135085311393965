import React, { useState, useEffect } from 'react';
import '../assets/style/InventoryMatching.css'
import * as XLSX from 'xlsx';
import Sidebar from './Sidebar';
import loadingGif from '../assets/images/test.gif'; // Adjust the path as necessary
import Logo from '../assets/images/logo.png'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';


function InventoryMatching({call}) {
     

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');



  if (permission_code !== "11") {
    return <NonPrivilegedUserView call = {call}/>;
  }

  return <PrivilegedUserView call = {call}/>;
}

function NonPrivilegedUserView({call}) {
  return (
    <div className="inventory-matching-page">
      <Sidebar call={call}/>
      <div className="processing-message">
        <img src={Logo} alt="Loading..." className="loading-gif" />
        Medvelle team is processing your data, thank you for your patience.
      </div>
    </div>
  );
}

function PrivilegedUserView({call}) {

  const location_name = localStorage.getItem('location_name');
  const permission_code = localStorage.getItem('permission_code');
  const [file, setFile] = useState(null);
  const [unmatchedProducts, setUnmatchedProducts] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedMatches, setSelectedMatches] = useState({}); // New state to track selections
  const [selectedCategory, setSelectedCategory] = useState('');
  const [matchedProducts, setMatchedProducts] = useState([]); // New state for API fetched matched products

  const [selectedTab, setSelectedTab] = useState('Unmatched_Products');

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProductForReMap, setSelectedProductForReMap] = useState(null);

  const email = localStorage.getItem('email');

  function ProductModal({ isOpen, onClose, onSelectProduct, currentProductId }) {
    const [newProducts, setNewProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (!isOpen) return;

        const fetchNewProducts = async () => {
            try {
                const response = await fetch(`${call}/fetch-re-map-products`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ location_name: localStorage.getItem('location_name') }),
                });
                const data = await response.json();
                setNewProducts(data.products);
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        };
        fetchNewProducts();
    }, [isOpen]);  // Only refetch when modal opens

    const handleSelectProduct = async (selectedProductId) => {
      // Use the selectedProductId and currentProductId for re-mapping
      console.log('from new product',currentProductId, selectedProductId)
      try {
        
          const response = await fetch(`${call}/re-map-products`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  oldProductId: currentProductId,
                  newProductId: selectedProductId,
                  location_name: location_name
              })
          });
          
          if (response.ok) {
              toast.success('Product mapped successfully');
              
          } else {
              
          }
      } catch (error) {
          console.error('Error re-mapping product:', error);
          toast.error('Error re-mapping product');
      }
      fetchMatchedProducts();
      onClose();  // Close modal after API call
  };

    const filteredProducts = newProducts.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{maxWidth: '800px',}}>
                <span className="close" onClick={onClose}>Close</span>
                <h2>Re-Map Product: {selectedProductForReMap.matched_product_name}</h2>
                <input
                  type="text"
                  placeholder="Search Product"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '30%', padding: '10px', marginBottom: '10px', border: '2px #87d3d7', fontStyle: 'italic', borderRadius: '5px' }}
              />
                <div className='product-table-wrapper'>
                    <table className="add-product-table">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Description</th>
                                <th>Manufacturer</th>
                                <th>Category</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map((product, index) => (
                                <tr key={index}>
                                    <td>{product.name}</td>
                                    <td>{product.description}</td>
                                    <td>{product.manufacturer_name}</td>
                                    <td>{product.product_cat}</td>
                                    <td>
                                        <button className= 'map-button' onClick={() => handleSelectProduct(product.id)}>
                                            Map
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <button className='done-button' onClick={onClose}>Done</button>
            </div>
        </div>
    );
}


const handleReMap = (product) => {
  setSelectedProductForReMap(product);
  setIsProductModalOpen(true);
};

const fetchProducts = async () => {
  try {
    setIsUploading(true);

    const response = await fetch(`${call}/product-name-matching-products`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ location_name }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data);

    // Process the data
    const processedData = data.map(item => ({
      givenName: item.product_name,
      matches: JSON.parse(item.top_matches || '[]'),
    }));

    setUnmatchedProducts(processedData);

    // Automatically select matches with a score of 100
    const initialMatches = {};
    processedData.forEach(product => {
      product.matches.forEach(match => {
        if (match.combined >= 98) {
          initialMatches[product.givenName] = match.product_id;
        }
      });
    });
    setSelectedMatches(initialMatches);
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsUploading(false);
  }
};
 

useEffect(() => {

  fetchProducts();
}, []);

// Render function to display matched products
const renderMatchedProductsTable = (products) => {
  return (
    <div style={{
      border: '2px solid #87D3D7',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '560px',
    }}>
      
      {/* <h4>{heading} ({products.length} products)
      
      </h4> */}

      <div style={{
        maxHeight: '540px',
        overflowY: 'auto', // Apply scrolling to this div
        
      }}>
        <table style={{
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          position: 'relative',
         
        }}>
          <thead style={{
            position: 'sticky',
            top: 0,
            zIndex: 0,
            backgroundColor: 'white',
          }}>
            <tr style={{ fontSize: '14px' }}>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Product Name</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Matched Name</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Description</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Manufacturer</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Category</th>
              {/* <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Description</th> */}
              <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Re-Map</th>
              
              {/* <th style={{ borderBottom: '2px solid black', padding: '10px',  width: '20%', textAlign: 'center' }}>Name Score</th>
              <th style={{ borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'center' }}>Name + Description Score</th> */}
            </tr>
          </thead>
          <tbody>
        {products.map((product, index) => (
          <tr key={index}>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.product_name}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.matched_product_name}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.product_description}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.manufacturer_name}</td>
            <td style={{ padding: '10px', textAlign: 'left' }}>{product.product_cat}</td>
            <td style={{ padding: '10px', textAlign: 'center' }}><button className='remap-button' onClick={() => handleReMap(product)}>Re-Map</button></td>

          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </div>
  );
};


  const handleTabClick = (tab) => {
    
    fetchProducts();  
    fetchMatchedProducts();
    setSelectedTab(tab);
      
  };
  
  const fetchMatchedProducts = async () => {
    try {
      

      const response = await fetch(`${call}/fetch-matched-products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: location_name }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMatchedProducts(data.products); // assuming response in expected format

    } catch (error) {
      console.error('Error:', error);
    } finally {
      
    }
  };
  
  useEffect(() => {

    fetchMatchedProducts();
  }, []); // Dependency array ensures this effect runs only once


  // Function to sort product groups by the highest product_ within each group
  const sortByHighestproduct_score = (products) => {
    return products.sort((a, b) => {
      const maxproduct_scoreA = Math.max(...a.matches.map(match => match.product_score));
      const maxproduct_scoreB = Math.max(...b.matches.map(match => match.product_score));
      return maxproduct_scoreB - maxproduct_scoreA; // Descending order
    });
  };

  // Add a function to export the mappings
  const exportMappings = () => {
    const wb = XLSX.utils.book_new(); // Create a new workbook
    const wsName = "Mappings";

    // Convert selectedMatches object to array suitable for Excel sheet
    const dataForExcel = Object.entries(selectedMatches).map(([givenName, product_id]) => {
      // Find the matched product to get the description
      const product = unmatchedProducts.find(p => p.givenName === givenName);
      const match = product.matches.find(m => m.product_id === product_id);
      return {
        Given_Name: givenName,
        Matched_Name: match.matchedName,
        Matched_product_id: match.product_id,
        Matched_Description: match.matchedDescription,
        Product_category: match.category,
        Manufacturer: match.manufacturer
      };
    });


    const ws = XLSX.utils.json_to_sheet(dataForExcel); // Convert data to worksheet
    XLSX.utils.book_append_sheet(wb, ws, wsName); // Append worksheet to workbook

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, "mappings.xlsx");
    // Send dataForExcel to a server-side endpoint to save as JSON
  fetch(`${call}/save-product-json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({dataForExcel, location_name}),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
   
  })
  .catch((error) => {
    console.error('Error:', error);
  });
  fetchMatchedProducts();
  };

  // Add a function to export the mappings
  const handleSave = async () => {
    const wb = XLSX.utils.book_new(); // Create a new workbook
    const wsName = "Mappings";

    // Convert selectedMatches object to array suitable for Excel sheet
    const dataForExcel = Object.entries(selectedMatches).map(([givenName, product_id]) => {
      // Find the matched product to get the description
      const product = unmatchedProducts.find(p => p.givenName === givenName);
      const match = product.matches.find(m => m.product_id === product_id);
      return {
        Given_Name: givenName,
        Matched_Name: match.matchedName,
        Matched_product_id: match.product_id,
        Matched_Description: match.matchedDescription,
        Product_category: match.category,
        Manufacturer: match.manufacturer
      };
    });


    
  const response = await fetch(`${call}/save-product-json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({dataForExcel, location_name}),
  })

  if(response.ok){

    
  fetchProducts();
  fetchMatchedProducts();
  toast.success('Saved!');
 

  await fetch(`${call}/update-inventory-matching-progress`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ taskName: 'Inventory Matching', location_name: location_name, completed: 'true' }),
});

}
  };


  const handleMatchSelection = (givenName, matchedName) => {
    setSelectedMatches(prev => ({
      ...prev,
      [givenName]: matchedName // Store the selected match for the given product
    }));
  };


  // // Splitting products into matched and unmatched based on product_score criteria and sorting them
  // const matchedProducts = sortByHighestproduct_score(unmatchedProducts.filter(product =>
  //   product.matches.some(match => match.product_score === 100)
  // ));

  const trulyUnmatchedProducts = sortByHighestproduct_score(unmatchedProducts);


  
  const renderTable = (products, tableBackground, heading) => {
    // Function to render a table for either matched or unmatched products
    return (
      
      <div style={{
        border: '2px solid #87D3D7',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: '560px',
        backgroundColor: tableBackground, // Set the background color based on the table type
      }}>
        
        {/* <h4>{heading} ({products.length} products)
        
        </h4> */}
  
        <div style={{
          maxHeight: '540px',
          overflowY: 'auto', // Apply scrolling to this div
          
        }}>
          <table style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
            position: 'relative',
           
          }}>
            <thead style={{
              position: 'sticky',
              top: 0,
              zIndex: 0,
              backgroundColor: 'white',
            }}>
              <tr style={{ fontSize: '13px' }}>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'center' }}>Given Name</th>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Matched Name</th>
                {/* <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Description</th> */}
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Manufacturer</th>
                <th style={{ borderBottom: '2px solid black', padding: '10px', textAlign: 'left' }}>Category</th>
                {/* <th style={{ borderBottom: '2px solid black', padding: '10px',  width: '20%', textAlign: 'center' }}>Name Score</th>
                <th style={{ borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'center' }}>Name + Description Score</th> */}
              </tr>
            </thead>
            <tbody>
              
              {products.map((product, productIndex) => {
                return product.matches.sort((a, b) => b.product_score - a.product_score).map((match, matchIndex) => {
                  const isSelected = selectedMatches[product.givenName] === match.product_id;
  
                  return (
                    <tr key={`${product.givenName}-${match.matchedName}-${matchIndex}`} style={{ fontSize: '13px', backgroundColor: productIndex % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                      {matchIndex === 0 && (
                        <td rowSpan={product.matches.length} style={{ verticalAlign: 'top', padding: '10px', textAlign: 'center' }}>
                          {product.givenName}
                        </td>
                      )}
                      <td style={{ padding: '10px' }}>
                        <button
                          className="upload-button"
                          onClick={() => handleMatchSelection(product.givenName, match.product_id)}
                          style={{
                            backgroundColor: isSelected ? '#5E9396' : '#87d3d7',
                          }}
                        >
                          {match.matchedName}
                          {match.matchedDescription && <span style={{color: 'black'}}>({match.matchedDescription})</span>}
                        </button>
                      </td>
                      {/* <td style={{ padding: '10px' }}>{match.matchedDescription}</td> */}
                      <td style={{ padding: '10px' }}>{match.manufacturer}</td>
                      <td style={{ padding: '10px' }}>{match.category}</td>
                      {/* <td style={{ padding: '10px', textAlign: 'center' }}>{match.product_score.toFixed(2)}</td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>{match.description_score.toFixed(2)}</td> */}
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      </div>
      
    );
  };
  

  

  return (
    <div className="page-wrapper-container">
    <div className="inventory-matching-page">
    <div className="dashboard-layout">
    <Sidebar call={call}/>
    </div>

    <h1>Inventory Matching</h1>
      {/* Only show the upload section if no tables are being displayed */}
      {!(trulyUnmatchedProducts.length > 0 ) && (
        <>
          
          
          

            {isUploading && <div className="loading-container">
      <img src={loadingGif} alt="Loading..." className="loading-gif" />
      <p style={{marginTop: '-100px'}}>Loading...</p>
    </div>}
         
        </>
      )}
  

  {
    (trulyUnmatchedProducts.length >= 0 ) && (
      
      <>
      
          
          <div style={{ marginTop: '5px', marginBottom: '30px' }}>
              
              <span
                onClick={() => handleTabClick('Unmatched_Products')}
                style={{
                  
                  color: '#000000',
                  borderBottom: selectedTab === 'Unmatched_Products' ? ' 2px solid black' : ' 2px solid transparent',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer' // Set cursor to pointer
                }}
              >
                Unmatched
              </span>

              <span
                onClick={() => handleTabClick('Matched_Products')}
                style={{
                  marginBottom: '15px',
                  marginLeft: '15px',
                  color: '#000000',
                  borderBottom: selectedTab === 'Matched_Products' ? ' 2px solid black' : ' 2px solid transparent',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer' // Set cursor to pointer
                }}
              >
                Completed
              </span>
            </div>
            
            
           

{
  matchedProducts.length > 0 && selectedTab === 'Matched_Products' &&
  renderMatchedProductsTable(matchedProducts)

}

{
  trulyUnmatchedProducts.length > 0 && selectedTab === 'Unmatched_Products' &&
  renderTable(trulyUnmatchedProducts, '#ffe0e0', 'Unmatched Products')
}

{
  selectedTab === 'Unmatched_Products' &&
(
<div className="button-container">
    <button onClick={handleSave} className="save-mappings-button">
      Save
    </button>
    <button onClick={exportMappings} className="save-mappings-button" style={{marginLeft: '10px'}}>
      Export Mappings
    </button>


  </div>
  )}
  </>
            

            )
            

            
          }

    {isProductModalOpen && (
      <ProductModal
          isOpen={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          currentProductId={selectedProductForReMap.matched_product_id}
          onSelectProduct={(selectedProductId) => {
          console.log("Selected product ID:", selectedProductId);
          // Handle additional logic here if needed
          }}
          />
      )}
         {/* <ToastContainer /> */}

      </div>
      </div>




    );

        }



export default InventoryMatching;
