import React, { useState, useEffect } from 'react';
import '../assets/style/Welcome.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './Sidebar';
import Modal from 'react-modal';
import greenTick from '../assets/images/green-tick.png'

function WelcomePage({call}) {
  const email = localStorage.getItem('email');
  const location_name = localStorage.getItem('location_name');
  const locationName = location_name;
  const permission_code = localStorage.getItem('permission_code');
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [file, setFile] = useState('')
  const [haveToUpload, setHaveToUpload] = useState(true);
  const [bundlesInvoices, setBundlesInvoices] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [users, setUsers] = useState([]);

  const handlePermissionChange = async (username, location_name, newPermission) => {

    const response = await fetch(`${call}/update-permission-level`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: username, location_name: location_name, permission_code: newPermission }),
    })

    if (response.ok) {
      toast.success('Permission level updated successfully')
      fetch(`${call}/get-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
      })
      .then(response => response.json())
      .then(data => {
        setUsers(data);
      })
      .catch(error => console.error('Error fetching users:', error)); 
    }

  };


    const [newUser, setNewUser] = useState({
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      phone: '',
      permission: '25', // Default to 'Staff'
    });

    const resetNewUserState = () => {
      setIsAddUserModalOpen(false) 
      setNewUser({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        phone: '',
        permission: '25',
      });
      setCurrentStep(1);
      setUserExists(false);
    };

    const steps = [
      { number: 1, title: "Enter User Email" },
      { number: 2, title: "User Exists" },
      { number: 3, title: "Enter User Details" },
      { number: 4, title: "Select Permission" }
    ];
    
    const [currentStep, setCurrentStep] = useState(1);
    
    const handleNextStep = (next = 1) => {
      if (currentStep < steps.length) {
          setCurrentStep(currentStep + next);
      }
    };

    const submitEmail = async () => {
      // Check if the user is already in the system
      if (users.some(user => user.user_email === newUser.email)) {
        alert('This user is already in your system');
        return;
      }
      
      // Check if the user is marked as deleted
      const deletedResponse = await fetch(`${call}/is-user-deleted`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newUser.email }),
      });

      const deletedData = await deletedResponse.json();
      if (deletedData.isDeleted) {
        setIsAddUserModalOpen(true)
        handleNextStep(3);
        return;
      }

      const response = await fetch(`${call}/check-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newUser.email, location_name: locationName }),
      });
    
      const data = await response.json();
      if (data.exists) {
        setUserExists(true);
        setCurrentStep(2); // Proceed to step 2 (User Exists)
      } else {
        const response = await fetch(`${call}/re-check-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: newUser.email}),
        });
        const data1 = await response.json();
        if(data1.exists){
          toast.error("This email is already in the Medvelle system, please contact Medvelle support for assistance")
          return
        }
      
        setUserExists(false);
        handleNextStep(2); // Proceed to step 3 (Enter User Details)
      }
    };

    async function postFile({ file }) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("filename", "medical-licenses/" + `${locationName}`);

      const requestOptions = {
        method: 'POST',
        body: formData
      };
    
      try {
        const response = await fetch(`${call}/upload-files`, requestOptions);
        if (!response.ok) {
          throw new Error('Failed to upload image');
        }
        
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error; // Propagate the error further
      }
    }


    const fetchFile = async (filename) => {
      try {
        const key = "medical-licenses/" + filename; 
        const response = await fetch(`${call}/get-file`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ key }), // Include the key in the request body as JSON
        });
    
        if (!response.ok) {
          toast.error("Failed to fetch file");
          throw new Error('Failed to fetch file');
        }

        toast.success("File fetched successfully");
    
        // Trigger download when fetch is successful
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.pdf`; // Set the download filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    
        console.log('File downloaded successfully');
      } catch (error) {
        console.error('Error downloading file:', error);
      }
  };
    
    const submitUploadInvoice = async event => {
      event.preventDefault();
      try {
          const result = await postFile({ file: file });

          // Check if the result contains a message indicating success
          if (result.message === 'File uploaded successfully') {
              // Show success toast
              toast.success('File uploaded successfully');
              setHaveToUpload(false);
          } else {
              // Show error toast if the message is not as expected
              toast.error('Failed to upload file');
          }

          setModalUploadOpen(false);
          setFile('');
          await fetchFiles();

      } catch (error) {
          console.error('Error uploading file:', error);
          // Show error toast for any other errors
          toast.error('Failed to upload file');
      }
  };

  const fileSelected = event => {
      let file = event.target.files[0]
      setFile(file)
  }


  const fetchFiles = async () => {
    try {
        const response = await fetch(`${call}/get-aws-files`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ folder: "medical-licenses/" }), // Include the key in the request body as JSON
        });
    
        const data = await response.json();
        const fileNames = data.files.map(file => file.replace("medical-licenses/", ""));

        // Check if the file for the current location is present
        if (fileNames.includes(`${locationName}`)) {
          console.log('found a match files uploaded: ', locationName)
            setIsFileUploaded(true);
        } else {
            setIsFileUploaded(false);
        }
    } catch (error) {
        console.error('Error fetching file names:', error);
    }
};

    
    const confirmAddUser = async () => {
      await fetch(`${call}/add-user-to-location`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newUser, location_name: locationName }),
      });
      setIsAddUserModalOpen(false);
    };
    

    const handleAddUser = () => {
      setIsAddUserModalOpen(true);
    };


    const handleAddUserChange = (e) => {
      const { name, value } = e.target;
      setNewUser(prevState => ({ ...prevState, [name]: value }));
    };

    const submitExistingUser = async () => {
      const response = await fetch(`${call}/add-existing-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: newUser.email ,permission_code: newUser.permission, location_name: locationName }),
      });
    
      if (response.ok) {
        toast.success('User added successfully');
        resetNewUserState();
        fetch(`${call}/get-users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location_name: locationName }),
        })
        .then(response => response.json())
        .then(data => {
          setUsers(data);
        })
        .catch(error => console.error('Error fetching users:', error));
      } else {
        // toast.error('Failed to add user');
      }
    }

    const submitNewUser = async () => {
      const { userName, firstName, lastName, email, phone, permission } = newUser;
    
      // Check if all fields are filled
      if (!firstName || !lastName || !email || !phone || !permission || !userName) {
        alert('Please fill all fields before submitting.');
        return;
      }
    
      try {
        const response = await fetch(`${call}/add-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ newUser, location_name: locationName }),
        });
    
        const responseData = await response.json();
    
        if (response.ok) {
          toast.success('User added successfully');
          resetNewUserState();
          fetchUsers();
        } else {
          if (responseData.message === 'Username exists') {
            toast.error('Username already exists');
          } else {
            toast.error('Failed to add user');
          }
        }
      } catch (error) {
        console.error('Error adding user:', error);
        toast.error('An error occurred while adding the user');
      }
    };

    const fetchUsers = async () => {
      const response = await fetch(`${call}/get-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
      });
      const data = await response.json();
      setUsers(data);
    };
    
    const handleInput = (e) => {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };


    useEffect(() => {
      fetch(`${call}/get-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
      })
      .then(response => response.json())
      .then(data => {
        setUsers(data);
      })
      .catch(error => console.error('Error fetching users:', error));

      fetchFiles();

    }, []); 

    const handleDeleteUser = (user) => {
      setDeleteUser(user)
      setIsDeleteModalOpen(true);
    }

    const handleGoBack = async () => {
      setIsDeleteModalOpen(false)
    }

    const confirmDelete = async () => {

      const response = await fetch(`${call}/remove-user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user: deleteUser}),
      });
  
      if (response.ok) {
        toast.success('User removed successfully')
        setIsDeleteModalOpen(false)
        fetch(`${call}/get-users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location_name: locationName }),
        })
        .then(response => response.json())
        .then(data => {
          setUsers(data);
        })
        .catch(error => console.error('Error fetching users:', error)); 
      }
    }

    const filteredUsers = users.filter(user => !String(user.permission_code).startsWith('1'));

  const [formData, setFormData] = useState({
    locationName: '',
    masterGroup: '',
    groupName: '',
    contactName: '',
    contactPhone: '',
    shippingStreet: '', // For the shipping street address
    shippingSecondaryInfo: '', // For secondary shipping information
    shippingCity: '', // For the shipping city
    shippingState: '', // For the shipping state
    shippingZip: '', // For the shipping ZIP code
    shippingCountry: '',
    billingStreet: '', // For the billing street address
    billingSecondaryInfo: '', // For secondary billing information
    billingCity: '', // For the billing city
    billingState: '', // For the billing state
    billingZip: '', // For the billing ZIP code
    billingCountry: ''
  });

  // Calculate progress based on filled fields excluding secondary shipping and billing info
  const excludedFields = ['shippingSecondaryInfo', 'billingSecondaryInfo'];
  const filledFields = Object.keys(formData).filter(key => formData[key] && !excludedFields.includes(key)).length;
  const totalFields = Object.keys(formData).filter(key => !excludedFields.includes(key)).length;
  const progressPercentage = (filledFields / totalFields) * 100;

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };



  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await fetch(`${call}/fetch-location`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location_name: location_name }),
        });
        if (response.ok) {
          const data = await response.json();
          setFormData({
            locationName: data.location_name || '',
            masterGroup: data.master_group || '',
            groupName: data.group_name || '',
            contactName: data.contact_name || '',
            contactPhone: data.phone || '',
            shippingStreet: data.shipping_street || '',
            shippingSecondaryInfo: data.shipping_secondary_info || '',
            shippingCity: data.shipping_city || '',
            shippingState: data.shipping_state || '',
            shippingZip: data.shipping_zip || '',
            shippingCountry: data.shipping_country || '',
            billingStreet: data.billing_street || '',
            billingSecondaryInfo: data.billing_secondary_info || '',
            billingCity: data.billing_city || '',
            billingState: data.billing_state || '',
            billingZip: data.billing_zip || '',
            billingCountry: data.billing_country || '',
          });
        } else {
          throw new Error('Failed to fetch location data');
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchLocationData();
  }, []); // The empty array [] means this useEffect runs once on mount

  const [userEntries, setUserEntries] = useState([
    { firstname: '', lastname: '', phone: '', username: '', email: '', permissionLevel: 'owner' },
  ]);

  const handleUserChange = (index, e) => {
    const updatedEntries = [...userEntries];
    updatedEntries[index][e.target.name] = e.target.value;
    setUserEntries(updatedEntries);
  };

  const addUserEntry = () => {
    setUserEntries([...userEntries, { firstname: '', lastname: '', phone: '', username: '', email: '', permissionLevel: 'owner' }]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Perform validation if the field is required
    if (e.target.hasAttribute("required") && value.trim() === "") {
      // Display an error message
      alert(`${name} is required.`);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const removeUserEntry = (indexToRemove, username) => {
    setUserEntries(userEntries.filter((_, index) => index !== indexToRemove));
    fetch(`${call}/remove-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username: username, location_name: location_name }),
    })
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Basic form validation
    const formFields = [
      'locationName', 'masterGroup', 'groupName', 'contactName', 'contactPhone',
      'shippingStreet', 'shippingCity', 'shippingState', 'shippingZip', 'shippingCountry',
      'billingStreet', 'billingCity', 'billingState', 'billingZip', 'billingCountry'
    ];

    for (const field of formFields) {
      if (!formData[field].trim()) {
        alert(`Each field row is required.`);
        return;
      }
    }

    toast.success("Information Saved Successfully");

      formData.location_name = location_name;
      const response = await fetch(`${call}/locations-data`, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json', // Indicate JSON content
        },
        body: JSON.stringify(formData), // Convert formData object to JSON
      });

      

    // Check progress percentage and update if necessary
    if (progressPercentage === 100) {
      console.log('progress is 100')
      updatePlatformSetupProgress();
    }
  };

  const copyShippingToBilling = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      billingStreet: prevFormData.shippingStreet,
      billingSecondaryInfo: prevFormData.shippingSecondaryInfo,
      billingCity: prevFormData.shippingCity,
      billingState: prevFormData.shippingState,
      billingZip: prevFormData.shippingZip,
      billingCountry: prevFormData.shippingCountry
    }));
  };
  

  // Function to update platform setup progress
  const updatePlatformSetupProgress = async () => {
    try {
      const response = await fetch(`${call}/update-progress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taskName: 'Platform Setup',
          location_name: location_name,
          completed: "Completed",
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update task progress');
      }

      const result = await response.json();
    } catch (error) {
      console.error('Error updating task progress:', error);
    }
  };

  const handleSubmitUsers = async (e) => {
    // Check each user entry for empty fields
    for (const entry of userEntries) {
      // Destructure the entry to access its fields directly
      const { firstname, lastname, email, phone, username } = entry;

      // Create an array of all the fields
      const fields = [firstname, lastname, email, phone, username];

      // Check if any of the fields is empty
      for (const field of fields) {
        if (!field) {
          alert("Each field row is required.");
          return; // Stop execution if any field is empty
        }
      }
    }

    toast.success("Users included in the system");

    e.preventDefault();
    

    for (const entry of userEntries) {
      // Step 1: Send user info
      await fetch(`${call}/user-info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname: entry.firstname,
          lastname: entry.lastname,
          email: entry.email,
          phone: entry.phone,
          username: entry.username,
          location_name: location_name
        }),
      });

      // Step 2: Send permission info
      await fetch(`${call}/user-permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: entry.username,
          location_name: location_name,
          permissionLevel: entry.permissionLevel,
        }),
      });
    }

    fetch(`${call}/get-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_name: location_name }),
    })
      .then(response => response.json())
      .then(data => {
        setUsers(data);
      })
      .catch(error => console.error('Error fetching users:', error));

  };
  

  return (
    <div className="page-wrapper-container">
          <div className="welcome-page">
      <div className="dashboard-layout">
        <Sidebar call={call}/>
        <div className="welcome-content">
        </div>
      </div>
      <div className="progress-container">
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
        </div>
        <span className="progress-text1">{`${Math.round(progressPercentage)}% Complete`}</span>
      </div>
      <form className="welcome-form" >
        <h2>Location Details</h2>
        <label>Location Name</label>
        <input type="text" name="locationName" value={formData.locationName} onChange={handleChange}  />

        <label>Master Group</label>
        <input type="text" name="masterGroup" value={formData.masterGroup} onChange={handleChange}  />

        <label>Group Name</label>
        <input type="text" name="groupName" value={formData.groupName} onChange={handleChange}  />

        <label>Contact Name</label>
        <input type="text" name="contactName" value={formData.contactName} onChange={handleChange} />

        <label>Contact Phone</label>
        <input type="text" name="contactPhone" value={formData.contactPhone} onChange={handleChange} />

        <h2>Shipping Details</h2>

        <label>Shipping Street</label>
        <input type="text" name="shippingStreet" value={formData.shippingStreet} onChange={handleChange} />

        <label>Secondary Shipping Information</label>
        <input type="text" name="shippingSecondaryInfo" value={formData.shippingSecondaryInfo} onChange={handleChange} />

        <label>Shipping City</label>
        <input type="text" name="shippingCity" value={formData.shippingCity} onChange={handleChange} />

        <label>Shipping State</label>
        <input type="text" name="shippingState" value={formData.shippingState} onChange={handleChange} />

        <label>Shipping Zip</label>
        <input type="text" name="shippingZip" value={formData.shippingZip} onChange={handleChange} />

        <label>Country</label>
        <input type="text" name="shippingCountry" value={formData.shippingCountry} onChange={handleChange} />

        
        <div style={{display: 'flex'}}>
        <h2>Billing Details</h2>
        <button type="button" className="copy-address-button" onClick={copyShippingToBilling} style={{marginLeft: '1vw'}}>Copy Shipping Address</button>
        </div>

        <label>Billing Street</label>
        <input type="text" name="billingStreet" value={formData.billingStreet} onChange={handleChange} />

        <label>Secondary Billing Information</label>
        <input type="text" name="billingSecondaryInfo" value={formData.billingSecondaryInfo} onChange={handleChange} />

        <label>Billing City</label>
        <input type="text" name="billingCity" value={formData.billingCity} onChange={handleChange} />

        <label>Billing State</label>
        <input type="text" name="billingState" value={formData.billingState} onChange={handleChange} />

        <label>Billing Zip</label>
        <input type="text" name="billingZip" value={formData.billingZip} onChange={handleChange} />

        <label>Country</label>
        <input type="text" name="billingCountry" value={formData.billingCountry} onChange={handleChange} />

        <button type="submit" className="submit-button" onClick={handleSave}>Save Form</button>
      </form>

      <div style={{display: 'flex'}}>
        <h1 style={{  display: 'flex', fontWeight: 'bold', color: '#87D3D7', }}>Upload Medical License</h1>
        {isFileUploaded && (<img src={greenTick} alt="greentick" className="blend-image" style={{marginLeft: '0.5vw', marginTop: '2.5vh', height: '3.4vh', width: '2vw'}} />)}
      </div>
      <button onClick={() => setModalUploadOpen(true)} 
        style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '1vh', cursor: 'pointer', borderRadius: '1vh', marginTop: '1vh', marginBottom: '5vh',}}>Upload License
      </button>
      <button 
        onClick={() => {
          fetchFile(`${location_name}`);
        }}
      
        style={{ 
            backgroundColor: '#87D3D7', 
            color: '#fff', 
            border: 'none', 
            padding: '1vh', 
            cursor: 'pointer', 
            borderRadius: '1vh',
            marginLeft: '1vw'
          }} 
        >
          Download
      </button>
      <h1 style={{display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7', marginTop: '-1vh' }}>Manage Users</h1>

<div style={{ 
    border: '2px solid #87D3D7', 
    display: 'flex', 
    justifyContent: 'left', 
    flexDirection: 'column', 
    width: '105%', 
    maxHeight: '30vh', // Set a fixed height
    overflowY: 'auto', // Enable vertical scrolling
    boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
}}>

  <table style={{  
      width: '100%', 
      borderCollapse: 'separate', 
      borderSpacing: 0, 
      position: 'relative' // Ensure positioning context
  }}>
  
    <thead style={{
      position: 'sticky', // Stick the header
      top: 0, // Stick to the top of the table
      zIndex: 0 // Ensure it stays above the tbody rows
    }}>

      <tr style={{ fontSize: '1rem', backgroundColor: 'white' }}>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%', textAlign: 'left'  }}>Username</th>
          <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%', textAlign: 'left'  }}>Name</th>
          <th style={{  borderBottom:'2px solid black', padding: '10px', width: '20%', textAlign: 'left' }}>Email</th>
          <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'left' }}>
          <div style={{display: 'flex' }}>
          Permission Level
          <button className="info-button" onClick={togglePopup} style={{marginTop: '2px', marginLeft: '5px'}}>&#x2139;</button>
          </div>
          
          </th>
          <th style={{  borderBottom:'2px solid black', padding: '10px', width: '20%',textAlign: 'center' }}>Status</th>
          <th style={{  borderBottom:'2px solid black', padding: '10px', width: '20%', textAlign: 'center' }}>Remove User</th>

      </tr>

    </thead>

    <tbody>
      {filteredUsers.map((user,index) => (
        <tr key={user.username} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
          <td style={{padding: '10px', }}>{user.username}</td>
          <td style={{padding: '10px', }}>{user.first_name} {user.last_name}</td>
          <td style={{padding: '10px', }}>{user.user_email}</td>
          <td style={{ padding: '10px', textAlign: 'center' }}>
            <select
                value={user.permission_code}
                onChange={(e) => handlePermissionChange(user.username, user.location_name, e.target.value)}
                style={{  width: '150px', marginLeft: '385x' }}
            >
              <option value="21">Owner</option>
              <option value="22">Manager</option>
              <option value="25">Staff</option>
              <option value="26">Restricted Staff</option>
              <option value="24">Reporting</option>
              <option value="23">Frontdesk</option>                        
            </select>
          </td>
          <td style={{padding: '10px', textAlign: 'center'}}>{user.status}</td>
          <td style={{ padding: '10px', textAlign: 'center'}}>    
            <button onClick={() => handleDeleteUser(user)} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Remove</button>
          </td>
          
        </tr>
      ))}
    </tbody>
  </table>
</div>

<button onClick={() => handleAddUser()} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '1vh', cursor: 'pointer', borderRadius: '1vh', marginTop: '3vh', marginBottom: '3vh' }}>Add User</button>

<Modal
          overlayClassName="modal-overlay"
          className="modal-content"
          isOpen={isDeleteModalOpen}
          onRequestClose={() => {
            setIsDeleteModalOpen(false);
          
          }}
          style={{
            content: {
              height: '200px',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 2,
            },
          }}
        >
          <h2 style={{textAlign: 'center'}}>Attention</h2>
          
          <h3 style={{textAlign: 'center'}}>Are you sure you want to remove this user?</h3>
          <button onClick={handleGoBack} type="button" style={{marginLeft: '140px' , width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px',  cursor: 'pointer'}}>Go Back</button>

          <button onClick={confirmDelete} type="button" style={{marginLeft: '20px' ,border: '3px solid #F49C7D', color: 'white', backgroundColor: '#F49C7D', padding: '5px', marginTop: '20px', cursor: 'pointer'}}>Confirm Remove</button>

        </Modal>

        {isPopupVisible && (
          <div className="popup">
            <div className="popup-inner">
              <h3>User Permission Levels:</h3>
              <p><strong>Owner:</strong> Has full access to the platform across their onboarded locations</p>
              <p><strong>Staff:</strong> Has access to request orders and view the orders page</p>
              <p><strong>Restricted Staff:</strong> Has access to request orders and a limited view of the orders page(no pricing information available)</p>
              <p><strong>Reporting:</strong> Can only view and generate reports</p>
              <p><strong>Frontdesk:</strong> Can access only spotcheck (inventory counting tool)</p>
              <button className="submit-button" onClick={togglePopup} style={{width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px',  cursor: 'pointer'}}>Close</button>
            </div>
          </div>
        )}


<Modal
          overlayClassName="modal-overlay"
          className="modal-content"
          isOpen={isAddUserModalOpen}
          onRequestClose={resetNewUserState}
          style={{
            content: {
              height: currentStep === 1 ? '220px' : 'auto', // Conditional height
              width: '300px'
            },
          }}
        >
          <h2>{steps[currentStep-1].title}</h2>
          {currentStep === 1 && (
            <div className="input-group" style={{marginTop: '50px' }}>
              <h3>Email</h3>
              <input type="email" name="email" value={newUser.email} onChange={handleAddUserChange} />
              <button 
                type="button" 
                onClick={submitEmail} 
                style={{ border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', margin: '20px', cursor: 'pointer', disabled: !newUser.email}}
                disabled={!newUser.email}
              >
                Next
              </button>
            </div>
          )}

          {currentStep === 2 && (
            <div className="input-group">
              <p>This user already exists. Do you want to add this user to {locationName}?</p>
              <div style={{display: 'flex', textAlign: 'center' }}>
                <button 
                  type="button" 
                  onClick={resetNewUserState} 
                  style={{ marginRight: '305px' , width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px',  marginTop: '20px', cursor: 'pointer'}}
                >
                  Cancel
                </button>
                <button 
                  type="button" 
                  onClick={() => handleNextStep(2)} 
                  style={{  width: '450px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px',  cursor: 'pointer'}}
                >
                  Add
                </button>
              </div>
            </div>
          )}

          {currentStep === 3 && !userExists && (
            <form>
              <div className="input-group">
                <h3>First Name</h3>
                <input type="text" name="firstName" value={newUser.firstName} onChange={handleAddUserChange} />
              </div>
              <div className="input-group">
                <h3>Last Name</h3>
                <input type="text" name="lastName" value={newUser.lastName} onChange={handleAddUserChange} />
              </div>
              <div className="input-group">
                <h3>Username</h3>
                <input type="text" name="userName" value={newUser.userName} onChange={handleAddUserChange} />
              </div>
              <div className="input-group">
                <h3>Phone</h3>
                <input
                  type="tel"
                  name="phone"
                  value={newUser.phone}
                  pattern="[0-9]*"
                  onChange={handleAddUserChange}
                  onInput={handleInput}
                />            
              </div>
              <div className="input-group">
                <h3>Permission Level</h3>
                <select name="permission" style={{width:'150px'}} value={newUser.permission} onChange={handleAddUserChange}>
                  <option value="21">Owner</option>
                  <option value="22">Manager</option>
                  <option value="25">Staff</option>
                  <option value="26">Restricted Staff</option>
                  <option value="24">Reporting</option>
                  <option value="23">Frontdesk</option>
                </select>
              </div>
              <br/>
              <br/>
              <button 
                type="button" 
                onClick={submitNewUser} 
                style={{ 
                  width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', cursor: 'pointer',
                }}
              >
                Submit
              </button>
            </form>
          )}

          {currentStep === 4 && (
            <div className="input-group">
              <h3>Permission Level</h3>
              <select name="permission" value={newUser.permission} onChange={handleAddUserChange}>
                <option value="21">Owner</option>
                <option value="22">Manager</option>
                <option value="25">Staff</option>
                <option value="26">Restricted Staff</option>
                <option value="24">Reporting</option>
                <option value="23">Frontdesk</option>
              </select>
              <button 
                type="button" 
                onClick={submitExistingUser}
                style={{ 
                  marginLeft: '305px' , width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px',  marginTop: '20px', cursor: 'pointer'
                }}
                disabled={!newUser.permission}
              >
                Submit
              </button>
            </div>
          )}
        </Modal>


        <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={modalUploadOpen}
        onRequestClose={() => {setModalUploadOpen(false); setFile('');} }
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2,
            },
            content: {
                width: '20%',
                height: '30%',
                textAlign: 'center'
            }
        }}
    >
        <form onSubmit={submitUploadInvoice}>
            <div style={{ marginTop: '-15px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <h3>Upload File</h3>

                <input
                    type="file"
                    accept="*"
                    onChange={fileSelected}
                    style={{ display: 'none' }} // Hide the actual file input
                    id="fileInput" // Assign an ID for the label to reference
                />
                <label htmlFor="fileInput" 
                    style={{ marginBottom: '30px', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    Choose File
                </label>
                {file && 
                    <>
                        <br/>
                        <br/>
                        <span>{file.name}</span>
                        <br/>
                        <br/>
                        <button 
                            type="submit"
                            style={{ backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            Submit
                        </button>
                    </>
                }
            </div>
            
            
        </form>
    </Modal>  


      {/* <ToastContainer /> */}
    </div>
    </div>
  );
}

export default WelcomePage;