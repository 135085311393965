// Accounts.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import '../assets/style/AccountsPage.css'; // Create and import a CSS file for Accounts-specific styles

function Accounts() {
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/'); // Redirect to the InitialPage after logout
    }).catch((error) => {
      // An error happened.
      console.error('Logout Error:', error);
    });
  };

  return (
    <div className="accounts-page">
      <h1>Accounts</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Accounts;
